import { HStack, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FeedbackSuperpose from '../pages/FeedbackSuperpose';
import CarouselGridElement from '../pages/preprocessing/gridElements/CarouselGridElement';
import SingleImageGridElement from '../pages/preprocessing/gridElements/SingleImageElement';
import { Feedback, Pose } from '../types/api-types';
import { COMMON_LOCALES } from '../utils/constants';
import useFeedbacks from '../utils/feedback-hook';

interface FeedbackOverlayProps {
    feedbacks: Feedback[]
    onScroll(event: React.UIEvent<HTMLDivElement>): void
    carouselData?: (string | Pose)[]
    imgSrc: string
}

export default function FeedbackOverlay(props: FeedbackOverlayProps) {
    const { feedbacks, onScroll, carouselData, imgSrc } = props;
    const { t } = useTranslation([COMMON_LOCALES]);
    const { getVisibleIdsFromFeedbacks, getSuperposeImagesFromFeedbacks } = useFeedbacks();

    const [visibleFeedbackIds, setVisibleFeedbackIds] = useState<string[]>();
    const [feedbackIdHovered, setFeedbackIdHovered] = useState<string | undefined>();

    const overlayFeedbackImages = useMemo(() => {
        if (!feedbacks || !visibleFeedbackIds) {
            return undefined;
        }

        return getSuperposeImagesFromFeedbacks(feedbacks, visibleFeedbackIds, feedbackIdHovered);
    }, [visibleFeedbackIds, feedbacks, feedbackIdHovered]);

    const localCarouselData = useMemo(() => {
        if (!carouselData) {
            return null;
        }

        // ---- If the imgSrc is not included in the carouselData we add it ----
        if (!carouselData.includes(imgSrc)) {
            return [imgSrc, ...carouselData];
        }

        return carouselData;
    }, [carouselData, imgSrc]);

    const handleFeedbackClick = (newFeedbackId: string) => {
        if (visibleFeedbackIds?.includes(newFeedbackId)) {
            setVisibleFeedbackIds(visibleFeedbackIds?.filter((feedbackId) => feedbackId !== newFeedbackId));

            return;
        }

        setVisibleFeedbackIds(visibleFeedbackIds ? [...visibleFeedbackIds, newFeedbackId] : [newFeedbackId]);
    };

    useEffect(() => {
        if (!visibleFeedbackIds && feedbacks.length > 0) {
            setVisibleFeedbackIds(getVisibleIdsFromFeedbacks(feedbacks));
        }
    }, [feedbacks]);

    return (
        <HStack h="100%" w="100%">
            <VStack boxSize="full" flex={1} >
                {
                    localCarouselData && <CarouselGridElement
                        imagesData={localCarouselData}
                        label={t('comparison_images.title', { ns: COMMON_LOCALES })}
                    />
                }
            </VStack>
            <VStack boxSize="full" flex={1} >
                <SingleImageGridElement
                    imageSrc={imgSrc}
                    label={t('warp_with_feedbacks')}
                    overlay={overlayFeedbackImages}
                />
            </VStack>
            <VStack boxSize="full" flex={1} >
                <FeedbackSuperpose
                    feedbacks={feedbacks}
                    onFeedbackClick={handleFeedbackClick}
                    onMouseEnterCard={(feedbackId) => setFeedbackIdHovered(feedbackId)}
                    onMouseLeaveCard={() => setFeedbackIdHovered(undefined)}
                    onScroll={onScroll}
                    visibleFeedbackIds={visibleFeedbackIds}
                />
            </VStack>
        </HStack>
    );
}

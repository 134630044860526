import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';

import { LoginResponse } from '../../types/api-types';
import { storeTokens } from '../../utils/auth-helpers';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '../../utils/constants';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import { handleLogout } from '../../utils/login-helpers';
import { baseUrl, baseWarpEditUrl } from '../../utils/queries-helpers';

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = loadFromLocalStorage(AUTH_TOKEN_KEY);
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

const mutex = new Mutex();
let lastRefreshTimestamp = Date.now();

// RTK Query function with Token refresh in case of 401
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // if locked, a refresh is in process
    await mutex.waitForUnlock();

    // try query
    const queryTimestamp = Date.now();
    const queryToken = loadFromLocalStorage(AUTH_TOKEN_KEY);
    let result = await baseQuery(args, api, extraOptions);

    // if the token is expired
    if (result.error && result.error.status === 401) {
        // avoid multiple refresh
        const release = await mutex.acquire();

        try {
            if (queryTimestamp > lastRefreshTimestamp) {
                // no more refresh for previous queries
                lastRefreshTimestamp = Date.now();

                // check the refresh token
                const refreshToken = loadFromLocalStorage(AUTH_REFRESH_TOKEN_KEY);
                if (!refreshToken) {
                    // logout (so remove tokens)
                    handleLogout(api.dispatch);

                    // eslint-disable-next-line no-console
                    console.error('Missing refreshing token');
                } else {
                    // try to get a new token
                    const refreshResult = await baseQuery({
                        body: { refresh_token: refreshToken },
                        method: 'POST',
                        url: 'token/refresh',
                    }, api, extraOptions);

                    // if all is well
                    if (refreshResult.data && !refreshResult.error) {
                        // store the new token
                        storeTokens(refreshResult.data as LoginResponse);
                    } else {
                        // wait just in case so the localstorage gets updated
                        await new Promise((r) => setTimeout(r, 1000));

                        // make sure token has not already been refreshed by another tab
                        const newToken = loadFromLocalStorage(AUTH_TOKEN_KEY);
                        if (!newToken || newToken === queryToken) {
                            // logout (so remove tokens)
                            handleLogout(api.dispatch);

                            // eslint-disable-next-line no-console
                            console.error('Error refreshing token', refreshResult.error);

                            // show refresh error
                            result = refreshResult;
                        }
                    }
                }
            }
        } finally {
            // release is mandatory
            release();
        }

        // retry only if theres a token
        const token = loadFromLocalStorage(AUTH_TOKEN_KEY);
        if (token) {
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

// Warp Edit RTK query function to make API calls
export const baseWarpEditQuery = fetchBaseQuery({
    baseUrl: baseWarpEditUrl,
});

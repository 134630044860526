import { Box, Center, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { matchPath, useLocation } from 'react-router-dom';

import { Garment } from '../../types/api-types';
import preventCmdCtrlClick from '../../utils/click-helpers';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import resizeImage from '../../utils/image';
import { getCleanSearch } from '../../utils/url-helpers';
import ImageWithError from '../ImageWithError';

// ---- Props needed to have correct overflow on text in card ----
const TEXT_OVERFLOW_PROPS = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    w: '100%',
};

interface CheckGarmentCardProps {
    garment: Garment,
    imageRatio?: string,
}

export default function CheckGarmentCard(props: CheckGarmentCardProps) {
    const { garment, imageRatio } = props;

    const { search, pathname } = useLocation();
    const navigate = useCustomNavigate();

    // ---- This is used to change the font size according to the card width ----
    const ref = useRef<HTMLDivElement>(null);
    const [cardWidth, setCardWidth] = useState<number>(0);
    const fontSize = useMemo(() => {
        if (cardWidth < 200) { return 9; }

        return 'md';
    }, [cardWidth]);

    const isExternal = useMemo(() => matchPath('external_validation', pathname), [pathname]);
    const isCheck = useMemo(() => matchPath('check', pathname), [pathname]);

    // ---- Navigation HREF according to pathname ----
    const href = useMemo(() => {
        if (isExternal) {
            return `/external_validation/${garment.warp_id}?${getCleanSearch(search)}`;
        }

        if (isCheck) {
            return `/check/${garment.garment_id}?${getCleanSearch(search)}`;
        }

        return undefined;
    }, [isExternal, isCheck, search]);
    useEffect(() => {
        function handleResize() {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setCardWidth(rect.width);
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [ref.current?.getBoundingClientRect()]);

    return (
        <VStack
            backgroundColor="gray.100"
            border="2px solid"
            borderColor={'transparent'}
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            ref={ref}
            w="100%"
        >
            <Box
                as="a"
                borderRadius="lg"
                h={0}
                href={href}
                onClick={(e: React.MouseEvent<HTMLElement>) => preventCmdCtrlClick(e, () => navigate(href))}
                overflow="hidden"
                paddingBottom={imageRatio}
                position="relative"
                w="100%"
            >
                <LazyLoad height="100%" offset={100} once overflow>
                    <ImageWithError
                        cursor="pointer"
                        fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                        height="100%"
                        objectFit={'contain'}
                        position="absolute"
                        src={resizeImage(garment.image_url, { width: 800 })}
                        width="100%"
                    />
                </LazyLoad>
            </Box>
            <VStack justifyContent="space-between" spacing={2} w="100%">
                <VStack alignItems="flex-start" maxW="100%" minW={30}>
                    <Text
                        fontSize={fontSize}
                        noOfLines={[1, 2]}
                        textAlign='center'
                        {...TEXT_OVERFLOW_PROPS}
                    >
                        {garment.garment_name}
                    </Text>
                    {
                        isCheck && <Text
                            fontSize={fontSize}
                            fontWeight="bold"
                            textAlign='center'
                            whiteSpace="nowrap"
                            {...TEXT_OVERFLOW_PROPS}
                        >
                            {garment.garment_check}
                        </Text>
                    }

                    {
                        isExternal && <Text
                            fontSize={fontSize}
                            fontWeight="bold"
                            textAlign='center'
                            whiteSpace="nowrap"
                            {...TEXT_OVERFLOW_PROPS}
                        >
                            {garment.warp_status}
                        </Text>
                    }

                </VStack>
            </VStack>
        </VStack>

    );
}

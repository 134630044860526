import { PREFILTERS } from './constants';

const isSelectableFromStatus = (status: string) => {
    if (([PREFILTERS.TODO] as string[]).includes(status)) {
        return true;
    }

    return false;
};

export default isSelectableFromStatus;

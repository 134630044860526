import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CrossWarpSubHeader from '../../components/headers/CrossWarpSubHeader';
import GridLayout from '../../components/layouts/GridLayout';
import { useGetCrossWarpsQuery } from '../../services/api/api-warp';
import { getClient, getConfig, getConvertedActiveFilters, getExperience } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { CrosswarpQueryInput, Filters } from '../../types/api-types';
import { DEFAULT_CROSSWARP_FILTERS, GARMENTS_LOCALES } from '../../utils/constants';
import GarmentGridElement from '../garments/GarmentGridElement';

export default function CrosswarpGarmentGrid() {
    const { t } = useTranslation(GARMENTS_LOCALES);
    const { pathname } = useLocation();

    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const config = useAppSelector((state) => getConfig(state));
    const storeConvertedActiveFilters = useAppSelector((state) => getConvertedActiveFilters(state, pathname));

    // ---- Use the default filter according to the pathname so we hide unrelevant garment ----
    const defaultFilters = DEFAULT_CROSSWARP_FILTERS;

    const [queryInputObject, setQueryInputObject] = React.useState<CrosswarpQueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience?.id,
        filters: { ...(defaultFilters), ...(storeConvertedActiveFilters) },
        group_garment: 1,
    });

    const { data: crosswarpGroupedResponse, isFetching: isCrosswarpGroupedLoading } = useGetCrossWarpsQuery(
        queryInputObject,
        { refetchOnMountOrArgChange: true, skip: !currentClient },
    );
    // ---- We duplicate the query with default params to use the global facet data ----
    const { data: facetDataCrosswarpGroupedResponse } = useGetCrossWarpsQuery(
        {
            clientId: currentClient ? currentClient.id : '',
            experienceId: selectedExperience?.id,
            filters: defaultFilters,
            group_garment: 1,
            only_facets: 1,
        },
        { refetchOnMountOrArgChange: true, skip: !currentClient },
    );

    function useCrosswarpQuery(filters: Filters, query = '', page = 1, collapse = null) {
        if (currentClient) {
            setQueryInputObject({
                clientId: currentClient.id,
                collapse,
                experienceId: selectedExperience?.id,
                filters: Object.keys(filters).length === 0 ? defaultFilters : { ...defaultFilters, ...filters },
                group_garment: 1,
                page,
                query,
            });
        }
    }

    return (
        <GridLayout
            FlexWarpComponent={GarmentGridElement}
            SubHeaderContent={CrossWarpSubHeader}
            data={crosswarpGroupedResponse}
            dataQuery={useCrosswarpQuery}
            facetData={facetDataCrosswarpGroupedResponse}
            imagesRatio={
                config
                    ? `${(100 / config.garment_image_ratio).toString()}%`
                    : '120%'
            }
            isLoading={isCrosswarpGroupedLoading}
            title={t('title', { clientName: currentClient?.name })}
            type="garment"
        />
    );
}

import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import React, { FC } from 'react';

interface SearchBarProps {
    placeholder: string,
    onChange?(newValue: string): void,
    debounce?: number
}

const SearchBar: FC<SearchBarProps> = ({ placeholder, onChange, debounce }) => {
    const [value, setValue] = React.useState('');

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) {
            if (debounce) {
                setTimeout(() => {
                    onChange(e.target.value);
                }, debounce);

                return;
            }

            onChange(e.target.value);
        }
    };

    const resetInput = () => {
        setValue('');
        if (onChange) {
            onChange('');
        }
    };

    return (
        <InputGroup>
            <InputLeftElement children={<Search2Icon color="gray.300" />} pointerEvents="none" />
            <Input onChange={handleOnChange} placeholder={placeholder} value={value} variant='outlined'/>
            {
                value !== ''
                && <InputRightElement children={<CloseIcon />} onClick={resetInput}/>
            }
        </InputGroup>
    );
};

export default SearchBar;

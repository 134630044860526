import { Button, HStack, Switch, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { CheckGarmentDetail, Feedback } from '../../types/api-types';
import preventCmdCtrlClick from '../../utils/click-helpers';
import { CHECKFINAL_LOCALES, COMMON_LOCALES, CROSSWARP_IMAGESIZE_KEY } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useRoles from '../../utils/roles-hook';
import { getCleanSearch, getGarmentDetailUrl, getModelDetailUrl, getWarpDetailUrl } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import Restricted from '../permission/Restricted';
import ImageSlider from '../sliders/ImageSlider';
import BaseSubHeader from './BaseSubHeader';
import HasFeedbackBanner from './HasFeedbackBanner';

interface ValidationSubHeaderProps {
    onImageSizeChange?(newSize: number): void
    onValidate(): void
    onViewBackChange?(newIndex: number): void
    onFeedbackClick(): void
    isValidateDisabled?: boolean
    validateTooltipLabel?: string
    validateButtonLabel?: string
    feedbackButtonLabel?: string
    viewBackValue?: number
    detailData?: CheckGarmentDetail
    feedback?: Feedback
}

export default function ValidationSubHeader(props: ValidationSubHeaderProps) {
    const {
        onImageSizeChange,
        onValidate,
        onViewBackChange,
        onFeedbackClick,
        isValidateDisabled,
        validateTooltipLabel,
        validateButtonLabel,
        feedbackButtonLabel,
        viewBackValue,
        detailData,
        feedback,
    } = props;

    const { t } = useTranslation([CHECKFINAL_LOCALES, COMMON_LOCALES]);
    const { search, pathname } = useLocation();
    const navigate = useCustomNavigate();
    const { isAdmin, isNoRole } = useRoles();
    const { garmentId, warpId } = useParams();

    const [localImageIndex, setLocalImageIndex] = useState<number>(0);

    // ---- Feedback HREF according to pathname ----
    const fbHref = useMemo(() => {
        const isExternal = matchPath('external_validation/:warpId', pathname);
        if (isExternal && warpId) {
            return `/external_validation/${warpId}/feedbacks?${getCleanSearch(search)}`;
        }
        const isCheck = matchPath('check/:garmentId', pathname);
        if (isCheck && garmentId) {
            return `/check/${garmentId}/feedbacks?${getCleanSearch(search)}`;
        }

        return undefined;
    }, [pathname, search, warpId, garmentId]);

    const handleOnViewBackChange = () => {
        setLocalImageIndex(localImageIndex === 0 ? 1 : 0);
    };

    useEffect(() => {
        if (onViewBackChange) {
            onViewBackChange(localImageIndex);
        }
    }, [localImageIndex]);

    useEffect(() => {
        if (viewBackValue && viewBackValue !== localImageIndex) {
            setLocalImageIndex(viewBackValue);
        }
    }, [viewBackValue]);

    return (
        <BaseSubHeader>
            <VStack justifyContent="space-between" spacing={1} w="100%">
                <HStack h="100%" justifyContent="space-between" pl={4} pr={4} w="100%">
                    <VStack alignItems="flex-start" spacing={1}>
                        <Text fontWeight="bold">{t('title')}</Text>
                        <HStack flex={1} justifyContent="flex-start" minW={90} spacing={1} w="100%">
                            {
                                isAdmin && detailData && <>
                                    <Restricted to="old_routes">
                                        <RoundButton
                                            href={getGarmentDetailUrl(detailData.garment_id)}
                                            onClick={() => window.open(getGarmentDetailUrl(detailData.garment_id), '_blank')}
                                            text="GD"
                                            tooltipLabel={t('gd', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <Restricted to="old_routes">
                                        <RoundButton
                                            href={getWarpDetailUrl(detailData.warp_id)}
                                            onClick={() => window.open(getWarpDetailUrl(detailData.warp_id), '_blank')}
                                            text="W"
                                            tooltipLabel={t('w', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <Restricted to="ref_warp.read">
                                        <RoundButton
                                            href={`/warp/${detailData.warp_id}/edit?${getCleanSearch(search)}`}
                                            onClick={() => navigate(`/warp/${detailData?.warp_id}/edit`)}
                                            text="EDIT"
                                            tooltipLabel={t('editW', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <Restricted to="old_routes">
                                        <RoundButton
                                            href={getModelDetailUrl(detailData.model_id)}
                                            onClick={() => window.open(getModelDetailUrl(detailData.model_id), '_blank')}
                                            text="M"
                                            tooltipLabel={t('m', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <Restricted to="preprocessing">
                                        <RoundButton
                                            href={`/preprocessing/${detailData?.garment_id}/quality?${getCleanSearch(search)}`}
                                            secondLine="QC"
                                            text="PP"
                                            tooltipLabel={t('ppqc', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <RoundButton
                                        href={`/refwarp/${detailData.warp_id}/quality?${getCleanSearch(search)}`}
                                        secondLine="QC"
                                        text="RW"
                                        tooltipLabel={t('rwqc', { ns: COMMON_LOCALES })}
                                    />
                                    <Restricted to="crosswarp.internal">
                                        <RoundButton
                                            href={`/crosswarp/${detailData?.warp_id}?${getCleanSearch(search)}`}
                                            text="CW"
                                            tooltipLabel={t('cw', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                </>
                            }
                            {
                                isNoRole
                                    ? <Button
                                        as="a"
                                        href={fbHref}
                                        onClick={(e) => preventCmdCtrlClick(e, () => navigate(fbHref))}
                                        variant="link"
                                    >
                                        {t('feedbacks_history', { ns: COMMON_LOCALES })}
                                    </Button>
                                    : <RoundButton
                                        href={fbHref}
                                        onClick={() => navigate(fbHref)}
                                        text="FB"
                                        tooltipLabel={'Feedbacks'}
                                    />
                            }

                        </HStack>
                    </VStack>

                    <HStack spacing={4}>
                        {
                            onViewBackChange && <HStack>
                                <Text>{t('view_back')}</Text>
                                <Switch isChecked={!!localImageIndex} onChange={handleOnViewBackChange} size="lg" value={localImageIndex} />
                            </HStack>
                        }
                        {
                            onImageSizeChange
                            && <ImageSlider
                                flexDirection='column'
                                localStorageKey={CROSSWARP_IMAGESIZE_KEY}
                                max={5}
                                min={1}
                                onChange={onImageSizeChange}
                            />
                        }

                        <Button onClick={onFeedbackClick} variant="outline">
                            {feedbackButtonLabel || t('report_all')}
                        </Button>
                        <Tooltip isDisabled={!isValidateDisabled || !validateTooltipLabel} label={validateTooltipLabel}>
                            <Button
                                isDisabled={isValidateDisabled}
                                onClick={onValidate}
                            >
                                {validateButtonLabel || t('validate_all', { ns: COMMON_LOCALES })}
                            </Button>
                        </Tooltip>

                    </HStack>
                </HStack>
                {(feedback && detailData) && <HasFeedbackBanner
                    detailLink={fbHref}
                    feedback={feedback}
                />}
            </VStack>
        </BaseSubHeader>
    );
}

import './i18n/config';
import './theme/style.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';

import { ChakraProvider, createStandaloneToast, Spinner, VStack } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';

import Router from './Router';
import { store } from './services/store/store';
import theme from './theme';

const { ToastContainer } = createStandaloneToast();

const App = () => (
    <ChakraProvider theme={theme}>
        <React.Suspense fallback={<VStack height='100vh' justifyContent='center' ><Spinner size='xl'/></VStack>}>
            <Provider store={store}>
                <Router />
            </Provider>
            <ToastContainer/>
        </React.Suspense>
    </ChakraProvider>
);

export default App;

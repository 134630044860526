import { WarningTwoIcon } from '@chakra-ui/icons';
import {
    Box,
    Divider,
    Grid,
    GridItem,
    IconButton,
    Spinner,
    VStack,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { KeypointType, QcResponse, Sample, WarpKeypoints } from '../../types/api-types';
import { MAIN_HEADER_HEIGHT, SUB_HEADER_HEIGHT } from '../../utils/constants';
import { convertApiKeypointsArray } from '../../utils/warp-edit-helpers';
import WarpGridItem from '../warp_edit/WarpGridItem';
import CollapseGridElement from './gridElements/CollapseGridElement';
import OpacityGridElement from './gridElements/OpacityGridElement';
import SampleGridGridElement from './gridElements/SampleGridGridElement';
import SideButtonsGridElement from './gridElements/SideButtonsGridElement';
import SingleImageGridElement from './gridElements/SingleImageElement';
import WarningGridElement from './gridElements/WarningGridElement';
import WarpDetailGridElement from './gridElements/WarpDetailGridElement';

export interface GridObject {
    label?: string,
    imageSrc?: string | string[],
    sideButtons?: string[],
    overlayImages?: string[],
    superpose?: string | string[],
    superposeAnimation?: boolean,
    superposeLabels?: [string, string],
    opacity?: string,
    info?: string,
    gridSample?: Sample[],
    gridChangePage?(): void,
    fraction?: string,
    garmentDetail?: { productName?: string, productCategory?: string, description?: string }
    defaultButton?: number,
    keypoints?: WarpKeypoints,
    TopLeftElement?: React.ReactNode,
    additionalText?: string,
    onIndexChange?(newIndex: number): void,
    dataSrc?: string,
    bottomElement?: ReactNode,
    collapse?: { title: string, content: ReactNode, count?: number }[]
    defaultIndex?: number[],
    warnings?: string[],
    customElement?: ReactNode
}
interface FullScreenGridProps {
    gridObjects: GridObject[],
    customHeight?: string,
    warnings?: QcResponse[] | string[],
    warningsLoading?: boolean,
    loading?: boolean,
    stepString?: string
}

const GRID_ITEM_HEIGHT = `calc(100vh - ${SUB_HEADER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px)`;
const SIDEBUTTONS_WIDTH = '150px';

// ---- Component used to generate QC fullscreen grids ----
export default function FullScreenGrid(props: FullScreenGridProps) {
    const { gridObjects, customHeight, warnings, loading, warningsLoading, stepString } = props;

    const [imageSelected, setImageSelected] = useState<number | null>(null);

    const [imageOffset, setImageOffset] = useState<string>();

    const [showWarnings, setShowWarnings] = useState<boolean>(false);

    // ---- Reset the imageSelected Value when we change gridObjects (change QC Step) ----
    useEffect(() => {
        setImageSelected(null);
        setImageOffset(undefined);

        // ---- Reset Show warnings ----
        if (showWarnings && (!warnings || warnings.length === 0)) {
            setShowWarnings(false);
        }

        // ---- We set the imageOffset state so we have the same image size on each element ----
        gridObjects.forEach((gridObject) => {
            if (gridObject.sideButtons) {
                if (gridObject.defaultButton || gridObject.defaultButton === 0) {
                    setImageSelected(gridObject.defaultButton);
                }
                setImageOffset(SIDEBUTTONS_WIDTH);
            }
        });
    }, [gridObjects]);

    // ---- Used to render a different layout for steps ----
    const customTemplate = useMemo(() => {
        let hasFraction = false; // Needed to know if we have at least 1 custom value
        const result: string[] = [];

        // ---- For each object we check if we have a custom fraction value ----
        gridObjects.forEach((gridObject) => {
            if (gridObject.fraction) {
                hasFraction = true;

                return result.push(gridObject.fraction);
            }

            // ---- default value is 1fr ----
            return result.push('1fr');
        });

        // ---- If no custom value is given we return null so we use the default template ----
        if (!hasFraction) {
            return null;
        }

        // ---- We push 1 element to the template if we have the showWarnings to true ----
        if (showWarnings) {
            result.push('1fr');
        }

        return result.join(' ');
    }, [gridObjects, showWarnings]);

    const getElementFromGridObject = (gridObject: GridObject) => {
        // ---- Takes priority over normal element ----
        if (gridObject.customElement) {
            return gridObject.customElement;
        }

        // ---- DON'T NEED gridObject.imageSrc ----
        if (gridObject.gridSample && gridObject.gridChangePage) {
            return <SampleGridGridElement
                gridChangePage={gridObject.gridChangePage}
                gridSample={gridObject.gridSample}
                info={gridObject.info}
                label={gridObject.label}
            />;
        }

        if (gridObject.collapse) {
            return <CollapseGridElement
                collapse={gridObject.collapse}
                defaultIndex={gridObject.defaultIndex}
                warnings={gridObject.warnings}
            />;
        }

        // ---- If we get to this point we need the imageSrc if not return null ----
        if (!gridObject.imageSrc) {
            return null;
        }

        // ---- NEED gridObject.imageSrc ----
        if (gridObject.opacity) {
            return <OpacityGridElement
                additionalText={gridObject.additionalText}
                imageSrc={gridObject.imageSrc}
                info={gridObject.info}
                label={gridObject.label}
                opacitySrc={gridObject.opacity}
            />;
        }

        if (gridObject.sideButtons) {
            return <SideButtonsGridElement
                imageSelected={imageSelected}
                imageSrc={gridObject.imageSrc}
                info={gridObject.info}
                label={gridObject.label}
                offsetWidth={imageOffset}
                onButtonClick={setImageSelected}
                overlay={gridObject.overlayImages}
                sideButtons={gridObject.sideButtons}
                superpose={gridObject.superpose}
            />;
        }

        if (gridObject.garmentDetail) {
            return <WarpDetailGridElement
                bottomElement={gridObject.bottomElement}
                garmentDetail={gridObject.garmentDetail}
                imageSrc={gridObject.imageSrc}
                label={gridObject.label}
            />;
        }

        if (gridObject.keypoints && typeof gridObject.imageSrc !== 'string') {
            let localDestinationKps: KeypointType[] = [];

            // ---- For each type we update the keypoints array with the matching keypoints ----
            Object.keys(gridObject.keypoints).forEach((cutType) => {
                if (!gridObject.keypoints) {
                    return;
                }

                localDestinationKps = localDestinationKps.concat(
                    convertApiKeypointsArray(gridObject.keypoints[cutType].destination, cutType),
                );
            });

            return (
                <WarpGridItem
                    info={gridObject.info}
                    kpList={localDestinationKps}
                    noEdit={true}
                    srcs={gridObject.imageSrc}
                    title={gridObject.label}
                    warpType=''
                />
            );
        }

        return <SingleImageGridElement
            TopLeftElement={gridObject.TopLeftElement}
            additionalText={gridObject.additionalText}
            dataSrc={gridObject.dataSrc}
            imageSrc={gridObject.imageSrc}
            info={gridObject.info}
            label={gridObject.label}
            superpose={gridObject.superpose}
            superposeAnimation={!!gridObject.superposeAnimation}
            superposeLabels={gridObject.superposeLabels}
        />;
    };

    return (
        <Box id="qcContainerId" position="relative">
            {
                loading
                    ? <VStack alignItems="center" height={customHeight || GRID_ITEM_HEIGHT} justifyContent="center" w="100%"><Spinner /></VStack>
                    : <Grid templateColumns={customTemplate || `repeat(${gridObjects.length + (showWarnings ? 1 : 0)}, 1fr)`}>
                        {
                            gridObjects.map((gridObject, index) => (
                                <GridItem
                                    display="flex"
                                    flexDir="row"
                                    height={customHeight || GRID_ITEM_HEIGHT}
                                    key={(stepString || '') + gridObject.label + index}
                                    pb={2}
                                >
                                    {getElementFromGridObject(gridObject)}
                                    {gridObjects.length > 1 && <Divider orientation='vertical' />}
                                </GridItem>
                            ))
                        }
                        {
                            warningsLoading && <Spinner position="absolute" right={2} top={2} />
                        }
                        {
                            warnings && warnings.length > 0 && <>
                                {
                                    !showWarnings && <IconButton
                                        aria-label='warning-button'
                                        icon={<>
                                            <WarningTwoIcon color="orange" fontSize={24} />
                                            <Box
                                                as="span"
                                                bgColor="red"
                                                borderRadius="7px"
                                                color="white"
                                                fontSize={10}
                                                p="1px"
                                                position="absolute"
                                                right={0}
                                                top={0}
                                                w="14px"
                                                zIndex={1}
                                            >
                                                {warnings.length}
                                            </Box>
                                        </>}
                                        onClick={() => setShowWarnings(true)}
                                        position="absolute"
                                        right={2}
                                        top={2}
                                        variant="ghost"
                                    />
                                }
                                {
                                    showWarnings && <GridItem display="flex" flexDir="row" height={customHeight || GRID_ITEM_HEIGHT} pb={2}>
                                        <Divider orientation='vertical' />
                                        <WarningGridElement onClose={() => setShowWarnings(false)} warnings={warnings} />
                                    </GridItem>
                                }
                            </>
                        }
                    </Grid>
            }

        </Box>
    );
}

import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { WarpQuality } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_IMAGESIZE_KEY, CROSSWARP_LOCALES, UNAUTHORIZED_RWQC_STATUS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import { getCleanSearch, getGarmentDetailUrl, getModelDetailUrl, getWarpDetailUrl } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import { useCrossWarpsContext } from '../crosswarps/CrossWarpsContext';
import Restricted from '../permission/Restricted';
import ImageSlider from '../sliders/ImageSlider';
import OpacitySlider from '../sliders/OpacitySlider';
import BaseSubHeader from './BaseSubHeader';

interface CrossWarpCheckSubHeaderProps {
    warpDetail?: WarpQuality
    warpId?: string
    onImageSizeChange(newSize: number): void
    onOpacityChange(newOpacity: number): void
    onSendToQC(): void
    onFeedbackClick(): void
    onValidate(): void
}

export default function CrossWarpCheckSubHeader(props: CrossWarpCheckSubHeaderProps) {
    const { onFeedbackClick, onImageSizeChange, onOpacityChange, onSendToQC, onValidate, warpDetail, warpId } = props;

    const { search } = useLocation();
    const { t } = useTranslation([COMMON_LOCALES, CROSSWARP_LOCALES]);
    const navigate = useCustomNavigate();

    const CrossWarpsContext = useCrossWarpsContext();

    const isGDDisabled = useMemo(() => !warpDetail || (warpDetail && !warpDetail.garment_id), [warpDetail]);
    const gdHref = useMemo(() => {
        if (isGDDisabled || !warpDetail?.garment_id) {
            return undefined;
        }

        return getGarmentDetailUrl(warpDetail.garment_id);
    }, [isGDDisabled, warpDetail]);

    const isRWDisabled = useMemo(
        () => !warpDetail || !warpDetail.warp_id, [warpDetail],
    );
    const rwHref = useMemo(() => {
        if (isRWDisabled || !warpDetail) {
            return undefined;
        }

        return getWarpDetailUrl(warpDetail.warp_id);
    }, [isRWDisabled, warpDetail]);

    const isMDisabled = useMemo(
        () => !warpDetail || !warpDetail.model_id, [warpDetail],
    );
    const mHref = useMemo(() => {
        if (isMDisabled || !warpDetail) {
            return undefined;
        }

        return getModelDetailUrl(warpDetail.model_id);
    }, [isRWDisabled, warpDetail]);

    const isPPQCDisabled = useMemo(() => !warpDetail, [warpDetail]);
    const ppqcHref = useMemo(() => {
        if (isPPQCDisabled) {
            return undefined;
        }

        return `/preprocessing/${warpDetail?.garment_id}/quality?${getCleanSearch(search)}`;
    }, [isPPQCDisabled, warpDetail, search]);

    const isRWQCDisabled = useMemo(() => !warpDetail
        || UNAUTHORIZED_RWQC_STATUS.includes(warpDetail.warp_status)
        || !warpDetail.warp_status
        || !warpDetail.warp_id, [warpDetail]);
    const rwqcHref = useMemo(() => {
        if (isRWQCDisabled) {
            return undefined;
        }

        return `/refwarp/${warpId}/quality?${getCleanSearch(search)}`;
    }, [isRWQCDisabled, search]);

    return (
        <BaseSubHeader>
            <HStack h="100%" justifyContent="space-between" pl="8px" pr="8px" w="100%">
                <VStack spacing={1}>
                    <Text color="black" fontWeight="bold">{t('garments.title', { ns: CROSSWARP_LOCALES })}</Text>
                    <HStack alignItems="center" justifyContent="space-evenly" pb={1} pt={1} spacing={1} w="100%">
                        <Restricted to="old_routes">
                            <RoundButton
                                href={gdHref}
                                isDisabled={isGDDisabled}
                                onClick={() => window.open(gdHref, '_blank')}
                                text="GD"
                                tooltipLabel={t('gd', { ns: COMMON_LOCALES })}
                            />
                            <RoundButton
                                href={rwHref}
                                isDisabled={isRWDisabled}
                                onClick={() => window.open(rwHref, '_blank')}
                                text="RW"
                                tooltipLabel={t('rw', { ns: COMMON_LOCALES })}
                            />
                            <RoundButton
                                href={mHref}
                                isDisabled={isMDisabled}
                                onClick={() => window.open(mHref, '_blank')}
                                text="M"
                                tooltipLabel={t('m', { ns: COMMON_LOCALES })}
                            />
                            <RoundButton
                                href={ppqcHref}
                                isDisabled={isPPQCDisabled}
                                onClick={() => navigate(`/preprocessing/${warpDetail?.garment_id}/quality`)}
                                secondLine="QC"
                                text="PP"
                                tooltipLabel={t('ppqc')}
                            />
                        </Restricted>
                        <RoundButton
                            href={rwqcHref}
                            isDisabled={isRWQCDisabled}
                            onClick={() => navigate(`/refwarp/${warpId}/quality`)}
                            secondLine="QC"
                            text="RW"
                            tooltipLabel={t('rwqc')}
                        />
                    </HStack>
                </VStack>
                <HStack>
                    <VStack justifyContent="center" minH="32px" pr={4}>
                        <OpacitySlider defaultValue={1} onChange={onOpacityChange} />
                    </VStack>
                    <VStack justifyContent="center" minH="32px" pr={4}>
                        <ImageSlider localStorageKey={CROSSWARP_IMAGESIZE_KEY} max={5} min={1} onChange={onImageSizeChange} />
                    </VStack>
                </HStack>

                <HStack>
                    <VStack alignItems={'flex-end'}>
                        <div>
                            {t('grid.selected')}{CrossWarpsContext?.selectedCrossWarps.length}
                        </div>
                        {
                            CrossWarpsContext
                            && <HStack spacing={8}>
                                <Button onClick={() => CrossWarpsContext.changeCheckAll(true)} variant='link'>
                                    {t('grid.select_all')}
                                </Button>
                                <Button onClick={() => CrossWarpsContext.changeCheckAll(false)} variant='link'>
                                    {t('grid.reset')}
                                </Button>
                            </HStack>
                        }
                    </VStack>
                    {
                        CrossWarpsContext
                        && <>
                            <Button
                                isDisabled={CrossWarpsContext.selectedCrossWarps.length === 0}
                                onClick={onSendToQC}
                                variant="outline"
                            >
                                {t('send_to_qc', { ns: CROSSWARP_LOCALES })}
                            </Button>
                            <Button
                                isDisabled={CrossWarpsContext.selectedCrossWarps.length === 0}
                                onClick={onValidate}
                            >
                                {t('validate')}
                            </Button>
                        </>
                    }
                    <Button onClick={onFeedbackClick} variant="red">
                        {t('rw_feedback', { ns: CROSSWARP_LOCALES })}
                    </Button>
                </HStack>

            </HStack>
        </BaseSubHeader>
    );
}

import { Button, Checkbox, HStack, Radio, RadioGroup, Spinner, Switch, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { WarpEditResponse } from '../../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, WARP_EDIT_LOCALES, WARPEDIT_STEPS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useRoles from '../../utils/roles-hook';
import { getCleanSearch, getGarmentDetailUrl, getNewWarpEditUrl, getWarpDetailUrl } from '../../utils/url-helpers';
import DropdownButton from '../buttons/DropdownButton';
import RoundButton from '../buttons/RoundButton';
import Restricted from '../permission/Restricted';
import { useSuperposeAnimationContext } from '../SuperposeAnimationContext';
import WarpEditConfigPanel from '../WarpEditConfigPanel';
import BaseSubHeader from './BaseSubHeader';

interface WarpEditSubHeaderProps {
    blockEdit?: boolean,
    stepString: string,
    stepArray: string[],
    textures: string[],
    allowNoSmart?: boolean,
    activePart: string,
    activeTexture: string,
    visibleValues: string[],
    isWarpInitialized: boolean,
    isComparisonLoading: boolean,
    isPostWarpError: boolean,
    isPostWarpLoading: boolean,
    isSmartwarpDisabled: boolean,
    isUpperfixDisabled: boolean,
    useFinalPayload: boolean,
    disableCheckResult: boolean,
    warpData?: WarpEditResponse,
    handleActivePartChange(newPart: string): void,
    handleStepChange(newStep: string): void,
    nextStep(): void,
    handleVisibleCheck(e: React.ChangeEvent<HTMLInputElement>, cutKey: string): void,
    handleTextureChange(newTexture: string): void,
    handleSmartwarpCheck(e: React.ChangeEvent<HTMLInputElement>): void,
    handleDisableSmartwarpCheck(e: React.ChangeEvent<HTMLInputElement>): void,
    handleDisableUpperfix(e: React.ChangeEvent<HTMLInputElement>): void,
    handlePostSmart(): void
    handleFeedbackClick(): void
}

export default function WarpEditSubHeader(props: WarpEditSubHeaderProps) {
    // ---- Util hook ----
    const { t } = useTranslation([WARP_EDIT_LOCALES, COMMON_LOCALES]);
    const navigate = useNavigate();
    const customNavigate = useCustomNavigate();
    const { isAdmin } = useRoles();
    const { search } = useLocation();

    // ---- Superpose Context ----
    const SuperposeAnimationContext = useSuperposeAnimationContext();

    // ---- Props ----
    const {
        blockEdit,
        stepString,
        stepArray,
        textures,
        allowNoSmart,
        activePart,
        activeTexture,
        visibleValues,
        isWarpInitialized,
        isComparisonLoading,
        isPostWarpError,
        isPostWarpLoading,
        isSmartwarpDisabled,
        isUpperfixDisabled,
        useFinalPayload,
        disableCheckResult,
        warpData,
        handleStepChange,
        nextStep,
        handleActivePartChange,
        handleVisibleCheck,
        handleTextureChange,
        handleSmartwarpCheck,
        handleDisableSmartwarpCheck,
        handleDisableUpperfix,
        handlePostSmart,
        handleFeedbackClick,
    } = props;
    const { warpId, garmentId } = useParams();

    // ---- Callback ----
    const cutOptionDisabled = useCallback(
        (cutKey: string) => (cutKey !== 'torso' && !isWarpInitialized) || blockEdit,
        [isWarpInitialized, blockEdit],
    );

    // ---- Functions ----
    const handleFeedbackMouseEnter = () => {
        if (SuperposeAnimationContext && SuperposeAnimationContext.setAnimate) {
            SuperposeAnimationContext.setAnimate(false);
        }
    };

    const handleFeedbackMouseLeave = () => {
        if (SuperposeAnimationContext && SuperposeAnimationContext.setAnimate) {
            SuperposeAnimationContext.setAnimate(true);
        }
    };

    const isCWDisabled = useMemo(() => warpData?.warp_status !== 'READY' && !warpData?.is_override, [warpData]);
    const cwHref = useMemo(() => {
        if (isCWDisabled) {
            return undefined;
        }

        return `/crosswarp/${warpData?.warp_id}?${getCleanSearch(search)}`;
    }, [isCWDisabled, warpData, search]);

    return (
        <BaseSubHeader>
            <HStack pl={4} pr={4} w="100%">
                <VStack alignItems="flex-start" minW={'150px'}>
                    <Text fontWeight="bold">{t('title')}</Text>
                    <HStack>
                        <Restricted to="old_routes">
                            {
                                warpId
                                && <RoundButton
                                    href={getWarpDetailUrl(warpId)}
                                    onClick={() => window.open(getWarpDetailUrl(warpId), '_blank')}
                                    text="W"
                                    tooltipLabel={t('w', { ns: COMMON_LOCALES })}
                                />
                            }
                            {
                                garmentId
                                && <>
                                    <RoundButton
                                        href={getNewWarpEditUrl(garmentId)}
                                        onClick={() => window.open(getNewWarpEditUrl(garmentId), '_blank')}
                                        text="W"
                                        tooltipLabel={t('w', { ns: COMMON_LOCALES })}
                                    />
                                    <RoundButton
                                        href={getGarmentDetailUrl(garmentId)}
                                        onClick={() => window.open(getGarmentDetailUrl(garmentId), '_blank')}
                                        text="GD"
                                        tooltipLabel={t('gd', { ns: COMMON_LOCALES })}
                                    />
                                </>
                            }
                            {
                                warpData && <Restricted to="crosswarp.internal">
                                    <RoundButton
                                        href={cwHref}
                                        isDisabled={isCWDisabled}
                                        onClick={() => customNavigate(`/crosswarp/${warpData.warp_id}`)}
                                        text="CW"
                                        tooltipLabel={t('cw', { ns: COMMON_LOCALES })}
                                    />
                                </Restricted>
                            }

                        </Restricted>
                    </HStack>
                </VStack>
                {
                    stepString === WARPEDIT_STEPS.EDIT
                    && <HStack flex={1} spacing={4} w="100%">
                        <WarpEditConfigPanel title={t('active_part')}>
                            <RadioGroup defaultValue="torso" onChange={handleActivePartChange} value={activePart} w="100%">
                                <Wrap paddingLeft={2} paddingRight={2} spacing={0}>
                                    {
                                        warpData && Object.keys(warpData.garment_cuts).map((cutKey) => (
                                            <WrapItem key={cutKey} minW="calc((100% - 8px)/ 3)">
                                                <Radio isDisabled={cutOptionDisabled(cutKey)} value={cutKey}>
                                                    {t(`${cutKey}`, { ns: COMMON_LOCALES })}
                                                </Radio>
                                            </WrapItem>
                                        ))
                                    }
                                </Wrap>
                            </RadioGroup>
                        </WarpEditConfigPanel>

                        <WarpEditConfigPanel title={t('visualisation')}>
                            <HStack justify="space-between" p="0 8px" w="100%">
                                <DropdownButton title={t('garment_part')}>
                                    {
                                        warpData && Object.keys(warpData.garment_cuts).map((cutKey) => (
                                            <Checkbox
                                                defaultChecked={cutKey === 'torso'}
                                                isChecked={visibleValues.includes(cutKey)}
                                                isDisabled={cutOptionDisabled(cutKey)}
                                                key={cutKey}
                                                onChange={(e) => handleVisibleCheck(e, cutKey)}
                                                value={cutKey}
                                            >
                                                {t(`${cutKey}`, { ns: COMMON_LOCALES })}
                                            </Checkbox>
                                        ))
                                    }
                                </DropdownButton>

                                <DropdownButton title={t('garment_texture')}>
                                    <RadioGroup
                                        defaultValue={activeTexture}
                                        display="flex"
                                        flexWrap="wrap"
                                        gridGap={4}
                                        isDisabled={blockEdit}
                                        onChange={handleTextureChange}
                                        w="100%"
                                    >
                                        {
                                            textures.map((texture) => (
                                                <Radio key={texture} value={texture}>{t(`textureType.${texture}`)}</Radio>
                                            ))
                                        }
                                    </RadioGroup>
                                </DropdownButton>
                            </HStack>
                        </WarpEditConfigPanel>

                        <WarpEditConfigPanel title={t('smart_warp')}>
                            <HStack h="100%" justify={allowNoSmart ? 'space-between' : 'center'} p="0 8px" w="100%">
                                <VStack alignItems="flex-start" h="100%" justifyContent="flex-start">
                                    <HStack>
                                        <Switch isChecked={useFinalPayload} isDisabled={blockEdit} onChange={handleSmartwarpCheck} />
                                        <Text>{t('show_final')}</Text>
                                    </HStack>
                                    {
                                        (isAdmin || warpData?.is_override) && <HStack>
                                            <Button
                                                fontSize="xs"
                                                isDisabled={blockEdit || !useFinalPayload || isSmartwarpDisabled}
                                                onClick={handlePostSmart}
                                                variant="link"
                                            >
                                                <Text>{t('post_smart')}</Text>
                                            </Button>
                                        </HStack>
                                    }
                                </VStack>
                                {
                                    allowNoSmart && <VStack alignItems="flex-start" h="100%">
                                        <Checkbox
                                            isChecked={isSmartwarpDisabled}
                                            isDisabled={blockEdit}
                                            onChange={handleDisableSmartwarpCheck}
                                        >
                                            {t('disable_smart')}
                                        </Checkbox>
                                        <Checkbox
                                            isChecked={isUpperfixDisabled}
                                            isDisabled={blockEdit}
                                            onChange={handleDisableUpperfix}
                                        >
                                            {t('disable_upperfix')}
                                        </Checkbox>
                                    </VStack>
                                }
                            </HStack>
                        </WarpEditConfigPanel>
                        <Button isDisabled={disableCheckResult} onClick={nextStep}>
                            {t('next_step', { ns: COMMON_LOCALES })}
                        </Button>
                    </HStack>
                }

                {
                    stepString === WARPEDIT_STEPS.SMART_COMPARISON
                    && <>
                        <Text color={isPostWarpError ? 'red' : 'black'} flex={2} noOfLines={[1, 2]} pl={2} textAlign="center">
                            {isPostWarpError && t('comparison_error_post', { ns: ERROR_LOCALES })}
                        </Text>
                        <HStack flex={1} justifyContent="flex-end" spacing={4} w="100%">
                            {(isComparisonLoading && !isPostWarpError) && <Spinner />}
                            <Button onClick={() => handleStepChange(WARPEDIT_STEPS.EDIT)} variant="outline">
                                {t('back_to_edit')}
                            </Button>
                            <Button
                                onClick={handleFeedbackClick}
                                onMouseEnter={handleFeedbackMouseEnter}
                                onMouseLeave={handleFeedbackMouseLeave}
                                variant="red"
                            >
                                {t('report_problem')}
                            </Button>
                            <Button isDisabled={isComparisonLoading || isPostWarpError} onClick={nextStep}>
                                {allowNoSmart
                                    ? `${t('choose', { ns: COMMON_LOCALES })} ${t(isSmartwarpDisabled ? 'no_smart_warp' : 'smart_warp')}`
                                    : t('confirm', { ns: COMMON_LOCALES })
                                }
                            </Button>
                        </HStack>
                    </>
                }

                {
                    stepString === WARPEDIT_STEPS.SIZING
                    && <HStack flex={1} justifyContent="flex-end" spacing={4} w="100%">
                        {isPostWarpLoading && <Spinner />}
                        <Button onClick={() => handleStepChange(WARPEDIT_STEPS.EDIT)} variant="outline">
                            {t('back_to_edit')}
                        </Button>
                        <Button onClick={() => handleStepChange(WARPEDIT_STEPS.SMART_COMPARISON)} variant="outline">
                            {t('back_to_smartwarp')}
                        </Button>
                        <Button isDisabled={isPostWarpError} onClick={nextStep}>
                            {t('next_step', { ns: COMMON_LOCALES })}
                        </Button>
                    </HStack>
                }

                {
                    stepString === WARPEDIT_STEPS.FINAL_CHECK
                    && <HStack flex={1} justifyContent="flex-end" spacing={4} w="100%">
                        {isPostWarpLoading && <Spinner />}
                        <Button onClick={() => handleStepChange(WARPEDIT_STEPS.EDIT)} variant="outline">
                            {t('back_to_edit')}
                        </Button>
                        <Button onClick={() => handleStepChange(WARPEDIT_STEPS.SMART_COMPARISON)} variant="outline">
                            {t('back_to_smartwarp')}
                        </Button>
                        {
                            stepArray.includes(WARPEDIT_STEPS.SIZING)
                            && <Button onClick={() => handleStepChange(WARPEDIT_STEPS.SIZING)} variant="outline">
                                {t('back_to_sizing')}
                            </Button>
                        }
                        <Button isDisabled={isPostWarpLoading} onClick={nextStep}>
                            {t('confirm_save')}
                        </Button>
                    </HStack>
                }

                {
                    stepString === WARPEDIT_STEPS.FINISH
                    && <HStack flex={1} justifyContent="flex-end" spacing={4} w="100%">
                        {isPostWarpLoading && <Spinner />}
                        <Button onClick={() => navigate(-1)} variant="outline">
                            {t('back_to_list')}
                        </Button>
                    </HStack>
                }
            </HStack>

        </BaseSubHeader>
    );
}

import { WarningTwoIcon } from '@chakra-ui/icons';
import { Button, HStack, Spinner, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { Feedback, GarmentQuality, WarpQuality } from '../../types/api-types';
import { COMMON_LOCALES, PREPROCESSING_LOCALES, SUB_HEADER_HEIGHT } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import { getCleanSearch, getGarmentDetailUrl, getModelDetailUrl, getWarpDetailUrl } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import Restricted from '../permission/Restricted';
import { useSuperposeAnimationContext } from '../SuperposeAnimationContext';
import HasFeedbackBanner from './HasFeedbackBanner';

interface QualityCheckSubHeaderProps {
    children?: React.ReactNode,
    title?: string,
    incrementStep: () => void,
    feedbackClick: () => void,
    showBackToList?: boolean,
    nextButtonLabel: string,
    isValidated: boolean,
    noValidation?: boolean,
    noValidationInfo?: string,
    validationLoading?: boolean,
    noFinishedButtons?: boolean,
    noFeedback?: boolean,
    hasFeedbackLink?: string,
    lastFeedback?: Feedback,
    isActiveFeedback?: boolean,
    garmentQuality?: GarmentQuality,
    warpQuality?: WarpQuality,
    isLoading?: boolean,
    pendingFeedbacksNb?: number
}

export default function QualityCheckSubHeader(props: QualityCheckSubHeaderProps) {
    const { t } = useTranslation([PREPROCESSING_LOCALES, COMMON_LOCALES]);
    const {
        children,
        title,
        incrementStep,
        feedbackClick,
        noValidation,
        noValidationInfo,
        validationLoading,
        showBackToList,
        isValidated,
        noFinishedButtons,
        noFeedback,
        hasFeedbackLink,
        lastFeedback,
        nextButtonLabel,
        garmentQuality,
        warpQuality,
        isLoading,
        pendingFeedbacksNb,
    } = props;

    const SuperposeAnimationContext = useSuperposeAnimationContext();

    const handleChangeStep = () => incrementStep();

    const { pathname, search } = useLocation();

    const navigate = useCustomNavigate();

    const isGDDisabled = useMemo(() => !garmentQuality && (warpQuality && !warpQuality.garment_id), [garmentQuality, warpQuality]);
    const gdHref = useMemo(() => {
        const garmentId = garmentQuality?.garment_id || warpQuality?.garment_id;
        if (isGDDisabled || !garmentId) {
            return undefined;
        }

        return getGarmentDetailUrl(garmentId);
    }, [isGDDisabled, garmentQuality, warpQuality]);

    const isWDisabled = useMemo(
        () => !warpQuality || !warpQuality.warp_id, [warpQuality],
    );
    const wHref = useMemo(() => {
        if (isWDisabled || !warpQuality) {
            return undefined;
        }

        return getWarpDetailUrl(warpQuality.warp_id);
    }, [isWDisabled, warpQuality]);

    const editHref = useMemo(() => {
        if (isWDisabled || !warpQuality) {
            return undefined;
        }

        return `/warp/${warpQuality.warp_id}/edit?${getCleanSearch(search)}`;
    }, [isWDisabled, warpQuality]);

    const isMDisabled = useMemo(
        () => !warpQuality || !warpQuality.model_id, [warpQuality],
    );
    const mHref = useMemo(() => {
        if (isMDisabled || !warpQuality) {
            return undefined;
        }

        return getModelDetailUrl(warpQuality.model_id);
    }, [isWDisabled, warpQuality]);

    // ---- We concidere that the Refwarp is already processed here ----
    const isRWQCDisabled = useMemo(() => !warpQuality, [warpQuality]);
    const rwqcHref = useMemo(() => {
        if (isRWQCDisabled) {
            return undefined;
        }

        return `/refwarp/${warpQuality?.reference?.warp_id}/quality?${getCleanSearch(search)}`;
    }, [isRWQCDisabled, warpQuality, search]);

    // ---- We concidere that the Refwarp is already processed here ----
    const isCWDisabled = useMemo(() => (warpQuality?.is_override
        ? (!warpQuality?.reference?.warp_id || warpQuality?.reference?.warp_status !== 'READY')
        : warpQuality?.warp_status !== 'READY'),
    [warpQuality]);
    const cwHref = useMemo(() => {
        if (isCWDisabled) {
            return undefined;
        }

        return `/crosswarp/${warpQuality?.reference ? warpQuality?.reference?.warp_id : warpQuality?.warp_id}?${getCleanSearch(search)}`;
    }, [isCWDisabled, warpQuality, search]);

    // ---- We concidere that the Garment is already processed here ----
    const isPPQCDisabled = useMemo(() => !warpQuality, [warpQuality]);
    const ppqcHref = useMemo(() => {
        if (isPPQCDisabled) {
            return undefined;
        }

        return `/preprocessing/${warpQuality?.garment_id}/quality?${getCleanSearch(search)}`;
    }, [isPPQCDisabled, warpQuality, search]);

    const isGarmentFBDisabled = useMemo(() => !lastFeedback || !garmentQuality, [lastFeedback, garmentQuality]);
    const garmentFBHref = useMemo(() => {
        if (isGarmentFBDisabled) {
            return undefined;
        }

        return `/garment/${garmentQuality?.garment_id}/feedbacks?${getCleanSearch(search)}`;
    }, [isGarmentFBDisabled, garmentQuality, search]);

    const isWarpFBDisabled = useMemo(() => !lastFeedback || !warpQuality, [lastFeedback, warpQuality]);
    const warpFBHref = useMemo(() => {
        if (isWarpFBDisabled) {
            return undefined;
        }

        return `/warp/${warpQuality?.warp_id}/feedbacks?${getCleanSearch(search)}`;
    }, [isWarpFBDisabled, warpQuality, search]);

    const backHref = useMemo((() => {
        if (matchPath('/preprocessing/:garmentId/quality', pathname)) {
            return `/preprocessing?${getCleanSearch(search)}`;
        }
        if (matchPath('/refwarp/:warpId/quality', pathname)) {
            return `/refwarp?${getCleanSearch(search)}`;
        }
        if (matchPath('/crosswarp/:warpId/quality', pathname)) {
            return `/crosswarp?${getCleanSearch(search)}`;
        }

        return '';
    }), [pathname, search]);

    const handleFeedbackMouseEnter = () => {
        if (SuperposeAnimationContext && SuperposeAnimationContext.setAnimate) {
            SuperposeAnimationContext.setAnimate(false);
        }
    };

    const handleFeedbackMouseLeave = () => {
        if (SuperposeAnimationContext && SuperposeAnimationContext.setAnimate) {
            SuperposeAnimationContext.setAnimate(true);
        }
    };

    return (
        <VStack backgroundColor="gray.200" h={SUB_HEADER_HEIGHT} justifyContent="center" spacing={0} w="100%">
            {
                isLoading
                    ? <Spinner />
                    : <>
                        <HStack h="100%" pl={4} pr={4} w="100%">
                            <VStack alignItems="flex-start" flex={1} spacing={1}>
                                <Text color="black" fontWeight="bold" noOfLines={1} textAlign="left">{title}</Text>
                                {
                                    (warpQuality || garmentQuality)
                                    && <HStack flex={1} justifyContent="flex-start" minW={90} spacing={1} w="100%">
                                        <Restricted to="old_routes">
                                            <RoundButton
                                                href={gdHref}
                                                isDisabled={isGDDisabled}
                                                onClick={() => window.open(gdHref, '_blank')}
                                                text="GD"
                                                tooltipLabel={t('gd', { ns: COMMON_LOCALES })}
                                            />
                                        </Restricted>
                                        {
                                            warpQuality && <>
                                                <Restricted to="old_routes">
                                                    <RoundButton
                                                        href={wHref}
                                                        isDisabled={isWDisabled}
                                                        onClick={() => window.open(wHref, '_blank')}
                                                        text="W"
                                                        tooltipLabel={t('w', { ns: COMMON_LOCALES })}
                                                    />
                                                </Restricted>
                                                <Restricted to="ref_warp.read">
                                                    <RoundButton
                                                        href={editHref}
                                                        onClick={() => navigate(`/warp/${warpQuality.warp_id}/edit`)}
                                                        text="EDIT"
                                                        tooltipLabel={t('editW', { ns: COMMON_LOCALES })}
                                                    />
                                                </Restricted>
                                                <Restricted to="old_routes">
                                                    <RoundButton
                                                        href={mHref}
                                                        isDisabled={isMDisabled}
                                                        onClick={() => window.open(mHref, '_blank')}
                                                        text="M"
                                                        tooltipLabel={t('m', { ns: COMMON_LOCALES })}
                                                    />
                                                </Restricted>
                                                <Restricted to="preprocessing">
                                                    <RoundButton
                                                        href={ppqcHref}
                                                        isDisabled={isPPQCDisabled}
                                                        onClick={() => navigate(
                                                            `/preprocessing/${warpQuality.garment_id}/quality`,
                                                        )}
                                                        secondLine="QC"
                                                        text="PP"
                                                        tooltipLabel={t('ppqc', { ns: COMMON_LOCALES })}
                                                    />
                                                </Restricted>
                                                {
                                                    matchPath('/crosswarp/:warpId/quality', pathname)
                                                    && <>
                                                        <RoundButton
                                                            href={rwqcHref}
                                                            isDisabled={isRWQCDisabled}
                                                            onClick={() => navigate(`/refwarp/${warpQuality.reference?.warp_id}/quality`)}
                                                            secondLine="QC"
                                                            text="RW"
                                                            tooltipLabel={t('rwqc', { ns: COMMON_LOCALES })}
                                                        />
                                                    </>
                                                }
                                                <Restricted to="crosswarp.internal">
                                                    <RoundButton
                                                        href={cwHref}
                                                        isDisabled={isCWDisabled}
                                                        onClick={() => navigate(
                                                            `/crosswarp/${warpQuality?.reference
                                                                ? warpQuality?.reference?.warp_id : warpQuality?.warp_id
                                                            }`,
                                                        )}
                                                        text="CW"
                                                        tooltipLabel={t('cw', { ns: COMMON_LOCALES })}
                                                    />
                                                </Restricted>
                                                <RoundButton
                                                    href={warpFBHref}
                                                    isDisabled={isWarpFBDisabled}
                                                    onClick={() => navigate(`/warp/${warpQuality.warp_id}/feedbacks`)}
                                                    text="FB"
                                                    tooltipLabel={'Feedbacks'}
                                                />
                                            </>
                                        }

                                        {
                                            garmentQuality
                                            && <RoundButton
                                                href={garmentFBHref}
                                                isDisabled={isGarmentFBDisabled}
                                                onClick={() => navigate(`/garment/${garmentQuality.garment_id}/feedbacks`)}
                                                text="FB"
                                                tooltipLabel={'Feedbacks'}
                                            />
                                        }

                                    </HStack>
                                }
                            </VStack>
                            <HStack flex={5}>
                                <VStack flex={3}>
                                    {children}
                                </VStack>
                                <HStack flex={1} justifyContent="flex-end">
                                    {
                                        isValidated
                                            ? <> {
                                                !noFinishedButtons && <>
                                                    <Button as='a' href={backHref} variant="outline">{t('back_to_list')}</Button>
                                                </>
                                            }
                                            </>
                                            : <>
                                                {
                                                    (pendingFeedbacksNb !== undefined && pendingFeedbacksNb !== 0)
                                                    && <Tooltip
                                                        label={`${t('feedback.toast_pending_title', { ns: COMMON_LOCALES })}: ${
                                                            t('feedback.toast_pending_description', { ns: COMMON_LOCALES })}`}
                                                    >
                                                        <WarningTwoIcon color="orange"/>
                                                    </Tooltip>

                                                }
                                                {
                                                    showBackToList
                                                    && <Button as='a' href={backHref} variant="outline">{t('back_to_list')}</Button>
                                                }
                                                {
                                                    !noFeedback
                                                    && <Button
                                                        flexDir="column"
                                                        gap="4px"
                                                        onClick={feedbackClick}
                                                        onMouseEnter={handleFeedbackMouseEnter}
                                                        onMouseLeave={handleFeedbackMouseLeave}
                                                        variant="red"
                                                    >
                                                        {t('feedback')}
                                                        {
                                                            (pendingFeedbacksNb !== undefined && pendingFeedbacksNb !== 0)
                                                            && <Text fontSize={11}>{t('pending', { ns: COMMON_LOCALES })}: {pendingFeedbacksNb}</Text>
                                                        }
                                                    </Button>
                                                }

                                                <Tooltip label={noValidationInfo} shouldWrapChildren>
                                                    <Button
                                                        isDisabled={noValidation}
                                                        isLoading={validationLoading}
                                                        onClick={() => handleChangeStep()}
                                                    >
                                                        {nextButtonLabel}
                                                    </Button>
                                                </Tooltip>
                                            </>
                                    }

                                </HStack>
                            </HStack>
                        </HStack>
                        {lastFeedback && <HasFeedbackBanner detailLink={hasFeedbackLink} feedback={lastFeedback} />}
                    </>
            }

        </VStack>

    );
}

import { Button } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { resetActiveFilters } from '../services/store/slices/sessionSlice';
import preventCmdCtrlClick from '../utils/click-helpers';
import useCustomNavigate from '../utils/custom-navigate-hook';

interface CellValueProps {
    value?: number | string,
    path?: string,
    containerStyle?: React.CSSProperties
    onClick?(): void
}

/*
 * Table Cell component with click used for dashboard
 * @param props Value and navigation Path
 * @returns
 */
export default function CellValue(props: CellValueProps) {
    const { value, path, containerStyle, onClick } = props;
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();

    const handleOnClick = () => {
        // ----- OnClick overrides the default behaviour -----
        if (onClick) {
            onClick();

            return;
        }

        if (!path) {
            return;
        }

        // ---- Need to reset the store first ----
        const urlPath = path.replaceAll(/[?].*/g, '');
        dispatch(resetActiveFilters(urlPath));

        navigate(path);
    };

    return (
        <Button
            _hover={path || onClick ? { backgroundColor: 'gray.200', cursor: 'pointer' } : undefined}
            as="a"
            borderRadius={0}
            display="flex"
            h="100%"
            href={path}
            justifyContent="flex-end"
            onClick={(e) => preventCmdCtrlClick(e, handleOnClick)}
            p={2}
            style={containerStyle}
            variant='unstyled'
            w="100%"
        >
            {value || 0}
        </Button>
    );
}

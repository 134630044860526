import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES } from '../utils/constants';

interface ModelIdentityBannerProps {
    modelHeight: number,
    modelSizes: string[]
}

export default function ModelIdentityBanner(props: ModelIdentityBannerProps) {
    const { t } = useTranslation();
    const { modelHeight, modelSizes } = props;

    return (
        <HStack justifyContent={'center'} w="100%">
            {
                (modelHeight && modelSizes)
                && <Text color="black" fontSize={14} textAlign="center">{t('model', { ns: COMMON_LOCALES })}: {`${modelHeight} cm `}
                    / {t('garment', { ns: COMMON_LOCALES })}: {`${modelSizes.toString()}`}</Text>
            }
        </HStack>
    );
}

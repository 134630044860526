import { Box, Divider, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ValidationSubHeader from '../../components/headers/ValidationSubHeader';
import ImageWithError from '../../components/ImageWithError';
import FeedbackModal from '../../components/modals/FeedbackModal';
import ModelIdentityBanner from '../../components/ModelIdentityBanner';
import { useGetExternalQualityQuery, usePutExternalWarpQualityValidateMutation } from '../../services/api/api-external';
import { useGetExternalWarpFeedbacksQuery } from '../../services/api/api-feedback';
import { useGetGarmentDetailQuery } from '../../services/api/api-garment';
import { getClient } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ErrorMutation, FeedbackParams } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_LOCALES, ERROR_LOCALES, PREPROCESSING_LOCALES, WARP_LOCALES } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useFeedbacks from '../../utils/feedback-hook';
import resizeImage from '../../utils/image';
import OpacityGridElement from '../preprocessing/gridElements/OpacityGridElement';
import SingleImageGridElement from '../preprocessing/gridElements/SingleImageElement';
import WarpDetailGridElement from '../preprocessing/gridElements/WarpDetailGridElement';
import { CENTER_SPINNER } from '../preprocessing/GridItemImage';

const SCREEN_ID = 'externalValidation';

export default function ExternalValidation() {
    const { t } = useTranslation([WARP_LOCALES, ERROR_LOCALES, PREPROCESSING_LOCALES]);
    const toast = useToast();
    const { sendExternalFeedback } = useFeedbacks();
    const { warpId } = useParams();
    const navigate = useCustomNavigate();

    const currentClient = useAppSelector((state) => getClient(state));

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [localPostedFeedback, setLocalPostedFeedback] = useState<boolean>(false);

    const { data: externalQuality, isLoading: isExternalQualityLoading, error: errorExternalQuality } = useGetExternalQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    );

    const { data: garmentDetail, isFetching: isGarmentDetailLoading, error: errorGarmentDetail } = useGetGarmentDetailQuery(
        { clientId: currentClient?.id || '', id: externalQuality?.garment_id || '' },
        { skip: !currentClient || !externalQuality },
    );

    const { data: feedbacks } = useGetExternalWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', sort: true },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    );

    const [putExternalWarpQualityValidate] = usePutExternalWarpQualityValidateMutation();

    const noValidationAllowed = useMemo(() => {
        if (!externalQuality) {
            return true;
        }

        if (errorExternalQuality || errorGarmentDetail) {
            return true;
        }

        return localPostedFeedback;
    }, [localPostedFeedback, externalQuality, errorExternalQuality, errorGarmentDetail]);

    const handleFeedbackSuccess = () => {
        setModalOpen(false);
        setLocalPostedFeedback(true);
    };

    const sendFeedback = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        if (currentClient && warpId) {
            resolve();

            return sendExternalFeedback({ ...params, warpId }, handleFeedbackSuccess);
        }
        resolve();

        return null;
    });

    const handleValidate = () => {
        // ---- This is not handled so we do nothing ----
        if (!warpId) {
            return;
        }

        if (noValidationAllowed) {
            navigate('/external_validation');

            return;
        }

        // ---- Send the validate PUT request ----
        putExternalWarpQualityValidate({ clientId: currentClient?.id || '', id: warpId }).unwrap()
            .then((response) => {
                if (response.success === false) {
                    return toast({
                        isClosable: true,
                        status: 'error',
                        title: t('cant_validate_status', { ns: ERROR_LOCALES }),
                    });
                }

                // ---- Put success + success in the response ----
                if (response.success) {
                    toast({
                        isClosable: true,
                        status: 'success',
                        title: t('external_success'),
                    });

                    navigate('/external_validation');
                }

                return null;
            });
    };

    const isAnyLoading = useMemo(() => isExternalQualityLoading || isGarmentDetailLoading, [isGarmentDetailLoading, isExternalQualityLoading]);

    return (
        <Box height='100%'>
            {
                errorExternalQuality || errorGarmentDetail
                    ? <VStack h="100%" justifyContent="center">
                        {errorExternalQuality && <Text>{(errorExternalQuality as ErrorMutation)?.data?.message}</Text>}
                        {errorGarmentDetail && <Text>{(errorGarmentDetail as ErrorMutation)?.data?.message}</Text>}
                    </VStack>
                    : <>
                        {
                            externalQuality && !isAnyLoading
                                ? <VStack boxSize="full" spacing={0}>
                                    <FeedbackModal
                                        hidePriority={true}
                                        modalOpen={modalOpen}
                                        onClose={() => { setModalOpen(false); }}
                                        onSend={sendFeedback}
                                        screenId={SCREEN_ID}
                                    />
                                    <ValidationSubHeader
                                        detailData={externalQuality}
                                        feedback={feedbacks && feedbacks.items.length > 0 ? feedbacks.items[0] : undefined}
                                        feedbackButtonLabel={t('feedback', { ns: CROSSWARP_LOCALES })}
                                        onFeedbackClick={() => setModalOpen(true)}
                                        onValidate={handleValidate}
                                        validateButtonLabel={
                                            noValidationAllowed ? t('done', { ns: COMMON_LOCALES }) : t('validate', { ns: COMMON_LOCALES })
                                        }
                                    />
                                    <HStack boxSize="full" id={SCREEN_ID} overflow="hidden" pl={4} spacing={0}>
                                        <VStack h="100%" w="20%">
                                            {
                                                garmentDetail && <WarpDetailGridElement
                                                    garmentDetail={{
                                                        description: garmentDetail.product_description,
                                                        imageLabel: garmentDetail.garment_name,
                                                        productCategory: garmentDetail.garment_type,
                                                        productName: garmentDetail.product_name,
                                                    }}
                                                    imageSrc={garmentDetail.image_url}
                                                    label={t('input')}
                                                />
                                            }

                                        </VStack>

                                        <Divider orientation='vertical' />
                                        <VStack alignItems="center" flex={1} h="100%" justifyContent="center" pl={4} spacing={0}>
                                            <SingleImageGridElement
                                                imageSrc={externalQuality.image_url}
                                                label={t('w', { ns: COMMON_LOCALES })}
                                            />
                                            <ModelIdentityBanner
                                                modelHeight={externalQuality.model_identity.height}
                                                modelSizes={[
                                                    externalQuality.model_identity.garment_size, externalQuality.model_identity.garment_size2,
                                                ]}
                                            />
                                        </VStack>
                                        {
                                            externalQuality.debugs?.transparent && <>
                                                <Divider orientation='vertical' />
                                                <VStack alignItems="center" flex={1} h="100%" justifyContent="center" pl={4} spacing={0}>
                                                    <OpacityGridElement
                                                        imageSrc={externalQuality.debugs?.transparent.model_image_url}
                                                        label={t('w', { ns: COMMON_LOCALES })}
                                                        opacitySrc={externalQuality.debugs?.transparent.garment_image_url}
                                                    />
                                                    <ModelIdentityBanner
                                                        modelHeight={externalQuality.model_identity.height}
                                                        modelSizes={[
                                                            externalQuality.model_identity.garment_size, externalQuality.model_identity.garment_size2,
                                                        ]}
                                                    />
                                                </VStack>
                                            </>
                                        }
                                        {
                                            externalQuality.extras && <>
                                                <Divider orientation='vertical' />
                                                <VStack h="100%" minH="100%" pl={4} pr={4} spacing={2} w="10%">
                                                    <Text fontWeight="bold">{t('extra', { ns: COMMON_LOCALES })}</Text>
                                                    <VStack overflowY="auto">
                                                        {
                                                            externalQuality.extras.map((extra) => (
                                                                <ImageWithError key={extra} src={resizeImage(extra, { width: 800 })} />
                                                            ))
                                                        }
                                                    </VStack>
                                                </VStack>
                                            </>
                                        }
                                    </HStack>
                                </VStack>
                                : CENTER_SPINNER
                        }
                    </>
            }
        </Box>
    );
}

import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES } from '../../utils/constants';
import BaseSlider from './BaseSlider';

interface OpacitySliderProps {
    defaultValue?: number
    onChange(newVal: number): void
}

export default function OpacitySlider(props: OpacitySliderProps) {
    const { t } = useTranslation(COMMON_LOCALES);

    const { defaultValue, onChange } = props;

    return (
        <HStack spacing={2}>
            <Text>{t('opacity')}</Text>
            <BaseSlider
                defaultValue={defaultValue}
                max={1}
                min={0}
                onChange={onChange}
                step={0.01}
            />
        </HStack>
    );
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface CollapseProps {
    label: string;
    children: ReactNode;
}

const Collapse: FC<CollapseProps> = ({ children, label }) => (
    <Accordion allowToggle w="full">
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box color="gray.500" flex={1} textAlign="left">{label}</Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel>
                {children}
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
);

export default Collapse;

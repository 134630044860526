import { Button, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getIsCrosswarpView, setIsCrosswarpView } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Facets, FacetTerms } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_FEEDBACK_PRIORITY, CROSSWARP_LOCALES, CROSSWARP_STATUS_KEY, PREFILTERS } from '../../utils/constants';
import PreFilterButton from '../buttons/PreFilterButton';
import Restricted from '../permission/Restricted';
import usePermission from '../permission/usePermission';
// import { useProductsContext } from '../products/ProductsContext';

const CrossWarpSubHeader = (props: {facets?: Facets}) => {
    // const ProductContext = useProductsContext();
    const { t } = useTranslation([COMMON_LOCALES, CROSSWARP_LOCALES]);
    const dispatch = useDispatch();

    const { facets } = props;

    const isCrosswarpView = useAppSelector((state) => getIsCrosswarpView(state));

    const [currentFacets, setCurrentFacets] = useState<Facets>();

    const allowedCrosswarpWrite = usePermission('crosswarp.write');
    const allowedCrosswarpInternal = usePermission('crosswarp.internal');

    const prefilters = [
        { isDisabled: !allowedCrosswarpInternal, label: t('prefilters.to_check', { ns: COMMON_LOCALES }), values: [PREFILTERS.MISSINGOVERRIDE] },
        {
            isDisabled: !allowedCrosswarpWrite,
            label: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
            values: [PREFILTERS.TOQUALITY, { customKey: 'garment_allowed|noraw', value: 'true' }],
        },
        { isDisabled: !allowedCrosswarpInternal, label: t('prefilters.hd_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOHDQC] },
        { label: t('prefilters.has_feedback', { ns: COMMON_LOCALES }), values: [PREFILTERS.HASFEEDBACK] },
        { filterKey: CROSSWARP_FEEDBACK_PRIORITY, label: t('feedback.priority.short_1', { ns: COMMON_LOCALES }), values: [PREFILTERS.P1] },
        { filterKey: CROSSWARP_FEEDBACK_PRIORITY, label: t('feedback.priority.short_2', { ns: COMMON_LOCALES }), values: [PREFILTERS.P2] },
        { filterKey: CROSSWARP_FEEDBACK_PRIORITY, label: t('feedback.priority.short_7', { ns: COMMON_LOCALES }), values: [PREFILTERS.P7] },
        { filterKey: CROSSWARP_FEEDBACK_PRIORITY, label: t('feedback.priority.short_9', { ns: COMMON_LOCALES }), values: [PREFILTERS.P9] },
    ];

    useEffect(() => {
        if (!currentFacets && facets) {
            setCurrentFacets(facets);
        }
    }, [facets]);

    return (
        <HStack justifyContent="space-between" w="100%">
            <HStack>
                {
                    currentFacets && prefilters.map((prefilter, index) => (
                        prefilter && (
                            <PreFilterButton
                                currentFacet={currentFacets[prefilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms}
                                filterKey={prefilter.filterKey || CROSSWARP_STATUS_KEY}
                                key={index}
                                label={prefilter.label}
                                overrideDisabled={prefilter.isDisabled || !(currentFacets[prefilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms)}
                                values={prefilter.values}
                            />
                        )
                    ))
                }
            </HStack>
            <HStack spacing={4}>
                {/* <VStack alignItems={'flex-end'}>
                    <div>
                        {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                    </div>
                    {
                        ProductContext
            && <HStack spacing={8}>
                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                    {t('grid.select_all')}
                </Button>
                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                    {t('grid.reset')}
                </Button>
            </HStack>
                    }
                </VStack> */}
                <Restricted to="crosswarp.internal">
                    <Button
                        onClick={() => dispatch(setIsCrosswarpView(!isCrosswarpView))}
                        variant="outline"
                    >
                        {isCrosswarpView ? t('garment_view', { ns: CROSSWARP_LOCALES }) : t('crosswarp_view', { ns: CROSSWARP_LOCALES })}
                    </Button>
                </Restricted>
            </HStack>
        </HStack>

    );
};

export default CrossWarpSubHeader;

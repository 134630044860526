import { CloseIcon } from '@chakra-ui/icons';
import { HStack, IconButton, List, ListItem, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QcResponse } from '../../../types/api-types';
import { COMMON_LOCALES } from '../../../utils/constants';
import CarouselGridElement from './CarouselGridElement';

interface WarningGridElementProps {
    warnings: QcResponse[] | string[]
    onClose?(): void
}

export default function WarningGridElement(props: WarningGridElementProps) {
    const { t } = useTranslation([COMMON_LOCALES]);

    const { warnings, onClose } = props;

    const [warningCarouselIndex, setWarningCarouselIndex] = useState<number>(0);

    const isStringArray = useMemo(() => (warnings.length > 0 && typeof warnings[0] === 'string'), [warnings]);

    useEffect(() => {
        // ---- Reset carousel index ----
        if (warningCarouselIndex !== 0) {
            setWarningCarouselIndex(0);
        }
    }, [warnings]);

    return (
        <VStack h="100%" w="100%">
            {
                isStringArray
                    ? <VStack>
                        <HStack justifyContent="space-between" p={4} position="relative" w="100%">
                            <Text
                                fontWeight="bold"
                                textAlign="center"
                                w="100%"
                            >
                                {t('warning', { ns: COMMON_LOCALES })}
                            </Text>
                            <IconButton
                                aria-label='close-button'
                                icon={<CloseIcon />}
                                onClick={onClose}
                                position="absolute"
                                right={2}
                                top={2}
                                variant="ghost"
                            />
                        </HStack>
                        <List styleType="-">
                            {
                                (warnings as string[]).map((warning, i) => <ListItem key={i} ml={8}><Text p={2}>{warning}</Text></ListItem>)
                            }
                        </List>

                    </VStack>
                    : <>
                        <HStack justifyContent="space-between" p={4} position="relative" w="100%">
                            <Text
                                fontWeight="bold"
                                textAlign="center"
                                w="100%"
                            >
                                {(warnings as QcResponse[])[warningCarouselIndex].message || t('warning', { ns: COMMON_LOCALES })}
                            </Text>
                            <IconButton
                                aria-label='close-button'
                                icon={<CloseIcon />}
                                onClick={onClose}
                                position="absolute"
                                right={2}
                                top={2}
                                variant="ghost"
                            />
                        </HStack>

                        <VStack h="100%" w="100%">
                            <CarouselGridElement imagesData={(warnings as QcResponse[]).map((warning) => {
                                if (warning.type === 'image' || typeof warning.data === 'string') {
                                    return '';
                                }

                                return `data:image/png;base64, ${warning.data.data}`;
                            })}
                            label={''}
                            onIndexChange={setWarningCarouselIndex}
                            />
                        </VStack>
                    </>
            }

        </VStack>
    );
}

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';

interface DropdownButtonProps {
    children?: React.ReactNode,
    title: string,
    visible?: boolean,
    onClick?(): void,
}

export default function DropdownButton(props: DropdownButtonProps) {
    const { children, title, visible, onClick } = props;

    return (
        <Box>
            <Popover
                isOpen={visible}
                onClose={onClick}
            >
                <PopoverTrigger>
                    <Button onClick={onClick} p="0 8px" rightIcon={<ChevronDownIcon />} variant="ghost">{title}</Button>
                </PopoverTrigger>
                <PopoverContent flexDirection="row" flexWrap="wrap" gridGap={4} p="8px">
                    <PopoverArrow />
                    {children}
                </PopoverContent>
            </Popover>
        </Box>
    );
}

import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    invalidateFeedbackAction,
    usePostCheckGarmentFeedbackMutation,
    usePostCrosswarpFeedbackMutation,
    usePostExternalFeedbackMutation,
    usePostGarmentFeedbackMutation,
    usePostWarpFeedbackMutation,
    usePostWarpSmartFeedbackMutation } from '../services/api/api-feedback';
import { getClient } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { ApiFeedbackImage, Feedback, FeedbackParams } from '../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, FEEDBACK_STATUS } from './constants';

type SuccessCallbackFunction = (
    response: {
        errors?: {
            [key: string]: string[];
        } | undefined;
        success: boolean;
        'feedback_id'?: string | undefined;
    },
    message: string
) => void;

type HandleFeedbackSuccessFunction = (
    response: {
        errors?: {
            [key: string]: string[];
        } | undefined;
        success: boolean;
        'feedback_id'?: string | undefined;
    },
    message: string,
    resolve: (returnValue: void) => void,
    successCallback?: SuccessCallbackFunction,
) => void

export default function useFeedbacks() {
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentClient = useAppSelector((state) => getClient(state));

    const [postCheckGarmentFeedback, { isLoading: isCheckGarmentFeedbackLoading }] = usePostCheckGarmentFeedbackMutation();

    const [postWarpFeedback, { isLoading: isWarpFeedbackLoading }] = usePostWarpFeedbackMutation();

    const [postCrosswarpFeedback, { isLoading: isCrossWarpFeedbackLoading }] = usePostCrosswarpFeedbackMutation();

    const [postSmartFeedback, { isLoading: isSmartWarpFeedbackLoading }] = usePostWarpSmartFeedbackMutation();

    const [postExternalFeedback, { isLoading: isExternalFeedbackLoading }] = usePostExternalFeedbackMutation();

    const [postGarmentFeedback, { isLoading: isGarmentFeedbackLoading }] = usePostGarmentFeedbackMutation();

    const handleFeedbackSuccess: HandleFeedbackSuccessFunction = (
        response,
        message,
        resolve,
        successCallback,
    ) => {
        // ---- Post success + success in the response ----
        if (response.success) {
            toast({
                isClosable: true,
                status: 'success',
                title: t('feedback.toast_success', { ns: COMMON_LOCALES }),
            });

            if (successCallback) {
                successCallback(response, message);
            }

            dispatch(invalidateFeedbackAction);

            resolve();

            return;
        }

        if (response.errors && !Array.isArray(response.errors)) {
            resolve();
            // ---- Show a toast for each key in the error object (1 key = 1 error in a body content) ----
            Object.keys(response.errors).forEach((errorKey) => {
                toast({
                    description: response.errors && response.errors[errorKey].toString(),
                    isClosable: true,
                    status: 'error',
                    title: errorKey,
                });
            });
        } else {
            toast({
                isClosable: true,
                status: 'error',
                title: t('feedback_success_false', { ns: ERROR_LOCALES }),
            });
        }
    };

    const sendCheckGarmentFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { garmentId, message, image, images } = payload;
        if (currentClient && garmentId) {
            return postCheckGarmentFeedback(
                { clientId: currentClient.id, content: message, id: garmentId, image, images },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const sendWarpFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { warpId, stepString, message, image, images, priority } = payload;
        if (currentClient && warpId) {
            return postWarpFeedback(
                { clientId: currentClient.id, content: message, id: warpId, image, images, priority, step: stepString },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const sendCrossWarpFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { garmentId, modelId, message, images, priority, image } = payload;
        if (currentClient && garmentId && modelId) {
            return postCrosswarpFeedback(
                { clientId: currentClient.id, content: message, garmentId, image, images, modelId, priority },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const sendSmartWarpFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { warpId, message, image, images } = payload;
        if (currentClient && warpId) {
            return postSmartFeedback(
                { clientId: currentClient.id, content: message, id: warpId, image, images },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const sendExternalFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { warpId, message, image, images } = payload;
        if (currentClient && warpId) {
            return postExternalFeedback(
                { clientId: currentClient.id, content: message, id: warpId, image, images },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const sendGarmentFeedback = (
        payload: FeedbackParams,
        successCallback?: SuccessCallbackFunction,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        const { garmentId, message, image, images, priority, stepString } = payload;
        if (currentClient && garmentId) {
            return postGarmentFeedback(
                { clientId: currentClient.id, content: message, id: garmentId, image, images, priority, step: stepString },
            ).unwrap()
                .then((response) => {
                    handleFeedbackSuccess(response, message, resolve, successCallback);
                })
                .catch(() => {
                    resolve();
                });
        }

        return null;
    });

    const getFirstNewImageFeedback = (feedbackImages: (string | ApiFeedbackImage)[]) => {
        let result: ApiFeedbackImage | null = null;
        for (let i = 0; i < feedbackImages.length; i++) {
            if (typeof feedbackImages[i] === 'string') {
                continue;
            }

            result = (feedbackImages[i] as ApiFeedbackImage);
            break;
        }

        return result;
    };

    const hasNewFeedbackImage = (feedbackImages: (string | ApiFeedbackImage)[]) => !!getFirstNewImageFeedback(feedbackImages);

    const hasOneFeedbackSuperposeImage = (feedbackImages: (string | ApiFeedbackImage)[]) => {
        let result = false;
        for (let i = 0; i < feedbackImages.length; i++) {
            if (typeof feedbackImages[i] === 'string') {
                continue;
            }

            if ((feedbackImages[i] as ApiFeedbackImage).superpose) {
                result = true;
                break;
            }
        }

        return result;
    };

    const getVisibleIdsFromFeedbacks = (feedbacks: Feedback[]) => feedbacks.filter(
        (feedback) => feedback.status !== FEEDBACK_STATUS.CANCELED
            && feedback.images
            && hasOneFeedbackSuperposeImage(feedback.images),
    ).map((feedback) => feedback.id);

    // ---- Get all the superpose images in an array according to status, visiblefeedbackIds and hovered ID ----
    const getSuperposeImagesFromFeedbacks = (feedbacks: Feedback[], visibleFeedbackIds?: string[], feedbackIdHovered?: string) => {
        if (!feedbacks || !visibleFeedbackIds) {
            return undefined;
        }

        const result: string[] = [];
        if (feedbackIdHovered) {
            const foundFeedback = feedbacks.find((feedback) => feedback.id === feedbackIdHovered);

            if (!foundFeedback || !foundFeedback.images) {
                return undefined;
            }

            for (let i = 0; i < foundFeedback.images.length; i++) {
                if (typeof foundFeedback.images[i] === 'string') {
                    continue;
                }

                const apiFeedbackImage = (foundFeedback.images[i] as ApiFeedbackImage);
                if (apiFeedbackImage.annotation && apiFeedbackImage.superpose) {
                    result.push(apiFeedbackImage.annotation);
                }
            }

            return result;
        }

        feedbacks.forEach((feedback) => {
            if (visibleFeedbackIds.includes(feedback.id) && feedback.images) {
                const feedbackImages = feedback.images;
                for (let i = 0; i < feedbackImages.length; i++) {
                    if (typeof feedbackImages[i] === 'string') {
                        continue;
                    }

                    const apiFeedbackImage = (feedbackImages[i] as ApiFeedbackImage);
                    if (apiFeedbackImage.annotation && apiFeedbackImage.superpose) {
                        result.push(apiFeedbackImage.annotation);
                    }
                }
            }
        });

        return result;
    };

    return {
        getFirstNewImageFeedback,
        getSuperposeImagesFromFeedbacks,
        getVisibleIdsFromFeedbacks,
        hasNewFeedbackImage,
        hasOneFeedbackSuperposeImage,
        isCheckGarmentFeedbackLoading,
        isCrossWarpFeedbackLoading,
        isExternalFeedbackLoading,
        isGarmentFeedbackLoading,
        isSmartWarpFeedbackLoading,
        isWarpFeedbackLoading,
        sendCheckGarmentFeedback,
        sendCrossWarpFeedback,
        sendExternalFeedback,
        sendGarmentFeedback,
        sendSmartWarpFeedback,
        sendWarpFeedback,
    };
}

const resizeImage = (src: string, params: number | string | { width?: number, height?: number }): string => {
    // ---- Verification ----
    if (!src) {
        return src;
    }

    // ---- Exception en dur ----
    const match = src.match(/^https?:\/\/storage\.googleapis\.com\/(demo-development-frameml)\/(.+)$/);
    if (match) {
        return resizeImage(`http://dev.images.veesual.ai/image?bucket=${encodeURIComponent(match[1])}&img=${encodeURIComponent(match[2])}`, params);
    }

    // ---- Traitement de l'image ----
    let finalSrc = src;
    if (src.match(/^https?:\/\/(dev\.)?images\.veesual\.ai\/image\?/)) {
        if (typeof (params) === 'object') {
            if (params.width && !finalSrc.match('&w=(.*)(&|$)')) {
                finalSrc += `&w=${params.width}`;
            }
            if (params.height && !finalSrc.match('&h=(.*)(&|$)')) {
                finalSrc += `&h=${params.height}`;
            }
        } else if (
            (typeof params === 'number' || typeof params === 'string') && !finalSrc.match('&w=(.*)(&|$)')
        ) {
            finalSrc += `&w=${params}`;
        }
    }

    // ---- Create URL object to check if recursion is needed ----
    const srcUrl = new URL(src);
    const urlParamValue = srcUrl.searchParams.get('url');

    // ---- If 'url' searchParams exist we do the recursion ----
    if (urlParamValue) {
        // ---- Remove the url from src url ----
        srcUrl.searchParams.delete('url');

        // ---- Call recursive and append the new updated url ----
        srcUrl.searchParams.append('url', resizeImage(urlParamValue, params));

        return srcUrl.href;
    }

    return finalSrc;
};

export default resizeImage;

import { createContext, useContext } from 'react';

import { FilterInContextType } from './filtersTypes';

export interface FiltersContextType {
    deselectAll: () => void;
    deselectAFilter: (filter: FilterInContextType) => void;
    deselectMultipleFilters: (filters: FilterInContextType[]) => void;
    selectAFilter: (filter: FilterInContextType) => void;
    selectMultipleFilters: (filters: FilterInContextType[]) => void;
    updateFilters:(filters: FilterInContextType[]) => void;
    filters: FilterInContextType[];
}

const FiltersContext = createContext<FiltersContextType | null>(null);

export const useFiltersContext = () => useContext(FiltersContext);

export default FiltersContext;

import { Button, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PREPROCESSING_LOCALES } from '../utils/constants';
import ImageWithError from './ImageWithError';

interface SuccessLayoutProps {
    imgSrc?: string,
    text?: string,
    linkLabel?: string,
    onLinkClick?(): void,
}
export default function SuccessLayout(props: SuccessLayoutProps) {
    const { imgSrc, text, linkLabel, onLinkClick } = props;

    const { t } = useTranslation(PREPROCESSING_LOCALES);

    return (
        <HStack h="100%" justify="center" pb={4} pt={4}>
            <ImageWithError
                fallback={<Center data-image-url={imgSrc} h="100%">
                    <Spinner />
                </Center>}
                h="100%"
                objectFit="contain"
                src={imgSrc}
            />
            <VStack alignItems="flex-start" spacing={10} textAlign="left">
                <Text>{t('congratulations')}</Text>
                <Text whiteSpace="pre-line">{text}</Text>
                {(linkLabel && onLinkClick) && <Button onClick={onLinkClick} variant="link">{linkLabel}</Button>}
            </VStack>
        </HStack>
    );
}

import { Text } from '@chakra-ui/react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import GarmentsGrid from './garments/GarmentsGrid';

export default function Input() {
    const [searchParams] = useSearchParams();

    return (
        <>
            {
                searchParams.get('mode') === 'garment' && <>
                    <GarmentsGrid />
                </>
            }
            {
                searchParams.get('mode') === 'model' && <>
                    <Text>Nothing yet</Text>
                </>
            }
        </>
    );
}

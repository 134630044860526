import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

interface URLKonvaImageProps {
    src: string,
    width: number,
    height: number,
    x?: number,
    y?: number,
    opacity?: number
}

export default function URLKonvaImage(props: URLKonvaImageProps) {
    const { src, width, height, x, y, opacity } = props;

    // ---- Need anonymous param to extract the image from the stage ----
    const [imgObj] = useImage(src, 'anonymous');

    return (
        <Image
            height={height}
            image={imgObj}
            opacity={opacity}
            width={width}
            x={x || 0}
            y={y || 0}
        />
    );
}

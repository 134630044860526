import { HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Facets, FacetTerms } from '../../types/api-types';
import { COMMON_LOCALES, GARMENT_FEEDBACK_PRIORITY, GARMENT_STATUS_KEY, PREFILTERS, TODO_VALUES } from '../../utils/constants';
import PreFilterButton from '../buttons/PreFilterButton';

const PreprocessingSubHeader = (props: {facets?: Facets}) => {
    // const ProductContext = useProductsContext();
    const { t } = useTranslation([COMMON_LOCALES]);

    const { facets } = props;

    const [currentFacets, setCurrentFacets] = useState<Facets>();

    const prefilters = [
        { label: t('prefilters.to_do', { ns: COMMON_LOCALES }), values: TODO_VALUES },
        { label: t('prefilters.to_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOQUALITY] },
        { label: t('prefilters.has_feedback', { ns: COMMON_LOCALES }), values: [PREFILTERS.HASFEEDBACK] },
        { filterKey: GARMENT_FEEDBACK_PRIORITY, label: t('feedback.priority.short_1', { ns: COMMON_LOCALES }), values: [PREFILTERS.P1] },
        { filterKey: GARMENT_FEEDBACK_PRIORITY, label: t('feedback.priority.short_9', { ns: COMMON_LOCALES }), values: [PREFILTERS.P9] },
    ];

    useEffect(() => {
        if (!currentFacets && facets) {
            setCurrentFacets(facets);
        }
    }, [facets]);

    return (
        <HStack justifyContent="space-between" w="100%">
            <HStack>
                {
                    currentFacets && prefilters.map((prefilter, index) => (
                        <PreFilterButton
                            currentFacet={currentFacets[prefilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms}
                            filterKey={prefilter.filterKey || GARMENT_STATUS_KEY}
                            key={index}
                            label={prefilter.label}
                            overrideDisabled={!(currentFacets[prefilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms)}
                            values={prefilter.values}
                        />
                    ))
                }
            </HStack>

            {/* <VStack alignItems={'flex-end'}>
                <div>
                    {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                </div>
                {
                    ProductContext
            && <HStack spacing={8}>
                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                    {t('grid.select_all')}
                </Button>
                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                    {t('grid.reset')}
                </Button>
            </HStack>
                }
            </VStack> */}
        </HStack>

    );
};

export default PreprocessingSubHeader;

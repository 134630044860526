import { Box, Button, Center, Checkbox, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import { CrosswarpCheckType } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_LOCALES } from '../../utils/constants';
import resizeImage from '../../utils/image';
import isSelectableFromStatus from '../../utils/status-helpers';
import { getCleanSearch, getModelDetailUrl } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import { isCrossWarpsEqual, isExistingCrossWarp, useCrossWarpsContext } from '../crosswarps/CrossWarpsContext';
import ImageWithError from '../ImageWithError';
import ModelIdentityBanner from '../ModelIdentityBanner';
import Restricted from '../permission/Restricted';

interface CrosswarpCheckCardProps {
    crosswarp: CrosswarpCheckType;
    imageRatio: string
    onFeedbackClick(): void
    isReserved?: boolean
    opacity?: number
}

export default function CrosswarpCheckCard(props: CrosswarpCheckCardProps) {
    const { t } = useTranslation(CROSSWARP_LOCALES);

    const { search } = useLocation();

    const { crosswarp, imageRatio, onFeedbackClick, isReserved, opacity } = props;

    const [isReservedLocal, setIsReservedLocal] = useState<boolean>(!!isReserved);

    // ---- This is used to change the font size according to the card width ----
    const ref = useRef<HTMLDivElement>(null);
    const [cardWidth, setCardWidth] = useState<number>(0);

    const CrossWarpsContext = useCrossWarpsContext();

    const isNotSelectionnable = useMemo(() => !isSelectableFromStatus(crosswarp.warp_status || ''), [crosswarp]);

    const fontSize = useMemo(() => {
        if (cardWidth < 230) { return 10; }

        return 'sm';
    }, [cardWidth]);

    const isChecked = useCallback(
        () => {
            let selectProduct = null;

            if (isNotSelectionnable || !CrossWarpsContext) {
                return false;
            }

            if (CrossWarpsContext.selectedCrossWarps) {
                selectProduct = CrossWarpsContext.selectedCrossWarps.find(
                    (localCrossWarp) => isCrossWarpsEqual(
                        localCrossWarp,
                        isExistingCrossWarp(crosswarp)
                            ? { warp_id: crosswarp.warp_id }
                            : { garment_id: crosswarp.garment_id, model_id: crosswarp.model_id },
                    ),
                );

                return !!selectProduct;
            }

            return !!selectProduct;
        },
        [CrossWarpsContext, isNotSelectionnable],
    );

    const handleCheck = (e: React.ChangeEvent | React.MouseEvent) => {
        e.preventDefault();

        if (isNotSelectionnable) {
            return null;
        }

        const crosswarpObject = crosswarp.warp_id
            ? { warp_id: crosswarp.warp_id }
            : { garment_id: crosswarp.garment_id, model_id: crosswarp.model_id };

        if (CrossWarpsContext?.selectACrossWarp) {
            if (isChecked()) {
                return CrossWarpsContext?.deselectACrossWarp(crosswarpObject);
            }

            return CrossWarpsContext?.selectACrossWarp(crosswarpObject);
        }

        return null;
    };

    const mHref = useMemo(() => getModelDetailUrl(crosswarp.model_id), [crosswarp]);

    const cwqcHref = useMemo(() => {
        if (crosswarp.warp_id) {
            return `/crosswarp/${crosswarp.warp_id}/quality?${getCleanSearch(search)}`;
        }

        return undefined;
    }, [crosswarp]);

    useEffect(() => {
        function handleResize() {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setCardWidth(rect.width);
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [ref.current?.getBoundingClientRect()]);

    useEffect(() => {
        if (isReservedLocal || !isReserved) {
            return;
        }

        setIsReservedLocal(isReserved);
    }, [isReserved]);

    return (
        <VStack
            backgroundColor="gray.100"
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            ref={ref}
            w="100%"
        >
            <Box
                borderRadius="lg"
                h={0}
                onClick={handleCheck}
                overflow="hidden"
                paddingBottom={imageRatio}
                position="relative"
                w="100%"
            >
                <LazyLoad height="100%" offset={100} once overflow>
                    {
                        crosswarp.transparent
                            ? <>
                                <ImageWithError
                                    cursor="pointer"
                                    fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                                    height="100%"
                                    objectFit={'contain'}
                                    position="absolute"
                                    src={resizeImage(crosswarp.transparent.model_image_url, { width: 800 })}
                                    width="100%"
                                />
                                <ImageWithError
                                    cursor="pointer"
                                    fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                                    height="100%"
                                    objectFit={'contain'}
                                    opacity={opacity !== undefined ? opacity : 1}
                                    position="absolute"
                                    src={resizeImage(crosswarp.transparent.garment_image_url, { width: 800 })}
                                    width="100%"
                                />
                            </>
                            : <ImageWithError
                                cursor="pointer"
                                fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                                height="100%"
                                objectFit={'contain'}
                                position="absolute"
                                src={resizeImage(crosswarp.image_url, { width: 800 })}
                                width="100%"
                            />
                    }

                </LazyLoad>
                <Checkbox
                    display={'block'}
                    isChecked={isChecked()}
                    isDisabled={isNotSelectionnable}
                    left={4}
                    onChange={handleCheck}
                    position="absolute"
                    size="lg"
                    top={4}
                />
            </Box>
            <VStack justifyContent="space-between" spacing={2} w="100%">
                <ModelIdentityBanner
                    modelHeight={crosswarp.model_identity.height}
                    modelSizes={[crosswarp.model_identity.garment_size, crosswarp.model_identity.garment_size2]}
                />
                {
                    isReserved
                        ? <Text
                            color="red"
                            fontSize={fontSize}
                            fontWeight="bold"
                            noOfLines={[1, 2]}
                            textAlign='center'
                            whiteSpace="nowrap"
                        >
                            {t('reserved', { ns: COMMON_LOCALES })}
                        </Text>
                        : <Text noOfLines={[1, 2]} textAlign="center">
                            {`${t('warp_status')} ${crosswarp.warp_status}`}
                        </Text>
                }

                <HStack flex={1} justifyContent="space-evenly" minW={90} spacing={4} w="100%">
                    <Restricted to="feedback">
                        <Button flex={1} fontSize={fontSize} onClick={onFeedbackClick} pb={1} pt={1} variant="red">
                            {t('feedback')}
                        </Button>
                    </Restricted>
                    <HStack>
                        <Restricted to="old_routes">
                            <RoundButton
                                href={mHref}
                                onClick={() => window.open(mHref, '_blank')}
                                text="M"
                            />
                        </Restricted>
                        <RoundButton
                            href={cwqcHref}
                            isDisabled={!crosswarp.warp_id}
                            secondLine="QC"
                            text="CW"
                            tooltipLabel={!crosswarp.warp_id ? t('qc_disabled') : undefined}
                        />
                    </HStack>
                </HStack>
            </VStack>
        </VStack>
    );
}

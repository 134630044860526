import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES, QCSTEPS } from './constants';

export function getGarmentQcStepName(stepValue: string) {
    const { t } = useTranslation(COMMON_LOCALES);
    switch (stepValue) {
        case QCSTEPS.DETAIL:
            return t('qc_steps.detail');
        case QCSTEPS.CLIPPING:
            return t('qc_steps.clipping');
        case QCSTEPS.FOREGROUND:
            return t('qc_steps.foreground');
        case QCSTEPS.CUT:
            return t('qc_steps.cut');
        case QCSTEPS.MASK:
            return t('qc_steps.mask');
        case QCSTEPS.MESH:
            return t('qc_steps.mesh');
        default:
            return '';
    }
}

export function getGarmentRefWarpQcStepName(stepValue: string) {
    const { t } = useTranslation(COMMON_LOCALES);
    switch (stepValue) {
        case QCSTEPS.FIT_CONSISTENCY:
            return t('qc_steps.fit_consistency');
        case QCSTEPS.SMART:
            return t('qc_steps.smart');
        case QCSTEPS.COVER_AND_TEXTURE:
            return t('qc_steps.cover_and_texture');
        case QCSTEPS.MORE_MODELS:
            return t('qc_steps.more_models');
        case QCSTEPS.OUTFIT_PREVIEW:
            return t('qc_steps.outfit_preview');
        case QCSTEPS.EXTERNAL_CHECK:
            return t('qc_steps.external');
        case QCSTEPS.HD_CHECK:
            return t('qc_steps.hd_check');
        default:
            return '';
    }
}

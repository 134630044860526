import { Slider, SliderFilledTrack, SliderMark, SliderProps, SliderThumb, SliderTrack } from '@chakra-ui/react';
import React from 'react';

export default function TransparencySlider(props: SliderProps) {
    return (
        <Slider
            {...props}
            m={0}
            minW={'80px'}
            variant="gray"
        >
            <SliderMark value={-100}>
                -100%
            </SliderMark>
            <SliderMark value={0}>
                0%
            </SliderMark>
            <SliderMark value={100}>
                +100%
            </SliderMark>
            <SliderTrack h={0.5}>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
        </Slider>

    );
}

import { Center, HStack, Spinner, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useGetReservationMutation } from '../../services/api/api-warp';
import { addReservation, getActiveFilters, resetReservation, setActiveFilters } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Facets, Filters, GarmentResponse, Pagination, Reservation } from '../../types/api-types';
import { COMMON_LOCALES, DEFAULT_IMAGESIZE_KEY } from '../../utils/constants';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import { addFiltersToUrlParams, getFiltersFromUrlParams, removeFiltersFromUrlParams } from '../../utils/url-helpers';
import FiltersContext from '../filters/FiltersContext';
import FiltersPanel from '../filters/FiltersPanel';
import { FilterInContextType } from '../filters/filtersTypes';
import GridSubHeader from '../headers/GridSubHeader';

interface GridLayoutProps {
    title: string,
    data?: Pagination,
    facetData?: Pagination,
    FlexWarpComponent: React.ElementType,
    imagesRatio: string,
    dataQuery(filters?: Filters, query?: string, page?: number, collapse?: number | null): void
    isLoading: boolean,
    type: string,
    SubHeaderContent?: React.ElementType<{facets?: Facets}>,
    noReservationQuery?: boolean
}

let timeoutSearchId: ReturnType<typeof setTimeout> | null = null;

export default function GridLayout(props: GridLayoutProps) {
    const { t } = useTranslation(COMMON_LOCALES);
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const [, setSearchParams] = useSearchParams();

    const { title, data, FlexWarpComponent, imagesRatio, dataQuery, isLoading, type, SubHeaderContent, facetData, noReservationQuery } = props;

    // const ProductContext = useProductsContext();

    const storeActiveFilters = useAppSelector((state) => getActiveFilters(state, pathname));

    const [selectedFilters, setSelectedFilters] = useState<FilterInContextType[]>(storeActiveFilters || []);
    const [queryString, setQueryString] = useState<string>('');

    // Init value needs to be the same as the slider in the GridSubbHeader
    const [numberProductPerRow, setNumerProductPerRow] = useState<number>(loadFromLocalStorage(DEFAULT_IMAGESIZE_KEY) || 4);

    // Use this instead of queryResponse so there is no blink
    const [fullProducts, setFullProducts] = useState<unknown[] | null>(data ? data.items : null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalProduct, setCurrentTotalProduct] = useState<number>(0);

    // Method to add a filter in the array
    const addFilterToArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => [...filters, newFilter];

    // Those methods are used for the Filter Context to handle the selection outside the filter components
    const getSelectedFilters = (filters: FilterInContextType[]) => {
        const filterObject: Filters = {};
        filters.forEach((filter) => {
            if (filterObject[filter.filterKey]) {
                if (Array.isArray(filterObject[filter.filterKey])) {
                    filterObject[filter.filterKey] = [...filterObject[filter.filterKey] as string[], filter.filterValue];
                } else {
                    filterObject[filter.filterKey] = [filterObject[filter.filterKey] as string, filter.filterValue];
                }
            } else {
                filterObject[filter.filterKey] = filter.filterValue;
            }
        });

        return filterObject;
    };

    const removeFilterFromArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => {
        const newFilters = filters.filter(
            (filter) => filter.filterKey !== newFilter.filterKey || filter.filterValue !== newFilter.filterValue,
        );

        return newFilters;
    };

    const resetTotalValues = () => {
        setCurrentTotalProduct(0);
    };

    // ---- Handle new filters in URL ----
    const updateFilterUrlParams = (newFilters: FilterInContextType[]) => {
        if (newFilters) {
            setSearchParams(addFiltersToUrlParams(search, newFilters), { replace: true });
        } else {
            setSearchParams(removeFiltersFromUrlParams(search), { replace: true });
        }
    };

    const fetchNewFilters = (updatedFilters: FilterInContextType[], query = '', page = 1) => {
        updateFilterUrlParams(updatedFilters);
        setSelectedFilters(updatedFilters);

        dispatch(setActiveFilters({ key: pathname, newActiveFilters: updatedFilters.length === 0 ? null : updatedFilters }));
        const filters = getSelectedFilters(updatedFilters);
        setCurrentPage(page);
        dataQuery(filters, query, page);
    };

    const selectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1) => {
        const newFilters = addFilterToArray([...selectedFilters], newFilter);
        resetTotalValues();
        fetchNewFilters(newFilters, query, page);
    };

    const selectMultipleFilters = (newFilters: FilterInContextType[], query = queryString, page = 1) => {
        let localSelectedFilters = [...selectedFilters];
        newFilters.forEach((newFilter) => {
            localSelectedFilters = addFilterToArray(localSelectedFilters, newFilter);
        });

        resetTotalValues();
        fetchNewFilters(localSelectedFilters, query, page);
    };

    const deselectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1) => {
        const newFilters = removeFilterFromArray([...selectedFilters], newFilter);
        resetTotalValues();
        fetchNewFilters(newFilters, query, page);
    };

    const deselectMultipleFilters = (newFilters: FilterInContextType[], query = queryString, page = 1) => {
        let localSelectedFilters = [...selectedFilters];
        newFilters.forEach((newFilter) => {
            localSelectedFilters = removeFilterFromArray(localSelectedFilters, newFilter);
        });

        resetTotalValues();
        fetchNewFilters(localSelectedFilters, query, page);
    };

    // ---- Replace all filters by the newFilters ----
    const updateFilters = (newFilters: FilterInContextType[]) => {
        resetTotalValues();
        fetchNewFilters(newFilters, queryString);
    };

    const deselectAll = () => {
        resetTotalValues();
        fetchNewFilters([], queryString, undefined);
    };

    // Called when we scroll the product grid and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80 || !fullProducts) {
            return;
        }

        handlePaginationScroll(
            isLoading,
            currentPage,
            () => fetchNewFilters(selectedFilters, queryString, currentPage + 1),
            data,
            fullProducts.length,
        );
    };

    const handleSearch = (input: string) => {
        if (timeoutSearchId) {
            clearTimeout(timeoutSearchId);
        }
        timeoutSearchId = setTimeout(() => {
            setQueryString(input);
            fetchNewFilters(selectedFilters, input, undefined);
        }, 300);
    };

    useEffect(() => {
        if (data) {
            const apiCurrentPage = parseInt(data.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setFullProducts((prev) => prev.concat(data.items));
            } else {
                setCurrentPage(1);
                setCurrentTotalProduct(data.total_dedup_count || data.total_count);
                setFullProducts(data.items);
            }
        }
    }, [data]);

    // ---- Handle URL Filters and Store Filters ----
    useEffect(() => {
        const filtersFromUrl = getFiltersFromUrlParams(search);

        if (filtersFromUrl && !storeActiveFilters) {
            fetchNewFilters(filtersFromUrl);
        }

        if ((!filtersFromUrl || filtersFromUrl.length === 0) && storeActiveFilters) {
            setSearchParams(addFiltersToUrlParams(search, storeActiveFilters), { replace: true });
        }
    }, [storeActiveFilters]);

    // ---- Reserved query -----
    const [getReserved] = useGetReservationMutation();
    useEffect(() => {
        if (noReservationQuery) {
            return undefined;
        }

        const reservedCall = (reservedResp: {data: Reservation[]}) => {
            const castedResp = reservedResp;
            if (castedResp && castedResp.data) {
                dispatch(addReservation(castedResp.data));
            }
        };
        const interval = window.setInterval(() => {
            getReserved().then((reservedResp) => {
                reservedCall(reservedResp as {data: Reservation[]});
            });
        }, 10000);

        getReserved().then((reservedResp) => {
            reservedCall(reservedResp as {data: Reservation[]});
        });

        return () => {
            dispatch(resetReservation());
            clearInterval(interval);
        };
    }, [noReservationQuery]);

    return (
        <VStack boxSize="full">
            <GridSubHeader onSliderChange={setNumerProductPerRow} searchResultsTotal={currentTotalProduct} title={title}>
                <HStack justifyContent={'space-between'} w="100%">
                    {
                        SubHeaderContent
                            ? <FiltersContext.Provider
                                value={{
                                    deselectAFilter,
                                    deselectAll,
                                    deselectMultipleFilters,
                                    filters: selectedFilters,
                                    selectAFilter,
                                    selectMultipleFilters,
                                    updateFilters,
                                }}
                            >
                                <SubHeaderContent facets={facetData?.facets}/>
                            </FiltersContext.Provider>
                            : <>
                                <div>
                                    {isLoading && <Spinner />}
                                </div>
                                {/* <VStack alignItems={'flex-end'}>
                                    <div>
                                        {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                                    </div>
                                    {
                                        ProductContext
                                            && <HStack spacing={8}>
                                                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                                                    {t('grid.select_all')}
                                                </Button>
                                                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                                                    {t('grid.reset')}
                                                </Button>
                                            </HStack>
                                    }
                                </VStack> */}
                            </>
                    }

                </HStack>

            </GridSubHeader>
            <HStack boxSize="full" overflow="hidden" pl={10} spacing={10}>
                {
                    fullProducts && data
                    && (
                        <>
                            <FiltersContext.Provider
                                value={{
                                    deselectAFilter,
                                    deselectAll,
                                    deselectMultipleFilters,
                                    filters: selectedFilters,
                                    selectAFilter,
                                    selectMultipleFilters,
                                    updateFilters,
                                }}
                            >
                                <FiltersPanel
                                    currentTotalProduct={currentTotalProduct}
                                    filters={(data as GarmentResponse).facets}
                                    isLoading={false}
                                    onSearch={handleSearch}
                                    type={type}
                                />
                            </FiltersContext.Provider>
                            <VStack boxSize="full">
                                {
                                    fullProducts.length < 1
                                        ? <Center boxSize="full">{t('grid.no_data')}</Center>
                                        : <FlexWarpComponent
                                            data={fullProducts}
                                            imagesRatio={imagesRatio}
                                            numberProductPerRow={numberProductPerRow}
                                            onScroll={handleScroll}
                                        />
                                }
                            </VStack>
                        </>

                    )
                }
            </HStack>

        </VStack>
    );
}

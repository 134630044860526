import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoWithTooltip from '../../../components/InfoWithTooltip';
import { COMMON_LOCALES, PREPROCESSING_LOCALES } from '../../../utils/constants';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import GridItemImage from '../GridItemImage';

interface SideButtonsGridElementProps {
    label?: string
    info?: string
    imageSrc: string | string[]
    superpose?: string | string[]
    overlay?: string[]
    offsetWidth?: string
    imageSelected: number | null
    onButtonClick(newImageSelected: number | null): void
    sideButtons: string[]
}

export default function SideButtonsGridElement(props: SideButtonsGridElementProps) {
    const { t } = useTranslation([COMMON_LOCALES, PREPROCESSING_LOCALES]);

    const { label, info, imageSrc, superpose, overlay, offsetWidth, imageSelected, onButtonClick, sideButtons } = props;

    useFeedbackContextHelper({ imageSrc, label, overlay, superpose });

    return (
        <VStack w="100%">
            {
                label && <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                    <HStack justifyContent="center">
                        <Text fontWeight="bold">{label}</Text>
                        {info && <InfoWithTooltip tooltipLabel={info} />}
                    </HStack>
                </HStack>
            }

            <HStack
                height="100%"
                justifyContent="center"
                spacing={0}
                w="100%"
            >
                {
                    Array.isArray(imageSrc) && imageSrc.length === 0
                        ? <Text>No contrast image</Text>
                        : <>
                            <GridItemImage
                                imageSelected={imageSelected}
                                overlay={overlay}
                                src={imageSrc}
                                superpose={superpose}
                            />
                            <VStack p={2} w={offsetWidth}>
                                {
                                    sideButtons.map((buttonLabel, index) => (
                                        <Button
                                            isActive={imageSelected === index}
                                            key={imageSrc.toString() + index}
                                            onClick={() => onButtonClick(imageSelected === index ? null : index)}
                                            variant="tab-like"
                                            w="100%"
                                        >
                                            {t(buttonLabel)}
                                        </Button>
                                    ))
                                }
                            </VStack>
                        </>
                }

            </HStack>
        </VStack>
    );
}

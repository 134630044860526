import { HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { forceCheck } from 'react-lazyload';

import ImageSlider from '../sliders/ImageSlider';
import BaseSubHeader from './BaseSubHeader';

interface GridSubHeaderProps {
    children?: React.ReactNode,
    onSliderChange?(newValue: number): void,
    title?: string
    total?: number
    searchResultsTotal?: number
}

export default function GridSubHeader(props: GridSubHeaderProps) {
    const { children, onSliderChange } = props;

    const updateProductsPerRow = (value: number) => {
        if (onSliderChange) {
            onSliderChange(value);
            forceCheck();
        }
    };

    return (
        <BaseSubHeader>
            <>
                <HStack flex={5} pl={10}>
                    <VStack flex={3}>
                        {children}
                    </VStack>
                    <VStack flex={0.8}>
                        <ImageSlider flexDirection="column" onChange={updateProductsPerRow}/>
                    </VStack>
                </HStack>
            </>
        </BaseSubHeader>
    );
}

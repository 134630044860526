import { createContext, useContext } from 'react';

export interface ProductsContextType {
    deselectAProduct: (id: string) => void;
    selectAProduct: (id: string) => void;
    selectedProducts: {id: string}[];
    checkAll: boolean;
    changeCheckAll: (newValue: boolean) => void;
}

const ProductsContext = createContext<ProductsContextType | null>(null);

export const useProductsContext = () => useContext(ProductsContext);

export default ProductsContext;

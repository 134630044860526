import { Input, InputGroup, InputRightAddon, Slider, SliderFilledTrack, SliderThumb, SliderTrack, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

interface ZoomSliderProps {
    transformComponentRef?: ReactZoomPanPinchRef
    zoomValue: number
    updateZoomValues(scaleValue: number): void
    min: number
    max: number
}

export default function ZoomSlider(props: ZoomSliderProps) {
    const { transformComponentRef, zoomValue, updateZoomValues, min, max } = props;

    const [inputError, setInputError] = useState<boolean>(false);
    const [inputZoomValue, setInputZoomValue] = useState<number>(min);

    // ---- Verify the new value (or the value in the inputZoomValue) and apply it if valide ----
    const validateValue = (newValue?: number) => {
        const valueToHandle = newValue || inputZoomValue / 100;

        // ---- If it's not a number or not between 1 and 8 (100% / 800%) ----
        if (Number.isNaN(valueToHandle) || valueToHandle < (min / 100) || valueToHandle > (max / 100)) {
            setInputError(true);

            return;
        }

        // ---- Remove Error ----
        setInputError(false);

        // ---- Apply the value as zoom in the transform ref ----
        if (transformComponentRef) {
            transformComponentRef.centerView(valueToHandle);
        }

        // ---- Update the zoom Value ----
        updateZoomValues(valueToHandle);
    };

    const handleSliderOnChange = (value: number) => {
        validateValue(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseFloat(event.target.value);

        setInputZoomValue(parsedValue);
    };

    const handleOnBlur = () => {
        validateValue(inputZoomValue / 100);
    };

    const handleInputKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            validateValue(inputZoomValue / 100);
        }
    };

    useEffect(() => {
        setInputZoomValue(zoomValue * 100);
    }, [zoomValue]);

    return (
        <VStack spacing={2}>
            <Slider
                defaultValue={zoomValue}
                max={max / 100}
                min={min / 100}
                minH={100}
                onChange={handleSliderOnChange}
                orientation="vertical"
                step={0.01}
                value={zoomValue}
                variant="gray"
                w="100%"
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
            <InputGroup backgroundColor="white" size="xs" w="65px">
                <Input
                    isInvalid={inputError}
                    max={max}
                    min={min}
                    onBlur={handleOnBlur}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyPress}
                    type="number"
                    value={inputZoomValue.toFixed()}
                />
                <InputRightAddon children={'%'} />
            </InputGroup>
        </VStack>
    );
}

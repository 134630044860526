import { Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface WarpEditConfigPanelProps {
    children?: React.ReactNode,
    title: string
}

export default function WarpEditConfigPanel(props: WarpEditConfigPanelProps) {
    return (
        <VStack bgColor="gray.100" borderRadius={8} flex={1}>
            <Text fontWeight="bold">{props.title}</Text>
            {props.children}
        </VStack>
    );
}

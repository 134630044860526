import { Button, HStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Facets, FacetTerms } from '../../types/api-types';
import { COMMON_LOCALES, PREFILTERS, WARP_FEEDBACK_PRIORITY, WARP_LOCALES, WARP_STATUS_KEY } from '../../utils/constants';
import { getCleanSearch } from '../../utils/url-helpers';
import PreFilterButton from '../buttons/PreFilterButton';
import { useFiltersContext } from '../filters/FiltersContext';
import Restricted from '../permission/Restricted';
import usePermission from '../permission/usePermission';

const RefWarpSubHeader = (props: {facets?: Facets}) => {
    // const ProductContext = useProductsContext();
    const { t } = useTranslation([COMMON_LOCALES, WARP_LOCALES]);
    const { search } = useLocation();
    const FilterContext = useFiltersContext();

    const { facets } = props;

    const [currentFacets, setCurrentFacets] = useState<Facets>();

    const allowedRefwarpWrite = usePermission('ref_warp.write');
    const allowedRefwarpInternal = usePermission('ref_warp.internal');
    const allowedRefwarpAdmin = usePermission('ref_warp.admin');

    const prefilters = [
        { label: t('prefilters.to_do', { ns: COMMON_LOCALES }), values: [PREFILTERS.TODO] },
        {
            isDisabled: !allowedRefwarpWrite,
            label: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
            values: [PREFILTERS.TOQUALITY, { customKey: 'garment_allowed|noraw', value: 'true' }],
        },
        { isDisabled: !allowedRefwarpInternal, label: t('prefilters.int_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOINTQC] },
        { isDisabled: !allowedRefwarpAdmin, label: t('prefilters.ext_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOEXTQC] },
        { label: t('prefilters.has_feedback', { ns: COMMON_LOCALES }), values: [PREFILTERS.HASFEEDBACK] },
        { filterKey: WARP_FEEDBACK_PRIORITY, label: t('feedback.priority.short_1', { ns: COMMON_LOCALES }), values: [PREFILTERS.P1] },
        {
            filterKey: WARP_FEEDBACK_PRIORITY,
            isDisabled: !allowedRefwarpAdmin,
            label: t('feedback.priority.short_7', { ns: COMMON_LOCALES }),
            values: [PREFILTERS.P7],
        },
        {
            filterKey: WARP_FEEDBACK_PRIORITY,
            isDisabled: !allowedRefwarpAdmin,
            label: t('feedback.priority.short_8', { ns: COMMON_LOCALES }),
            values: [PREFILTERS.P8],
        },
        {
            filterKey: WARP_FEEDBACK_PRIORITY,
            isDisabled: !allowedRefwarpAdmin,
            label: t('feedback.priority.short_9', { ns: COMMON_LOCALES }),
            values: [PREFILTERS.P9],
        },
    ];

    // ---- Check in Context if Prefilter is Activated ----
    const isExtRef = useMemo(() => {
        let result = true;
        if (
            !FilterContext
            || FilterContext.filters.findIndex(
                (contextFilter) => contextFilter.filterKey === WARP_STATUS_KEY && contextFilter.filterValue === PREFILTERS.TOEXTQC,
            ) === -1
        ) {
            result = false;
        }

        return result;
    }, [FilterContext?.filters]);

    useEffect(() => {
        if (!currentFacets && facets) {
            setCurrentFacets(facets);
        }
    }, [facets]);

    return (
        <HStack justifyContent="space-between" spacing={4} w="100%">
            <HStack>
                {
                    currentFacets && prefilters.map((prefilter, index) => (
                        prefilter && (
                            <PreFilterButton
                                currentFacet={currentFacets[prefilter.filterKey || WARP_STATUS_KEY] as FacetTerms}
                                filterKey={prefilter.filterKey || WARP_STATUS_KEY}
                                key={index}
                                label={prefilter.label}
                                overrideDisabled={prefilter.isDisabled || !(currentFacets[prefilter.filterKey || WARP_STATUS_KEY] as FacetTerms)}
                                values={prefilter.values}
                            />
                        )
                    ))
                }
            </HStack>

            {/* <VStack alignItems={'flex-end'}>
                <div>
                    {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                </div>
                {
                    ProductContext
            && <HStack spacing={8}>
                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                    {t('grid.select_all')}
                </Button>
                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                    {t('grid.reset')}
                </Button>
            </HStack>
                }
            </VStack> */}
            {
                isExtRef
                && <Restricted to="external_validation">
                    <Button
                        as="a"
                        href={`/external_validation?${getCleanSearch(search)}`}
                        variant="outline"
                    >
                        {t('client_qc_view', { ns: WARP_LOCALES })}
                    </Button>
                </Restricted>

            }

        </HStack>

    );
};

export default RefWarpSubHeader;

import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CheckFinalSubHeader from '../../components/headers/CheckFinalSubHeader';
import GridLayout from '../../components/layouts/GridLayout';
import { useGetChecksQuery } from '../../services/api/api-check';
import { getClient, getConfig, getConvertedActiveFilters, getExperience } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Filters, QueryInput } from '../../types/api-types';
import { ERROR_LOCALES, GARMENTS_LOCALES } from '../../utils/constants';
import CheckGridElement from './CheckGridElement';

export default function CheckFinal() {
    const { t } = useTranslation([GARMENTS_LOCALES, ERROR_LOCALES]);
    const { pathname } = useLocation();

    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));
    const storeConvertedActiveFilters = useAppSelector((state) => getConvertedActiveFilters(state, pathname));
    const selectedExperience = useAppSelector((state) => getExperience(state));

    const [queryInputObject, setQueryInputObject] = React.useState<QueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience?.id,
        filters: storeConvertedActiveFilters || undefined,
    });

    const { data: checkGarments, isLoading: isCheckGarmentsLoading } = useGetChecksQuery(
        queryInputObject,
        { refetchOnMountOrArgChange: true, skip: !currentClient },
    );
    // ---- We duplicate the query with default params to use the global facet data ----
    const { data: facetDataCheckGarments } = useGetChecksQuery({
        clientId: currentClient ? currentClient.id : '',
        per_page: 1,
    },
    { refetchOnMountOrArgChange: true, skip: !currentClient });

    function useGarmentQuery(filters: Filters, query = '', page = 1, collapse = null) {
        if (currentClient) {
            setQueryInputObject({
                clientId: currentClient.id,
                collapse,
                experienceId: selectedExperience?.id,
                filters: Object.keys(filters).length === 0 ? {} : { ...filters },
                page,
                query,
            });
        }
    }

    return (
        <Box h="100%">
            {
                !currentClient
                    ? <Center height="100%">{t('select_client', { ns: ERROR_LOCALES })}</Center>
                    : <GridLayout
                        FlexWarpComponent={CheckGridElement}
                        SubHeaderContent={CheckFinalSubHeader}
                        data={checkGarments}
                        dataQuery={useGarmentQuery}
                        facetData={facetDataCheckGarments}
                        imagesRatio={
                            config
                                ? `${(100 / config.garment_image_ratio).toString()}%`
                                : '120%'
                        }
                        isLoading={isCheckGarmentsLoading}
                        noReservationQuery={true}
                        title={t('title', { clientName: currentClient?.name })}
                        type="garment"
                    />
            }
        </Box>
    );
}

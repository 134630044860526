import { HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Facets, FacetTerms } from '../../types/api-types';
import { CHECKFINAL_STATUS_KEY, COMMON_LOCALES, PREFILTERS, WARP_LOCALES } from '../../utils/constants';
import PreFilterButton from '../buttons/PreFilterButton';
import usePermission from '../permission/usePermission';
import BaseSubHeader from './BaseSubHeader';

const CheckFinalSubHeader = (props: { facets?: Facets }) => {
    const { t } = useTranslation([COMMON_LOCALES, WARP_LOCALES]);

    const { facets } = props;

    const [currentFacets, setCurrentFacets] = useState<Facets>();

    const allowedCheckInternal = usePermission('check.internal');

    const prefilters = [
        allowedCheckInternal ? { label: t('prefilters.int_pending', { ns: COMMON_LOCALES }), values: [PREFILTERS.INTERNAL_PENDING] } : null,
        allowedCheckInternal ? { label: t('prefilters.int_feedback', { ns: COMMON_LOCALES }), values: [PREFILTERS.INTERNAL_FEEDBACK] } : null,
        { label: t('prefilters.pending', { ns: COMMON_LOCALES }), values: [PREFILTERS.PENDING] },
        { label: t('prefilters.validated', { ns: COMMON_LOCALES }), values: [PREFILTERS.VALIDATED] },
        { label: t('prefilters.feedback', { ns: COMMON_LOCALES }), values: [PREFILTERS.FEEDBACK] },
    ];

    useEffect(() => {
        if (!currentFacets && facets) {
            setCurrentFacets(facets);
        }
    }, [facets]);

    return (
        <BaseSubHeader>
            <HStack>
                {
                    currentFacets && prefilters.map((prefilter, index) => (
                        prefilter && (
                            <PreFilterButton
                                currentFacet={currentFacets[CHECKFINAL_STATUS_KEY] as FacetTerms}
                                filterKey={CHECKFINAL_STATUS_KEY}
                                key={index}
                                label={prefilter.label}
                                overrideDisabled={!(currentFacets[CHECKFINAL_STATUS_KEY] as FacetTerms)}
                                values={prefilter.values}
                            />
                        )
                    ))
                }
            </HStack>
        </BaseSubHeader>

    );
};

export default CheckFinalSubHeader;

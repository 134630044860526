import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { getPathBlock, setBlockNavModal } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { removeFiltersFromUrlParams } from './url-helpers';

export default function useCustomNavigate() {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const blockPath = useAppSelector((state) => getPathBlock(state, pathname));

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (blockPath?.block) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    });

    function navigateWithParams(targetUrl?: string, options?: NavigateOptions, params?: {[key: string]: string | null}, forceNavigation?: boolean) {
        if (blockPath?.block && !forceNavigation) {
            return dispatch(setBlockNavModal({ newPath: targetUrl, prevPath: pathname, visible: true }));
        }

        // ---- If we have search params in targetUrl, bypass everything ----
        if (targetUrl) {
            const explodedUrl = targetUrl.split('?');
            if (explodedUrl.length > 1) {
                return navigate({
                    pathname: explodedUrl[0],
                    search: explodedUrl[1],
                }, options);
            }
        }

        // ---- Remove filters from URL params whenever we navigate ----
        const searchParamsNoFilter = createSearchParams(removeFiltersFromUrlParams(search));
        if (params) {
            const finalParams = JSON.parse(JSON.stringify(params));
            searchParamsNoFilter.forEach((value, key) => {
                if (params[key] === null) {
                    delete finalParams[key];
                } else {
                    finalParams[key] = params[key] || value;
                }
            });

            return navigate({
                pathname: targetUrl || pathname,
                search: `${createSearchParams(finalParams)}`,
            }, options);
        }

        // ---- Clean Params from Preprocessing if we navigate somewhere else ----
        const finalParams: {[key: string]: string} = {};
        searchParamsNoFilter.forEach((value, key) => {
            if (key !== 'step') {
                finalParams[key] = value;
            }
        });

        return navigate({
            pathname: targetUrl || pathname,
            search: `${createSearchParams(finalParams)}`,
        }, options);
    }

    return navigateWithParams;
}

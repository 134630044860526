import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { getIsCrosswarpView } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import CrosswarpGarmentGrid from './CrosswarpGarmentGrid';
import CrosswarpGrid from './CrosswarpGrid';

export default function Crosswarp() {
    const [searchParams] = useSearchParams();

    const isCrossView = useAppSelector((state) => getIsCrosswarpView(state));

    return (
        <Box height='100%'>
            {
                searchParams.get('mode') === 'garment'
                && <>
                    {
                        isCrossView
                            ? <CrosswarpGrid />
                            : <CrosswarpGarmentGrid />
                    }
                </>
            }

            {
                searchParams.get('mode') === 'model' && <>
                    <Text>Nothing yet</Text>
                </>
            }

        </Box>
    );
}

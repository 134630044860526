import { Pagination } from '../types/api-types';

// ---- Called when the grid of sample is scrolled ----
const handlePaginationScroll = (
    isLoading: boolean,
    currentPage: number,
    callback: () => void,
    sampleData?: Pagination | null,
    fullDataLength?: number,
) => {
    if (!sampleData || isLoading) {
        return;
    }

    const apiCurrentPage = parseInt(sampleData.current_page_number, 10);
    const apiNumitemsPerPage = parseInt(sampleData.num_items_per_page, 10);

    // Prevent delay between fullData and sampleData items
    if (fullDataLength && fullDataLength <= (currentPage - 1) * apiNumitemsPerPage) {
        return;
    }

    const totalValue = sampleData.total_dedup_count || sampleData.total_count;

    // Prevent loading 1 page that doesn't exist
    if ((apiCurrentPage * apiNumitemsPerPage) >= totalValue) {
        return;
    }

    callback();
};

export default handlePaginationScroll;

import { Button } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { FacetTerms } from '../../types/api-types';
import { useFiltersContext } from '../filters/FiltersContext';
import { FilterInContextType } from '../filters/filtersTypes';

interface CustomFilterValue {
    customKey: string,
    value: string
}

interface PreFilterButtonProps {
    label: string,
    filterKey: string,
    values: (string | CustomFilterValue)[],
    currentFacet?: FacetTerms,
    overrideDisabled?: boolean,
}

// ---- Button used to prefilter directly in the Grid View. NEEDS THE FILTERCONTEXT ----
export default function PreFilterButton(props: PreFilterButtonProps) {
    const FilterContext = useFiltersContext();

    const { label, filterKey, values, currentFacet, overrideDisabled } = props;

    // ---- Total of prefilters calculation ----
    const totalCount = useMemo(() => {
        if (!currentFacet) {
            return 0;
        }
        let result = 0;
        currentFacet.data.forEach((facetData) => {
            if (values.includes(facetData.value)) {
                result += facetData.count;
            }
        });

        return result;
    }, [currentFacet]);

    // ---- Check in Context if Prefilter is Activated ----
    const isActivated = useMemo(() => {
        let result = true;
        for (let i = 0; i < values.length; i++) {
            // ---- Local var init ----
            let localFilterKey = filterKey;
            let localValue = values[i];

            // ---- Check if we have a custom filter type ----
            if (typeof values[i] !== 'string') {
                // ---- Cast the value to correct type ----
                const customFilterValue = values[i] as CustomFilterValue;

                // ---- Assign custom values to local vars ----
                localFilterKey = customFilterValue.customKey;
                localValue = customFilterValue.value;
            }

            if (
                !FilterContext
                    || FilterContext.filters.findIndex(
                        (contextFilter) => contextFilter.filterKey === localFilterKey
                            && contextFilter.filterValue === localValue,
                    ) === -1
            ) {
                result = false;
                break;
            }
        }

        return result;
    }, [FilterContext?.filters]);

    // ---- Click handling ----
    const handleFilterClick = () => {
        if (!FilterContext) {
            return null;
        }

        // ---- We create the new filter object we need to handle ----
        const newFilters: FilterInContextType[] = [];
        values.forEach((newValue) => {
            if (typeof newValue === 'string') {
                return newFilters.push({ filterKey, filterValue: newValue });
            }

            return newFilters.push({ filterKey: newValue.customKey, filterValue: newValue.value });
        });

        // ---- We select all necessary Filters for the prefilter ----
        FilterContext.updateFilters(newFilters);

        return null;
    };

    return (
        <Button
            isActive={isActivated}
            isDisabled={totalCount === 0 || overrideDisabled}
            onClick={handleFilterClick}
            variant="tab-like"
        >
            {label} ({totalCount})
        </Button>
    );
}

import React from 'react';

const preventCmdCtrlClick = (e: React.MouseEvent<HTMLElement>, callBack:() => void) => {
    if (e.ctrlKey || e.metaKey) {
        return;
    }

    e.preventDefault();
    callBack();
};

export default preventCmdCtrlClick;

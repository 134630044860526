import { Box, Button, HStack, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useParams, useSearchParams } from 'react-router-dom';

import FeedbackImagesContext from '../../components/FeedbackImagesContext';
import FeedbackOverlay from '../../components/FeedbackOverlay';
import BaseBanner from '../../components/headers/BaseBanner';
import QualityCheckSubHeader from '../../components/headers/QualityCheckSubHeader';
import FeedbackModal from '../../components/modals/FeedbackModal';
import ValidateModal from '../../components/modals/ValidateModal';
import usePermission from '../../components/permission/usePermission';
import StepSlider from '../../components/StepSlider';
import SuccessLayout from '../../components/SuccessLayout';
import SuperposeAnimationContext from '../../components/SuperposeAnimationContext';
import TransparencyEdition from '../../components/TransparencyEdition';
import {
    invalidateFeedbackAction,
    useGetWarpFeedbacksQuery,
} from '../../services/api/api-feedback';
import { invalidateGetGarmentsAction } from '../../services/api/api-garment';
import {
    invalidategetCrossWarpsAction,
    invalidateWarpQualityAction,
    useGetHDQualityQuery,
    useGetWarpQualityQuery,
    useGetWarpSampleModelsQuery,
    useGetWarpSampleOutfitsQuery,
    usePutHDQualityValidateMutation,
    usePutWarpQualityValidateMutation,
} from '../../services/api/api-warp';
import { getClient, setPathBlockState } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Feedback, FeedbackImage, FeedbackParams, PendingFeedback, Pose, Sample, SampleResponse, WarpQuality } from '../../types/api-types';
import {
    COMMON_LOCALES,
    CROSSWARP_LOCALES,
    ERROR_LOCALES,
    FEEDBACK_STATUS,
    MAIN_HEADER_HEIGHT,
    PREFILTERS,
    PREPROCESSING_LOCALES,
    QCSTEPS,
    SUB_HEADER_HEIGHT,
    WARP_EDIT_LOCALES,
    WARP_LOCALES,
} from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useFeedbacks from '../../utils/feedback-hook';
import resizeImage from '../../utils/image';
import useImagePreloader, { getImagePreloadListFromWarpQuality } from '../../utils/image-preloader-hook';
import useRoles from '../../utils/roles-hook';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import { getGarmentRefWarpQcStepName } from '../../utils/steps-helpers';
import FullScreenGrid, { GridObject } from '../preprocessing/FullScreenGrid';
import CarouselGridElement from '../preprocessing/gridElements/CarouselGridElement';

// Full height minus both header and margin
const FINAL_IMAGE_HEIGHT = `calc(100vh - ${SUB_HEADER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px - 2rem)`;

interface RefWarpQCProps {
    initialSteps: string[]
}

export default function RefWarpQC(props: RefWarpQCProps) {
    // ---- Utils Hooks ----
    const { t } = useTranslation([WARP_LOCALES, PREPROCESSING_LOCALES, COMMON_LOCALES, WARP_EDIT_LOCALES, ERROR_LOCALES]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname, search } = useLocation();
    const toast = useToast();
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();
    const { sendWarpFeedback, isWarpFeedbackLoading } = useFeedbacks();
    const { isAdmin } = useRoles();

    // ---- Props ----
    const { initialSteps } = props;
    const { warpId } = useParams();

    // ---- States ----
    const [stepsArray, setStepsArray] = useState<string[]>(initialSteps);
    const [step, setStep] = useState<number>(0);
    const [stepString, setStepString] = useState<string>(stepsArray[0]);
    const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);
    const [pendingFeedbacks, setPendingFeedbacks] = useState<PendingFeedback[]>([]);
    const [gridScrollPage, setGridScrollPage] = useState<number>(1);
    const [fullSamples, setFullSamples] = useState<Sample[]>([]);
    const [animateSuperpose, setAnimateSuperpose] = useState<boolean>(true);
    const [carouselIndex, setCarouselIndex] = useState<number>(0);
    const [feedbackImages, setFeedbackImages] = useState<FeedbackImage[]>([]);
    const [gridFeedbackImages, setGridFeedbackImages] = useState<FeedbackImage[]>([]);
    const [fullFeedBacks, setFullFeedBacks] = useState<Feedback[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    // ---- Vars ----
    const stepName = getGarmentRefWarpQcStepName(stepString);

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- APIs ----
    const { data: warpDetail, error: errorWarpDetail, isFetching: isWarpDetailLoading } = useGetWarpQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    );

    const { data: feedBacks, isLoading: isFeedbackLoading } = useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: 1, sort: true },
        { skip: !currentClient || !warpId || !warpDetail },
    );

    // ---- Need unsorted feedbacks to show in feedback modal and feedback step ----
    const { data: unsortedFeedBacks } = useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: currentPage },
        { skip: !currentClient || !warpId || !warpDetail },
    );

    const { data: hdDetail, error: errorHdDetail, isLoading: isHDDetailLoading } = useGetHDQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        {
            skip:
                !currentClient
                || !warpDetail
                || (
                    !([PREFILTERS.TOHDQC, PREFILTERS.READY] as string[]).includes(warpDetail.warp_status)
                    && searchParams.get('step') !== QCSTEPS.HD_CHECK
                )
                || warpDetail.hd_quality === false,
        },
    );

    const { data: sampleModels, error: errorWarpSampleModels, isLoading: isSampleModelsLoading } = useGetWarpSampleModelsQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: gridScrollPage },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || (gridScrollPage !== 1 && stepString === QCSTEPS.MORE_MODELS) },
    );

    const { data: sampleOutfits, error: errorWarpSampleOutfits, isLoading: isSampleOutfitsLoading } = useGetWarpSampleOutfitsQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: gridScrollPage },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || (gridScrollPage !== 1 && stepString === QCSTEPS.OUTFIT_PREVIEW) },
    );

    const [putWarpQualityValidate, { isLoading: isValidationLoading, data: validateWarpQualityData }] = usePutWarpQualityValidateMutation();

    const [putHDQualityValidate, { isLoading: isHDValidationLoading }] = usePutHDQualityValidateMutation();

    // ---- Dynamic value according to warpDetail status we use HD validation or normal validaiton ----
    const validationFunction = useMemo(() => {
        switch (warpDetail?.warp_status) {
            case PREFILTERS.TOHDQC:
                return putHDQualityValidate;
            default:
                return putWarpQualityValidate;
        }
    }, [warpDetail]);

    // ---- Image list for preload in useMemo to prevent preloading for nothing ----
    const imageList = useMemo(() => {
        if (isWarpDetailLoading || isSampleModelsLoading || isSampleOutfitsLoading || isHDDetailLoading) {
            return [];
        }

        return getImagePreloadListFromWarpQuality(warpDetail, sampleModels?.items, sampleOutfits?.items, hdDetail);
    }, [warpDetail, sampleModels, sampleOutfits, hdDetail]);

    // ---- Preloads all images ----
    const { imagesPreloadedList } = useImagePreloader(imageList);

    const handleFeedbackSuccess = () => {
        setFeedbackModalOpen(false);

        /**
         * We invalidate the garments and crosswarp so we have the updated version in the list (if elesticsearch is fast enough)
         */
        dispatch(invalidateGetGarmentsAction);
        dispatch(invalidategetCrossWarpsAction);
        dispatch(invalidateWarpQualityAction);
        dispatch(invalidateFeedbackAction);
    };

    const handleFeedbackModalOnSend = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        // ---- We store the new Feedback object ----
        setPendingFeedbacks([...pendingFeedbacks, { ...params, step: stepString }]);

        // ---- Success Toast + Modal close ----
        toast({
            description: t('feedback.toast_pending_description', { ns: COMMON_LOCALES }),
            isClosable: true,
            status: 'info',
            title: t('feedback.toast_pending_title', { ns: COMMON_LOCALES }),
        });
        setFeedbackModalOpen(false);
        resolve();
    });

    const sendFeedback = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        if (currentClient && warpId) {
            resolve();

            return sendWarpFeedback({ ...params, warpId }, handleFeedbackSuccess);
        }

        return null;
    });

    // ---- Called when the grid of sample is scrolled ----
    const handleScrollChangePage = () => {
        let sampleData = null;
        let isLoading = false;

        // ---- We handle the correct data according to the step (either Models or Outfits for the moment) ----
        switch (stepString) {
            case QCSTEPS.MORE_MODELS:
                sampleData = sampleModels;
                isLoading = isSampleModelsLoading;
                break;
            case QCSTEPS.OUTFIT_PREVIEW:
                sampleData = sampleOutfits;
                isLoading = isSampleOutfitsLoading;
                break;
            default:
                sampleData = null;
        }

        handlePaginationScroll(isLoading, gridScrollPage, () => setGridScrollPage(gridScrollPage + 1), sampleData, fullSamples.length);
    };

    const handleOnScrollFeedbacks = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80) {
            return;
        }
        handlePaginationScroll(isWarpFeedbackLoading, currentPage, () => setCurrentPage(currentPage + 1), unsortedFeedBacks, fullFeedBacks.length);
    };

    // ---- Add element from the warp quality in the carouselArray which use either string or Pose object ----
    const addCarouselElement = (carouselArray: (string | Pose)[], newElement?: string | string[] | Pose | Pose[] | { 'image_url': string }) => {
        if (!newElement) {
            return null;
        }

        // ---- Basic string handling ----
        if (typeof newElement === 'string') {
            return carouselArray.push(newElement);
        }

        // ---- If it's an array we call the function recursively ----
        if (Array.isArray(newElement)) {
            return newElement.forEach((elem) => {
                addCarouselElement(carouselArray, elem);
            });
        }

        // ---- If it's the object with only image_url we push the string ----
        if (newElement.image_url && Object.keys(newElement).length === 1) {
            return carouselArray.push(newElement.image_url);
        }

        // ---- Default case is a Pose ----
        return carouselArray.push(newElement as Pose);
    };

    // ---- Build the carousel arrray used for the CarouselGridElement ----
    const buildCarouselArray = (localWarpDetail: WarpQuality) => {
        const carouselArray: (string | Pose)[] = [];
        const debugKeys = [] as const;
        const baseKeys = ['poses', 'variants', 'reference', 'extras'] as const;

        // ---- Debug keys have the priority ----
        debugKeys.forEach((key) => {
            addCarouselElement(carouselArray, localWarpDetail.debugs[key]);
        });

        // ---- Then we include the base keys that are on the root of the object ----
        baseKeys.forEach((key) => {
            addCarouselElement(carouselArray, localWarpDetail[key]);
        });

        return carouselArray;
    };

    // ---- Data used in fit consistency step ----
    const carouselData = useMemo(() => {
        if (!warpDetail) {
            return [];
        }

        return buildCarouselArray(warpDetail);
    }, [warpDetail]);

    // ---- Title based on the current index in the carousel ----
    const comparisonTitle = useMemo(() => {
        const baseTitle = t('comparison_images.title', { ns: COMMON_LOCALES });

        if (typeof carouselData[carouselIndex] === 'string' || !carouselData[carouselIndex]) {
            return `${baseTitle}: ${t('comparison_images.EXTRA', { ns: COMMON_LOCALES })}`;
        }

        const dataAsPose = carouselData[carouselIndex] as Pose;

        if (dataAsPose.image_type === 'POSE') {
            return `${baseTitle}: ${t(`comparison_images.${dataAsPose.garment_pose}`, { ns: COMMON_LOCALES })}`;
        }

        return `${baseTitle}: ${t(`comparison_images.${dataAsPose.image_type}`, { ns: COMMON_LOCALES })}`;
    }, [carouselIndex, carouselData]);

    const stepHrefs = useMemo(() => stepsArray.map(
        ((localStep) => `/refwarp/${warpId}/quality${search.replace(/&step=\w*/, `&step=${localStep}`)}`),
    ), [search, stepsArray]);

    const GRID_ITEM_HEIGHT = useMemo(
        () => {
            let offset = 0;
            // ---- Array of condition that show a banner that we need to offset in height ----
            [
                warpDetail?.primary_warning,
            ].forEach((value) => {
                if (value) {
                    offset += 24;
                }
            });

            return `calc(100vh - ${SUB_HEADER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px ${offset !== 0 ? `- ${offset}px` : ''})`;
        },
        [warpDetail],
    );

    const gridObjects = useMemo(
        () => {
            if (!warpDetail || !sampleModels || !sampleOutfits) {
                return [];
            }

            // ---- Smartwarp variable Init ----
            const smartElementsArray: GridObject[] = [];
            const labelsArray: [string, string] = [
                t('comparison.smart', { ns: WARP_EDIT_LOCALES }),
                t('comparison.no_smart', { ns: WARP_EDIT_LOCALES }),
            ];

            // ---- Cover and texture var ----
            const coverAndTextureArray: GridObject[] = [];

            // ---- Additional text variable Init ----
            const warpDetailAdditionalText = `${t('model', { ns: COMMON_LOCALES })}: ${warpDetail.model_identity.height}
            cm / ${t('garment', { ns: COMMON_LOCALES })}: ${warpDetail.model_identity.garment_size}, 
            ${warpDetail.model_identity.garment_size2}`;
            let debugCrossAdditionalText = '';

            switch (stepString) {
                case QCSTEPS.FIT_CONSISTENCY:
                    return [
                        { additionalText: warpDetailAdditionalText, imageSrc: warpDetail.image_url, label: t('garments.warp') },
                        {
                            customElement: <CarouselGridElement
                                imagesData={carouselData}
                                info={t('tooltip.fit_consistency')}
                                label={comparisonTitle}
                                onIndexChange={setCarouselIndex}
                                superpose={warpDetail.image_url}
                            />,
                        },
                    ];
                case QCSTEPS.SMART:
                    if (!warpDetail.debugs.smart) {
                        return [];
                    }

                    // ---- Creating the smart gridObject array ----
                    smartElementsArray.push({
                        imageSrc: warpDetail.disable_smart
                            ? warpDetail.debugs.smart?.nosmart_image_url
                            : warpDetail.debugs.smart?.smart_image_url,
                        info: t('tooltip.smartwarp.comparison'),
                        label: t('garments.final_version',
                            {
                                version: warpDetail.disable_smart
                                    ? t('comparison.no_smart', { ns: WARP_EDIT_LOCALES })
                                    : t('comparison.smart', { ns: WARP_EDIT_LOCALES }),
                            }),

                        superpose: warpDetail.disable_smart
                            ? warpDetail.debugs.smart?.smart_image_url
                            : warpDetail.debugs.smart?.nosmart_image_url,

                        // ---- Animate only if images are preloaded and there is a superpose ----
                        superposeAnimation: warpDetail.debugs.smart
                            && animateSuperpose
                            && imagesPreloadedList.includes(warpDetail.debugs.smart.smart_image_url)
                            && imagesPreloadedList.includes(warpDetail.debugs.smart.nosmart_image_url),
                        // ---- Labels to show on the bottom right of the grid element ----
                        superposeLabels: warpDetail.disable_smart ? labelsArray.reverse() as [string, string] : labelsArray,
                    },
                    {
                        // ---- We use the no smart version if the warp is SMART and the root image_url is it's NO SMART ----
                        imageSrc: [warpDetail.debugs.smart.nosmart_image_url],
                        info: t('tooltip.smartwarp.keypoints'),
                        keypoints: warpDetail.warp_keypoints,
                        label: t('garments.before_smart'),
                    });

                    // ---- We push the parts grid element only if it exists (doesn't exists for Sleeveless etc) ----
                    if (warpDetail.debugs.parts) {
                        smartElementsArray.push({
                            imageSrc: warpDetail.debugs.parts.torso_image_url,
                            info: t('tooltip.smartwarp.parts'),
                            label: t('garments.garment_parts'),
                            superpose: warpDetail.debugs.parts.parts_image_url,

                            // ---- Animate only if images are preloaded and there is a superpose ----
                            superposeAnimation: animateSuperpose
                                && imagesPreloadedList.includes(warpDetail.debugs.parts.parts_image_url)
                                && imagesPreloadedList.includes(warpDetail.debugs.parts.torso_image_url),
                            // ---- Labels to show on the bottom right of the grid element ----
                            superposeLabels: [t('torso', { ns: COMMON_LOCALES }).toUpperCase(), t('parts', { ns: COMMON_LOCALES }).toUpperCase()],
                        });
                    }

                    return smartElementsArray;
                case QCSTEPS.COVER_AND_TEXTURE:
                    coverAndTextureArray.push(
                        {
                            imageSrc: warpDetail.debugs.transparent.model_image_url,
                            info: t('tooltip.cover_and_texture.opacity'),
                            label: t('garments.warp'),
                            opacity: warpDetail.debugs.transparent.garment_image_url,
                        },
                        {
                            imageSrc: warpDetail.debugs.grid.image_url,
                            info: t('tooltip.cover_and_texture.grid_view'),
                            label: t('garments.grid_view'),
                        },
                    );

                    if (warpDetail.debugs.garmentcut) {
                        coverAndTextureArray.push({
                            imageSrc: warpDetail.debugs.garmentcut.nogarmentcut_image_url,
                            info: t('tooltip.cover_and_texture.garment_trim'),
                            label: t('garments.garment_trim'),
                            superpose: warpDetail.image_url,
                            // ---- Animate only if images are preloaded and there is a superpose ----
                            superposeAnimation: animateSuperpose
                                && imagesPreloadedList.includes(warpDetail.debugs.garmentcut.nogarmentcut_image_url)
                                && imagesPreloadedList.includes(warpDetail.image_url),
                            // ---- Labels to show on the bottom right of the grid element ----
                            superposeLabels: [t('no_trim', { ns: COMMON_LOCALES }).toUpperCase(), t('trim', { ns: COMMON_LOCALES }).toUpperCase()],
                        });
                    }

                    return coverAndTextureArray;
                case QCSTEPS.MORE_MODELS:
                    return [
                        { additionalText: warpDetailAdditionalText, imageSrc: warpDetail.image_url, label: t('garments.warp') },
                        {
                            fraction: '2fr',
                            gridChangePage: handleScrollChangePage,
                            gridSample: fullSamples,
                            info: t('tooltip.more_models'),
                            label: t('garments.sample_crosswarps'),
                        },
                    ];
                case QCSTEPS.OUTFIT_PREVIEW:
                    return [
                        {
                            additionalText: warpDetailAdditionalText,
                            imageSrc: warpDetail.image_url,
                            label: t('garments.warp'),
                        },
                        {
                            fraction: '2fr',
                            gridChangePage: handleScrollChangePage,
                            gridSample: fullSamples,
                            info: t('tooltip.outfit_preview'),
                            label: t('garments.sample_outfits'),
                        },
                    ];
                case QCSTEPS.EXTERNAL_CHECK:
                    return [
                        {
                            imageSrc: warpDetail.image_url,
                            label: warpDetail.warp_status !== 'TO_EXTERNAL_QUALITY'
                                ? `${t('ext_validated')}${warpDetail.external_at
                                    ? ` - ${warpDetail.external_by} : ${new Date(warpDetail.external_at).toDateString()}`
                                    : ''
                                }`
                                : t('ext_not_validated'),
                        },
                    ];
                case QCSTEPS.HD_CHECK:
                    if (!hdDetail) {
                        return [];
                    }

                    return [
                        {
                            imageSrc: hdDetail.image_hd_url,
                            label: t('garments.hd_warp'),
                        },
                        {
                            imageSrc: hdDetail.debugs.transparent.model_image_url,
                            label: t('garments.warp'),
                            opacity: hdDetail.debugs.transparent.garment_image_url,
                        },
                        {
                            imageSrc: hdDetail.debugs.contrast.image_url,
                            info: t('tooltip.contrast_view'),
                            label: t('garments.contrast_view'),
                        },
                    ];
                case QCSTEPS.SIZING:
                    if (!warpDetail.debugs.cross) {
                        return [];
                    }

                    debugCrossAdditionalText = `${t('height', { ns: COMMON_LOCALES })}: ${(warpDetail.debugs.cross as Pose).model_identity.height}
                    cm / ${t('sizes', { ns: COMMON_LOCALES })}: ${(warpDetail.debugs.cross as Pose).model_identity.garment_size}, 
                    ${(warpDetail.debugs.cross as Pose).model_identity.garment_size2}`;

                    return [
                        {
                            additionnalInfo: warpDetailAdditionalText,
                            imageSrc: warpDetail.image_url,
                            label: t('garments.warp'),
                        },
                        {
                            additionalText: debugCrossAdditionalText,
                            imageSrc: warpDetail.debugs.cross.image_url,
                            info: t('tooltip.sizing'),
                            label: t('cw', { ns: COMMON_LOCALES }),
                            superpose: warpDetail.image_url,
                            superposeAnimation: animateSuperpose
                                && imagesPreloadedList.includes(warpDetail.image_url)
                                && imagesPreloadedList.includes(warpDetail.debugs.cross.image_url),
                            superposeLabels: [t('automatic', { ns: COMMON_LOCALES }), t('garments.warp')] as [string, string],
                        },
                    ];
                case QCSTEPS.FEEDBACKS:
                    if (!fullFeedBacks || !warpDetail) {
                        return [];
                    }

                    return [{
                        customElement: <FeedbackOverlay
                            carouselData={carouselData}
                            feedbacks={fullFeedBacks}
                            imgSrc={warpDetail.image_url}
                            onScroll={handleOnScrollFeedbacks}
                        />,
                        label: '',
                    }];
                case QCSTEPS.TRANSPARENCY:
                    return [{
                        customElement: <TransparencyEdition carouselData={carouselData} warpDetail={warpDetail}/>,
                    }];
                default:
                    return [];
            }
        },
        [
            stepString,
            warpDetail,
            sampleModels,
            sampleOutfits,
            fullSamples,
            hdDetail,
            animateSuperpose,
            imagesPreloadedList,
            comparisonTitle,
            stepsArray,
            fullFeedBacks,
        ],
    );

    // ---- we allow to go directly to a step or not according to warpDetail ----
    const allowToStep = useMemo(() => {
        switch (warpDetail?.warp_status) {
            case PREFILTERS.TOEXTQC:
                return stepsArray.findIndex((localStep) => localStep === QCSTEPS.EXTERNAL_CHECK);
            case PREFILTERS.TOHDQC:
                return stepsArray.findIndex((localStep) => localStep === QCSTEPS.HD_CHECK);
            case PREFILTERS.READY:
                return stepsArray.length;
            default:
                return undefined;
        }
    }, [warpDetail]);

    const allErrors = useMemo(() => errorWarpDetail
        || errorWarpSampleModels
        || errorWarpSampleOutfits
        || errorHdDetail
        || (warpDetail && !warpDetail.image_url)
        || (warpDetail && warpDetail.image_url === ''),
    [warpDetail, errorWarpDetail, errorWarpSampleModels, errorWarpSampleOutfits, errorHdDetail]);

    const localFromPathname = useMemo(() => {
        if (matchPath('/refwarp/:warpId/quality', pathname)) {
            return WARP_LOCALES;
        }
        if (matchPath('/crosswarp/:warpId/quality', pathname)) {
            return CROSSWARP_LOCALES;
        }

        return null;
    }, [pathname]);

    const localizedSteps = useMemo(() => stepsArray.map((stepKey) => t(`qc_steps.${stepKey}`, { ns: COMMON_LOCALES })), [stepsArray, t]);

    // ---- Dynamic value according to warpDetail status we allow to go to further step or not ----
    const lastStepValue = useMemo(() => {
        // ---- Variable used only for default case ----
        let externalIndex = -1;
        let hdIndex = -1;

        switch (warpDetail?.warp_status) {
            case PREFILTERS.TOEXTQC:
                return stepsArray.findIndex((localStep) => localStep === QCSTEPS.EXTERNAL_CHECK) + 1;
            case PREFILTERS.TOHDQC:
                return stepsArray.findIndex((localStep) => localStep === QCSTEPS.HD_CHECK) + 1;
            case PREFILTERS.READY:
                return stepsArray.length;
            default:
                externalIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.EXTERNAL_CHECK);
                hdIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.HD_CHECK);

                if (externalIndex !== -1) {
                    return externalIndex;
                }

                if (hdIndex !== -1) {
                    return hdIndex;
                }

                return stepsArray.length;
        }
    }, [warpDetail, stepsArray]);

    const allowRefwarpWrite = usePermission('ref_warp.write');
    const allowRefwarpInternal = usePermission('ref_warp.internal');
    const allowFeedback = usePermission('feedback');

    const noValidation = useMemo(
        () => {
            // Detail obligatoire
            if (!warpDetail) {
                return true;
            }

            // Si on est pas sur le dernier step
            if (stepsArray.findIndex((localStep) => localStep === stepString) !== lastStepValue - 1) {
                return false;
            }

            // ---- If we have pending feedbacks ----
            if (pendingFeedbacks.length !== 0) {
                return false;
            }

            // Droit d'ecriture obligatoire
            if (!allowRefwarpWrite) {
                return true;
            }

            // Il ne faut pas de primary warning
            if (warpDetail.primary_warning) {
                return true;
            }

            // Si on est pas en quality
            if (!([PREFILTERS.TOQUALITY, PREFILTERS.TOHDQC] as string[]).includes(warpDetail?.warp_status)) {
                // Sauf si c'est de la validation interne
                if (warpDetail?.warp_status !== PREFILTERS.TOINTQC || !allowRefwarpInternal) {
                    return true;
                }
            }

            // ---- If we are posting feedback or fetching WarpDetail ----
            if (isWarpFeedbackLoading || isWarpDetailLoading) {
                return true;
            }

            return false;
        },
        [warpDetail, stepString, stepsArray, isWarpFeedbackLoading, isWarpDetailLoading],
    );

    const noValidationInfo = useMemo(() => {
        if (!noValidation) {
            return undefined;
        }

        if (warpDetail?.primary_warning) {
            return t('primary_warning', { ns: COMMON_LOCALES });
        }

        switch (warpDetail?.warp_status) {
            case PREFILTERS.HASFEEDBACK:
                return t('no_validation_when_feedback', { ns: ERROR_LOCALES });
            case PREFILTERS.READY:
                return t('already_processed', { ns: ERROR_LOCALES });
            default:
                return t('not_allowed', { ns: ERROR_LOCALES });
        }
    }, [warpDetail, noValidation]);

    const isValidationStep = useMemo(() => stepsArray.findIndex(
        (localStep) => localStep === stepString,
    ) === lastStepValue - 1,
    [stepString, lastStepValue, stepsArray]);

    const showBackToList = useMemo(
        () => !!((isValidationStep && noValidation)), [isValidationStep, noValidation],
    );

    const successDescription = useMemo(() => {
        if (warpDetail?.warp_status === PREFILTERS.TOHDQC
            || validateWarpQualityData?.warp_status === PREFILTERS.READY) {
            return t('finished_text', { ns: localFromPathname || WARP_LOCALES });
        }

        if ((warpDetail?.external_quality && validateWarpQualityData?.warp_status !== PREFILTERS.TOHDQC)) {
            return t('final_step_explanation', { ns: localFromPathname || WARP_LOCALES });
        }

        return t('final_step_tohd_explanation', { ns: WARP_LOCALES });
    }, [warpDetail, validateWarpQualityData, t]);

    const nextStepLabel = useMemo(() => {
        if (isValidationStep && pendingFeedbacks.length !== 0) {
            return t('feedback.send', { ns: COMMON_LOCALES });
        }

        if (isValidationStep) {
            return t('validate', { ns: PREPROCESSING_LOCALES });
        }

        return t('next_step', { ns: PREPROCESSING_LOCALES });
    }, [isValidationStep, pendingFeedbacks, t]);

    // ---- All sources that are used in the Feedback Context to check if we can use the annotation on superpose in new feedback detail ----
    const feedbackAllowSuperposeSrc = useMemo(() => {
        if (!warpDetail) {
            return undefined;
        }

        const result = [
            warpDetail.image_url,
            warpDetail.debugs.transparent.model_image_url,
            warpDetail.debugs.grid.image_url,
        ];

        if (warpDetail.debugs.smart) {
            result.push(warpDetail.debugs.smart.nosmart_image_url);
            result.push(warpDetail.debugs.smart.smart_image_url);
        }

        if (warpDetail.debugs.parts) {
            result.push(warpDetail.debugs.parts.parts_image_url);
            result.push(warpDetail.debugs.parts.torso_image_url);
        }

        if (warpDetail.debugs.garmentcut) {
            result.push(warpDetail.debugs.garmentcut.nogarmentcut_image_url);
        }

        if (warpDetail.debugs.cross) {
            result.push(warpDetail.debugs.cross.image_url);
        }

        return result;
    }, [warpDetail]);

    const changeStep = (newStep: string) => {
        // ---- Reset of page ----
        setGridScrollPage(1);

        // ---- Reset feedbackImages ----
        setFeedbackImages([]);
        setGridFeedbackImages([]);

        // ---- This is not handled so we do nothing ----
        if (!stepsArray.includes(newStep)) {
            return;
        }

        const foundStepIndex = stepsArray.findIndex((localStep) => localStep === newStep);

        // ---- We set the new step Value in URL and change the local stepValue ----
        const stepBefore = searchParams.get('step');
        searchParams.set('step', newStep);
        setSearchParams(searchParams, { replace: !stepBefore });
        setStep(foundStepIndex);
        setStepString(newStep);

        // ---- remove success screen when going back a step after showing success screen ----
        setShowSuccessScreen(false);
    };

    const handleStepIncrement = () => {
        if (isValidationStep && pendingFeedbacks.length !== 0) {
            pendingFeedbacks.forEach((pendingFeedback) => {
                sendFeedback({
                    image: pendingFeedback.image,
                    images: pendingFeedback.images,
                    message: pendingFeedback.message,
                    priority: pendingFeedback.priority,
                });
            });

            setPendingFeedbacks([]);

            return null;
        }

        if (isValidationStep) {
            return setValidateModalOpen(true);
        }

        return changeStep(stepsArray[stepsArray.findIndex((localStep) => localStep === stepString) + 1]);
    };

    const validateQC = (extraParam?: string, stepAfterValidate?: string) => {
        // ---- Send the validate PUT request ----
        validationFunction(
            {
                clientId: currentClient?.id || '',
                extraParam,
                id: warpId || '',
            },
        ).unwrap()
            .then((response) => {
                if (response.success === false) {
                    toast({
                        isClosable: true,
                        status: 'error',
                        title: t('cant_validate_status', { ns: ERROR_LOCALES }),
                    });
                }
                // ---- Put success + success in the response ----
                if (response.success) {
                    setValidateModalOpen(false);
                    if (stepAfterValidate) {
                        changeStep(stepAfterValidate);
                    } else {
                        setShowSuccessScreen(true);
                    }

                    // ---- We invalidate the garments so we have the updated garment in the list (if elesticsearch is fast enough) ----
                    dispatch(invalidateGetGarmentsAction);
                }
            });
    };

    const handleSkipExternal = () => {
        validateQC('?skip_external=1', stepsArray.includes(QCSTEPS.HD_CHECK) ? QCSTEPS.HD_CHECK : undefined);
    };

    const handleSkipHD = () => {
        validateQC('?skip_hd=1');
    };

    const changeStepIndex = (stepValue: number) => {
        changeStep(stepsArray[stepValue]);
    };

    const handleAddFeedbackImage = (newImage: FeedbackImage) => {
        setFeedbackImages((prev) => {
            const foundIndex = prev.findIndex((img) => img.src === newImage.src);
            if (foundIndex !== -1) {
                return prev.map((oldImg, index) => {
                    if (foundIndex === index) {
                        return newImage;
                    }

                    return oldImg;
                });
            }

            return [...prev, newImage];
        });
    };

    const handleChangeGridFeedbackImages = (newImages: FeedbackImage[]) => {
        setGridFeedbackImages(newImages);
    };

    useEffect(() => {
        let apiCurrentPage = null;
        let sampleData: SampleResponse | null = null;

        // ---- We chose the correct data to handle according to the step ----
        if (sampleModels && stepString === QCSTEPS.MORE_MODELS) {
            apiCurrentPage = parseInt(sampleModels.current_page_number, 10);
            sampleData = sampleModels;
        }
        if (sampleOutfits && stepString === QCSTEPS.OUTFIT_PREVIEW) {
            apiCurrentPage = parseInt(sampleOutfits.current_page_number, 10);
            sampleData = sampleOutfits;
        }

        if (!sampleData) {
            return undefined;
        }

        // ---- We concat the data to have the full list if we incremented the page ----
        if (gridScrollPage === apiCurrentPage && gridScrollPage !== 1) {
            setFullSamples((prev) => prev.concat(sampleData ? sampleData.items : []));

            return undefined;
        }

        setFullSamples(sampleData.items);

        return undefined;
    }, [sampleModels, sampleOutfits, stepString]);

    useEffect(() => {
        const stepUrl = searchParams.get('step');
        if (!stepUrl) {
            changeStep(stepsArray[0]);

            return;
        }
        setStepString(stepsArray.includes(stepUrl) ? stepUrl : stepsArray[0]);
        setStep(stepsArray.findIndex((localStep) => localStep === stepUrl));
    }, []);

    // ---- Allows to use back from navigator to change steps ----
    useEffect(() => {
        // ---- Prevents to call this at the init of component ----
        if (!warpDetail || warpDetail.warp_id !== warpId) {
            return;
        }

        const stepUrl = searchParams.get('step');
        const stepIndex = stepsArray.findIndex((localStep) => localStep === stepUrl);

        // ---- Only change step if we found a step and the step is not the current one ----
        if (stepUrl && stepIndex !== -1 && (stepString !== stepUrl || stepIndex !== step)) {
            changeStep(stepUrl);
            setShowSuccessScreen(false);

            return;
        }

        // ---- If the step from URL doesn't exist in the array of steps we go the first one ----
        if (stepUrl && !stepsArray.includes(stepUrl)) {
            changeStep(stepsArray[0]);
            setShowSuccessScreen(false);
        }
    }, [searchParams, stepsArray]);

    useEffect(() => {
        if (allErrors) {
            navigate('/refwarp');
        }
    }, [allErrors]);

    // ---- Corrects the location and data according to warpDetail value ----
    useEffect(() => {
        if (warpDetail) {
            if (warpDetail.is_override === true && matchPath('/refwarp/:warpId/quality', pathname)) {
                navigate(`/crosswarp/${warpDetail.warp_id}/quality`, { replace: true });
            }

            if (warpDetail.is_override === false && matchPath('/crosswarp/:warpId/quality', pathname)) {
                navigate(`/refwarp/${warpDetail.warp_id}/quality`, { replace: true });
            }

            // ---- Steps Update from Config ----

            const indexToRemove: number[] = [];
            // ---- We store the external quality index if external quality is false ----
            if (warpDetail.external_quality === false) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.EXTERNAL_CHECK);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- We store the hd quality index if hd quality is false ----
            if (warpDetail.hd_quality === false) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.HD_CHECK);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- We store the Sizing index if we don't have a cross image ----
            if (!warpDetail.debugs.cross) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.SIZING);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- We store the Feedbacks index if we don't have feedbacks ----
            if (feedBacks && feedBacks.total_count === 0) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.FEEDBACKS);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            if (!warpDetail.debugs.transparency || !isAdmin) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.TRANSPARENCY);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- If we have any index to remove ----
            if (indexToRemove.length > 0) {
                // ---- Order the array so the index stay the same after splice ----
                indexToRemove.sort((a, b) => (a < b ? 1 : -1));

                // ---- Need to clone the data to splice ----
                const cloneStepsArray = JSON.parse(JSON.stringify(stepsArray));
                indexToRemove.forEach((removeIndex) => {
                    cloneStepsArray.splice(removeIndex, 1);
                });

                // ---- Asign the new step array ----
                setStepsArray(cloneStepsArray);
            }

            // ---- If status is HD we go directly to HD step ----
            if (warpDetail.warp_status === PREFILTERS.TOHDQC) {
                changeStep(QCSTEPS.HD_CHECK);
            }
        }
    }, [warpDetail, feedBacks]);

    useEffect(() => {
        dispatch(setPathBlockState({
            key: pathname,
            newValue: { block: pendingFeedbacks.length > 0, message: t('block_nav.feedback_description', { ns: COMMON_LOCALES }) },
        }));
    }, [pathname, pendingFeedbacks]);

    useEffect(() => {
        if (unsortedFeedBacks) {
            const apiCurrentPage = parseInt(unsortedFeedBacks.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                setFullFeedBacks((prev) => prev.concat(unsortedFeedBacks.items));
            } else {
                setCurrentPage(1);
                setFullFeedBacks(unsortedFeedBacks.items);
            }
        }
    }, [unsortedFeedBacks]);

    return (
        <Box height='100%'>
            {
                !allErrors
                && <>
                    <FeedbackModal
                        changePendingFeedbacks={setPendingFeedbacks}
                        history={unsortedFeedBacks?.items.filter((feedback) => feedback.status !== FEEDBACK_STATUS.CANCELED)}
                        images={[...feedbackImages, ...gridFeedbackImages]}
                        modalOpen={feedbackModalOpen}
                        onClose={() => { setFeedbackModalOpen(false); }}
                        onSend={handleFeedbackModalOnSend}
                        pendingFeedbacks={pendingFeedbacks}
                        stepDetail={`${step} - ${stepName}`}
                    />
                    <ValidateModal
                        modalOpen={validateModalOpen}
                        onClose={() => { setValidateModalOpen(false); }}
                        onValidate={validateQC}
                        validateLabel={stepsArray[step + 1] === QCSTEPS.HD_CHECK ? t('validate_modal.check_hd', { ns: COMMON_LOCALES }) : undefined}
                    >
                        {
                            (warpDetail?.warp_status !== PREFILTERS.TOHDQC && warpDetail?.can_skip_external)
                            && <Button
                                onClick={handleSkipExternal}
                                variant='outline'
                            >
                                {t('validate_modal.skip_external', { ns: COMMON_LOCALES })}
                            </Button>
                        }
                        {
                            (warpDetail?.can_skip_hd && warpDetail?.warp_status !== PREFILTERS.TOHDQC)
                            && <Button
                                onClick={handleSkipHD}
                                variant='outline'
                            >
                                {t('validate_modal.skip_hd', { ns: COMMON_LOCALES })}
                            </Button>
                        }
                    </ValidateModal>
                    <SuperposeAnimationContext.Provider value={{
                        animate: animateSuperpose && typeof carouselData[carouselIndex] !== 'string' && !!carouselData[carouselIndex]
                            && (carouselData[carouselIndex] as Pose).model_identity.id === warpDetail?.model_identity.id,
                        imagesPreloadedList,
                        setAnimate: setAnimateSuperpose,
                    }}>
                        <>
                            <QualityCheckSubHeader
                                feedbackClick={() => setFeedbackModalOpen(true)}
                                hasFeedbackLink={
                                    (feedBacks && feedBacks.items.length !== 0 && warpId)
                                        ? `/warp/${warpId}/feedbacks`
                                        : undefined
                                }
                                incrementStep={handleStepIncrement}
                                isLoading={isFeedbackLoading || isWarpDetailLoading || isHDDetailLoading}
                                isValidated={showSuccessScreen}
                                lastFeedback={(feedBacks && feedBacks.items.length !== 0) ? feedBacks.items[0] : undefined}
                                nextButtonLabel={nextStepLabel}
                                noFeedback={!allowFeedback}
                                noValidation={noValidation}
                                noValidationInfo={noValidationInfo}
                                pendingFeedbacksNb={pendingFeedbacks.length}
                                showBackToList={showBackToList}
                                title={t('garments.title', { ns: localFromPathname || WARP_LOCALES })}
                                validationLoading={isValidationLoading || isHDValidationLoading || isWarpFeedbackLoading}
                                warpQuality={warpDetail}
                            >
                                <StepSlider
                                    allowToStep={allowToStep}
                                    changeValue={changeStepIndex}
                                    externalSteps={[
                                        stepsArray.findIndex((localStep) => localStep === QCSTEPS.EXTERNAL_CHECK),
                                        stepsArray.findIndex((localStep) => localStep === QCSTEPS.HD_CHECK)]}
                                    hrefs={stepHrefs}
                                    labels={localizedSteps}
                                    max={stepsArray.length - 1}
                                    min={0}
                                    step={1}
                                    value={step}
                                />
                            </QualityCheckSubHeader>
                            {
                                warpDetail?.primary_warning
                                && <BaseBanner status="warning" title={(t('primary_warning', { ns: COMMON_LOCALES }))} />
                            }
                            {
                                searchParams.get('mode') === 'garment' && <>
                                    {(showSuccessScreen && warpDetail?.image_url)
                                        ? <HStack h={FINAL_IMAGE_HEIGHT} justifyContent="center">
                                            <SuccessLayout
                                                imgSrc={resizeImage(warpDetail.image_url, { width: 800 })}
                                                text={successDescription}
                                            />
                                        </HStack>
                                        : <FeedbackImagesContext.Provider
                                            value={{
                                                addFeedbackImage: handleAddFeedbackImage,
                                                changeGridFeedbackImages: handleChangeGridFeedbackImages,
                                                feedbackAllowSuperposeSrc,
                                                feedbackImages,
                                                gridFeedbackImages,
                                            }}
                                        >
                                            <FullScreenGrid customHeight={GRID_ITEM_HEIGHT} gridObjects={gridObjects} stepString={stepString} />
                                        </FeedbackImagesContext.Provider>
                                    }
                                </>

                            }

                        </>
                    </SuperposeAnimationContext.Provider>
                </>
            }
        </Box>
    );
}

import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Feedback } from '../../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, PREFILTERS } from '../../utils/constants';
import { getCleanSearch } from '../../utils/url-helpers';
import Restricted from '../permission/Restricted';
import BaseBanner from './BaseBanner';

interface HasFeedbackBannerProps {
    detailLink?: string
    feedback: Feedback
}

export default function HasFeedbackBanner(props: HasFeedbackBannerProps) {
    const { t } = useTranslation([ERROR_LOCALES, COMMON_LOCALES]);
    const { search } = useLocation();

    const { detailLink, feedback } = props;

    const isActive = feedback.status === PREFILTERS.PENDING;

    const title = `${feedback.step
        ? `${t(
            'step', { ns: COMMON_LOCALES },
        )
        } ${t(
            `qc_steps.${feedback.step}`, { ns: COMMON_LOCALES },
        )
        }: `
        : ''}${feedback.content}`;

    return (
        <BaseBanner
            alertIcon={isActive ? WarningTwoIcon : CheckCircleIcon}
            colorScheme={isActive ? undefined : 'purple'}
            href={detailLink ? `${detailLink}?${getCleanSearch(search)}` : undefined}
            status={isActive ? 'warning' : undefined}
            title={title}
        >
            {
                (!!isActive && detailLink)
                && <Restricted to="feedback">
                    <Flex flex={1} flexDir="row">
                        <Text noOfLines={1}>{t('can_handle_it')}
                            <Button variant="link">
                                {t('here', { ns: COMMON_LOCALES })}
                            </Button>
                        </Text>
                    </Flex>
                </Restricted>
            }
        </BaseBanner>
    );
}

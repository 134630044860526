import { Button, HStack, IconButton, ModalOverlay, Spinner, Text, VStack } from '@chakra-ui/react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage as KonvaStage } from 'konva/lib/Stage';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Circle, Layer, Line, Stage } from 'react-konva';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { UndoIcon } from '../assets/icons';
import { FeedbackImage, FeedbackImageObject } from '../types/api-types';
import { COMMON_LOCALES } from '../utils/constants';
import { getKpColor } from '../utils/warp-edit-helpers';
import ZoomSlider from './sliders/ZoomSlider';
import URLKonvaImage from './URLKonvaImage';

const ERASE_CURSOR = 'url(/assets/illustrations/circle.svg) 10 10, pointer';
const KEYPOINT_SIZE = 3; // Size in px
interface LineType { points: number[], tool: string, color?: string, strokeWidth: number }

interface FeedbackKonvaStageProps {
    imageSrc: string | FeedbackImage
    currentColor: string
    tool: string
    isError?: boolean
    onDraw?(): void
    noOverlay?: boolean
}

const FeedbackKonvaStage = React.forwardRef<{ getImageObject:() => Promise<FeedbackImageObject | null> }, FeedbackKonvaStageProps>((
    props, ref,
) => {
    const { t } = useTranslation(COMMON_LOCALES);

    const { imageSrc, currentColor, tool, isError, onDraw, noOverlay } = props;

    const [hideOverlay, setHideOverlay] = useState<boolean>(false);
    const [hasDrawn, setHasDrawn] = useState<boolean>(false);
    const [lines, setLines] = useState<LineType[]>([]);
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const [zoomScale, setZoomScale] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);

    const [imageElement, setImageElement] = useState<HTMLImageElement>();

    const canvaContainer = useRef<HTMLDivElement>(null);
    const isDrawing = useRef(false);
    const stageRef = useRef<KonvaStage>(null);
    const history = useRef<LineType[][]>([[]]);
    const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

    const localImageSrc = useMemo(() => {
        if (typeof imageSrc !== 'string') {
            return imageSrc.src;
        }

        return imageSrc;
    }, [imageSrc]);

    const cursorValue = useMemo(() => {
        if (tool === 'move') {
            return 'grab';
        }

        if (tool === 'eraser') {
            return ERASE_CURSOR;
        }

        return 'crosshair';
    }, [tool]);

    React.useImperativeHandle(
        ref,
        () => ({
            async getImageObject() {
                // ---- We need the stage ref ----
                if (!stageRef || !stageRef.current) {
                    return null;
                }

                // ---- Layers var ----
                const drawLayer = stageRef.current.findOne('#draw-layer');
                const imageLayer = stageRef.current.findOne('#image-layer');

                // ---- We need the layers and we need to have drawn to send the data ----
                if (!imageLayer || !drawLayer) {
                    return null;
                }

                // ---- Convert to blob ----
                const imageBlob = await (await fetch(imageLayer.toDataURL())).blob();
                const drawBlob = await (await fetch(drawLayer.toDataURL())).blob();

                return {
                    drawing: hasDrawn ? drawBlob : null,
                    image: imageBlob,
                    ...(typeof imageSrc === 'string' ? { originalSrc: imageSrc } : null),
                    superpose: typeof imageSrc === 'string' ? false : !!imageSrc.feedbackSuperpose,
                };
            },
        }),
    );

    const handleAddToHistory = (historyLines?: LineType[]) => {
        // ---- Remove first elem if we go pass the history threshold ----
        if (history.current.length >= 100) {
            history.current.shift();
        }

        // ---- Add element to history list ----
        history.current = history.current.concat([historyLines || []]);
    };

    const handleResize = () => {
        if (imageSrc && canvaContainer?.current) {
            // ---- We create a new Image object to get the ratio of it ----
            const image = new window.Image();

            // ---- Load correct src, in array case we take the first one ----
            if (typeof localImageSrc === 'string') {
                image.src = localImageSrc;
            } else {
                [image.src] = localImageSrc;
            }

            // ---- We only handle when the image is loaded ----
            image.onload = () => {
                if (!canvaContainer.current) {
                    return;
                }

                // ---- We loaded the image ----
                setLoading(false);

                // ---- Add the image to the state so we can use the natural size for the canvas ----
                setImageElement(image);

                // ---- Image ratio ----
                const imageRatio = image.naturalWidth / image.naturalHeight;

                // ---- Size vars ----
                const containerH = canvaContainer.current.clientHeight;
                const containerW = canvaContainer.current.clientWidth;

                // ---- Scale the container so it contains the whole canvas inside it ----
                let scaleValue = 1;
                if (imageRatio > 1) {
                    scaleValue = containerW / image.naturalWidth;
                } else {
                    scaleValue = containerH / image.naturalHeight;
                }

                // ---- Need set interval here because on init the transformComponentRef doesn't exist directly ----
                let intervalTimeout = 0;
                const interval = window.setInterval(() => {
                    if (!transformComponentRef.current || intervalTimeout > 20) {
                        intervalTimeout++;

                        return;
                    }

                    clearInterval(interval);
                    transformComponentRef.current?.centerView(scaleValue);
                }, 50);

                setZoomScale(scaleValue);
            };
        }
    };

    const handleUndoClick = () => {
        // ---- We do nothing if we have less than 1 element in the list ----
        if (history.current.length <= 1) {
            return;
        }

        // ---- Get previous lines and set them to state ----
        const previous = history.current[history.current.length - 2];
        setLines(previous);

        // ---- Remove the last element of history ----
        history.current.pop();
    };

    const handleClearClick = () => {
        handleAddToHistory();
        setLines([]);
    };

    const handlePointerDown = (e: KonvaEventObject<PointerEvent>) => {
        if (!e || !e.target || tool === 'move') {
            return;
        }

        isDrawing.current = true;
        const pos = e.target?.getStage()?.getPointerPosition();

        if (!pos) {
            return;
        }

        // ---- Duplicate pos points so it shows directly on click ----
        setLines([
            ...lines,
            {
                color: currentColor,
                points: [pos.x, pos.y, pos.x, pos.y],
                strokeWidth: tool !== 'eraser' ? 2 : Math.max(20 / zoomScale, 1),
                tool,
            },
        ]);
    };

    const handlePointerMove = (e: KonvaEventObject<PointerEvent>) => {
        // no drawing - skipping
        if (!isDrawing.current || tool === 'move') {
            return;
        }
        const stage = e.target.getStage();
        const point = stage?.getPointerPosition();
        const lastLine = lines[lines.length - 1];

        if (!point) {
            return;
        }

        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // ---- Handle line mode when shift is pressed ----
        if (e.evt.shiftKey) {
            // ---- Update the line points from the first point to the current pos of mouse ----
            lastLine.points = [
                lastLine.points[0],
                lastLine.points[1],
                point.x,
                point.y,
            ];
        }

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handlePointerUp = () => {
        isDrawing.current = false;

        handleAddToHistory(lines);

        if (!hasDrawn && tool === 'pen') {
            setHasDrawn(true);

            if (onDraw) {
                onDraw();
            }
        }
    };

    const handleMouseEnterContainer = () => {
        if (!hasDrawn) {
            setHideOverlay(true);
        }

        setShowButtons(true);
    };

    const handleMouseLeaveContainer = () => {
        if (!hasDrawn) {
            setHideOverlay(false);
        }

        setShowButtons(false);
    };

    // ---- Update the inputZoomValue from scale and validate the value ----
    const updateZoomValues = (scale: number) => {
        setZoomScale(scale);
    };

    useEffect(() => {
        if (!canvaContainer.current) {
            return undefined;
        }

        // ---- Observe firstImgRef for resize calls ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(canvaContainer.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [canvaContainer.current]);

    return (
        <VStack
            // ---- 100% minus label height + gap ----
            h="calc(100% - 32px)"
            onMouseEnter={handleMouseEnterContainer}
            onMouseLeave={handleMouseLeaveContainer}
            position="relative"
            ref={canvaContainer}
            w="100%"
        >

            {
                showButtons && <HStack left={0} position="absolute" top={0} zIndex={1}>
                    <IconButton
                        aria-label='Undo'
                        icon={<UndoIcon h="100%" />}
                        onClick={handleUndoClick}
                        variant="ghost"
                    />
                    <Button onClick={handleClearClick} variant="link" w='100%'>{t('feedback.canvas_draw.clear')}</Button>
                </HStack>
            }

            {
                !noOverlay && !hideOverlay && !hasDrawn && <ModalOverlay
                    alignItems='center'
                    display={'flex'}
                    h="100%"
                    justifyContent={'center'}
                    position={'absolute'}
                    w="100%"
                >
                    <Text color="white" fontWeight='bold'>{t('feedback.canvas_draw.please_annotate')}</Text>
                </ModalOverlay>
            }
            {
                loading || !imageElement
                    ? <VStack h="100%" justifyContent="center"><Spinner size="lg" /></VStack>
                    : <>
                        <TransformWrapper
                            doubleClick={{ disabled: true }}
                            initialScale={zoomScale}
                            minScale={0.01}
                            onZoom={(zoomRef) => { setZoomScale(zoomRef.state.scale); }}
                            panning={{ disabled: tool !== 'move', velocityDisabled: true }}
                            ref={transformComponentRef}
                            wheel={{ step: 0.05 }}
                        >
                            <TransformComponent
                                contentStyle={{
                                    alignItems: 'center',
                                    height: imageElement.naturalHeight,
                                    justifyContent: 'center',
                                    width: imageElement.naturalWidth,
                                }}
                                wrapperStyle={{
                                    border: `1px solid ${(isError && !hasDrawn) ? 'red' : 'lightgray'}`,
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <Stage
                                    height={imageElement.naturalHeight}
                                    onPointerDown={handlePointerDown}
                                    onPointerMove={handlePointerMove}
                                    onPointerUp={handlePointerUp}
                                    ref={stageRef}
                                    style={{
                                        cursor: cursorValue,
                                        height: imageElement.naturalHeight,
                                        width: imageElement.naturalWidth,
                                    }}
                                    width={imageElement.naturalWidth}
                                >
                                    <Layer id={'image-layer'}>
                                        {
                                            typeof localImageSrc === 'string'
                                                ? <URLKonvaImage
                                                    height={imageElement.naturalHeight}
                                                    src={localImageSrc}
                                                    width={imageElement.naturalWidth}
                                                />
                                                : localImageSrc.map((src) => <URLKonvaImage
                                                    height={imageElement.naturalHeight}
                                                    src={src}
                                                    width={imageElement.naturalWidth}
                                                />)
                                        }
                                        {
                                            typeof imageSrc !== 'string' && imageSrc.superpose && <>
                                                {
                                                    typeof imageSrc.superpose === 'string'
                                                        ? <URLKonvaImage
                                                            height={imageElement.naturalHeight}
                                                            opacity={imageSrc.opacityValue}
                                                            src={imageSrc.superpose}
                                                            width={imageElement.naturalWidth}
                                                        />
                                                        : imageSrc.superpose.map(
                                                            (src) => <URLKonvaImage
                                                                height={imageElement.naturalHeight}
                                                                key={src}
                                                                opacity={imageSrc.opacityValue}
                                                                src={src}
                                                                width={imageElement.naturalWidth}
                                                            />,
                                                        )
                                                }
                                            </>
                                        }
                                        {
                                            (typeof imageSrc !== 'string' && imageSrc.kpList) && imageSrc.kpList.map((kp, index) => (
                                                <Circle
                                                    fill={getKpColor(kp.type)}
                                                    key={index}
                                                    radius={KEYPOINT_SIZE}
                                                    scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}
                                                    x={((imageElement.naturalWidth * kp.x) / 100)}
                                                    y={((imageElement.naturalHeight * kp.y) / 100)}
                                                />
                                            ))
                                        }

                                    </Layer>
                                    <Layer id="draw-layer">
                                        {lines.map((line, i) => (
                                            <Line
                                                globalCompositeOperation={
                                                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                                }
                                                key={i}
                                                lineCap="round"
                                                lineJoin="round"
                                                points={line.points}
                                                stroke={line.color || '#df4b26'}
                                                strokeWidth={line.strokeWidth}
                                                tension={0.5}
                                            />
                                        ))}
                                    </Layer>
                                </Stage>
                            </TransformComponent>
                        </TransformWrapper>
                        <VStack alignItems="center" bottom={0} left={2} position="absolute">
                            {
                                transformComponentRef.current
                                && <ZoomSlider
                                    max={200}
                                    min={1}
                                    transformComponentRef={transformComponentRef.current}
                                    updateZoomValues={updateZoomValues}
                                    zoomValue={zoomScale}
                                />
                            }
                        </VStack>
                    </>
            }

        </VStack>
    );
});

export default FeedbackKonvaStage;

import { HStack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES, DEFAULT_IMAGESIZE_KEY } from '../../utils/constants';
import { loadFromLocalStorage, storeInLocalStorage } from '../../utils/local-storage-helpers';
import BaseSlider from './BaseSlider';

interface ImageSliderProps {
    defaultValue?: number
    min?: number
    max?: number
    onChange?(newValue: number): void
    flexDirection?: 'row' | 'column'
    localStorageKey?: string
}

/**
 * ImageSlider with smaller value on the right and bigger on the left
 * @param props ImageSliderProps
 */
export default function ImageSlider(props: ImageSliderProps) {
    const { defaultValue, min, max, onChange, flexDirection = 'row', localStorageKey } = props;

    const defaultProductsPerRow = defaultValue || loadFromLocalStorage(localStorageKey || DEFAULT_IMAGESIZE_KEY) || 4;
    const maxProductsPerRow = max || 6;
    const minProductsPerRow = min || 3;

    const { t } = useTranslation(COMMON_LOCALES);

    const [, setProductsPerRow] = useState<number>(defaultProductsPerRow);

    const handleOnChange = (newValue: number) => {
        const newProductPerRow = minProductsPerRow + maxProductsPerRow - newValue;
        setProductsPerRow(newProductPerRow);
        storeInLocalStorage(localStorageKey || DEFAULT_IMAGESIZE_KEY, newProductPerRow.toString());

        if (onChange) {
            onChange(newProductPerRow);
        }
    };

    return (
        <HStack flex={1} flexDirection={flexDirection} justifyContent="flex-end" minW="150px" spacing={4}>
            <Text textAlign="center">{t('grid.image_sizes')}</Text>
            <BaseSlider
                defaultValue={minProductsPerRow + maxProductsPerRow - defaultProductsPerRow}
                max={maxProductsPerRow}
                min={minProductsPerRow}
                onChange={handleOnChange}
                step={1}
            />
        </HStack>
    );
}

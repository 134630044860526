import { InfoIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import React from 'react';

export default function InfoWithTooltip(props: {tooltipLabel: string}) {
    return (
        <div>
            <Tooltip label={props.tooltipLabel}>
                <InfoIcon />
            </Tooltip>
        </div>
    );
}

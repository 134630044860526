import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES } from '../../utils/constants';

interface ValidateModalProps {
    children?: ReactNode
    onClose:() => void;
    modalOpen: boolean;
    onValidate?: () => void;
    validateLabel?: string;
    description?: string;
}

export default function ValidateModal(props: ValidateModalProps) {
    const { t } = useTranslation(COMMON_LOCALES);

    const { onClose, modalOpen, onValidate, children, validateLabel, description } = props;

    const handleValidateClick = () => {
        if (onValidate) {
            onValidate();
        }
    };

    return (
        <Modal isCentered isOpen={modalOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent maxW="100%" minW="md" w="unset">
                <ModalHeader textAlign={'left'}>
                    {t('validate_modal.title')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack alignItems={'flex-start'} spacing={6}>
                        <Text textAlign="left" whiteSpace="pre-line">{description || t('validate_modal.description')}</Text>
                    </VStack>

                </ModalBody>
                <ModalFooter justifyContent={'flex-end'} minW="auto">
                    <HStack>
                        <Button onClick={onClose} variant='ghost'>{t('close')}</Button>
                        <Button autoFocus={true} onClick={() => handleValidateClick()}>{validateLabel || t('validate')}</Button>
                        {children}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Slider, SliderFilledTrack, SliderTrack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import preventCmdCtrlClick from '../utils/click-helpers';

interface Props {
    min: number;
    max: number;
    step: number;
    value: number;
    changeValue: (newValue: number) => void;
    labels?: string[]
    hrefs?: string[]
    externalSteps?: number[]
    allowToStep?: number
}

const StepSlider: React.FC<Props> = ({ min, max, step, value, changeValue, labels, externalSteps, allowToStep, hrefs }) => {
    const [maxStepSeen, setMaxStepSeen] = useState<number>(min);

    useEffect(() => {
        if (value > maxStepSeen) {
            setMaxStepSeen(value);
        }
    }, [value]);

    return (
        <Box w={`calc(100% - (100% / ${max - min}))`}>
            {
                labels && <Flex position="relative" top={-5} w="100%">
                    {
                        labels.map((label, index) => (
                            <Text
                                fontSize="sm"
                                fontWeight={index === (value - min) ? 'bold' : 'normal'}
                                key={label}
                                left={`calc(${(index * 100) / (max - min)}% - ((100% / ${max - min}) / 2))`}
                                maxWidth={`calc(100% / ${max - min})`}
                                noOfLines={1}
                                overflow="hidden"
                                position="absolute"
                                textAlign="center"
                                w={`calc(100% / ${max - min})`}
                            >{label}</Text>
                        ))
                    }
                </Flex>
            }

            <Slider
                cursor="default"
                draggable={false}
                isReadOnly
                max={max}
                min={min}
                rounded="lg"
                step={step}
                value={value}
                variant='step'
            >
                {
                    [...Array(max - (min - step))].map((_, index) => (
                        <div key={index}>
                            <Box
                                as="a"
                                backgroundColor={value >= index + min ? 'primary.500' : 'gray.500'}
                                border='2px solid white'
                                borderRadius="50%"
                                cursor={(allowToStep || maxStepSeen) >= index ? 'pointer' : 'default'}
                                h={5}
                                href={hrefs && hrefs[index]}
                                left={`calc(${(index * 100) / (max - min)}% - 0.25rem - 5px)`}
                                onClick={(e: React.MouseEvent<HTMLElement>) => preventCmdCtrlClick(e, () => changeValue(index + min))}
                                pointerEvents={(allowToStep || maxStepSeen) >= index ? 'auto' : 'none'}
                                position="absolute"
                                top="-10px"
                                w={5}
                                zIndex={1}
                            >
                                {
                                    (allowToStep || maxStepSeen) > index
                                        && <CheckIcon color="white" display="block" h="100%" ml="10%" w="80%"/>
                                }
                            </Box>
                            {
                                (externalSteps && externalSteps.includes(index + min))
                                && <HStack
                                    h='4px'
                                    left={`calc(${(index * 100) / (max - min)}% - (100% / ${max - min} / 2) - 10px)`}
                                    position="absolute"
                                    top="-2px"
                                    w="22px"
                                    zIndex={1}
                                >
                                    <Box
                                        backgroundColor="gray.200"
                                        h="100%"
                                        w="10px"

                                    ></Box>
                                    <Box
                                        backgroundColor="gray.200"
                                        h="100%"
                                        w="10px"
                                    ></Box>
                                </HStack>
                            }
                        </div>

                    ))
                }
                <SliderTrack />
                <SliderFilledTrack rounded="lg" />
            </Slider>
        </Box>
    );
};
export default StepSlider;

import { Center } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CrossWarpSubHeader from '../../components/headers/CrossWarpSubHeader';
import GridLayout from '../../components/layouts/GridLayout';
import ProductsContext from '../../components/products/ProductsContext';
import { useGetCrossWarpsQuery } from '../../services/api/api-warp';
import {
    getClient,
    getConfig,
    getConvertedActiveFilters,
    getExperience } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { CrosswarpQueryInput, Filters } from '../../types/api-types';
import { DEFAULT_CROSSWARP_FILTERS, ERROR_LOCALES, GARMENTS_LOCALES } from '../../utils/constants';
import CrosswarpGridElement from './CrosswarpGridElement';

export default function CrosswarpGrid() {
    const { t } = useTranslation(GARMENTS_LOCALES);
    const { pathname } = useLocation();

    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const config = useAppSelector((state) => getConfig(state));
    const storeConvertedActiveFilters = useAppSelector((state) => getConvertedActiveFilters(state, pathname));

    // ---- Use the default filter according to the pathname so we hide unrelevant garment ----
    const defaultFilters = DEFAULT_CROSSWARP_FILTERS;

    const [queryInputObject, setQueryInputObject] = React.useState<CrosswarpQueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience?.id,
        filters: { ...(defaultFilters), ...(storeConvertedActiveFilters) },
    });

    const { data: crosswarpResponse, isFetching: isCrosswarpLoading } = useGetCrossWarpsQuery(
        queryInputObject,
        { refetchOnMountOrArgChange: true, skip: !currentClient },
    );

    // ---- We duplicate the query with default params to use the global facet data ----
    const { data: facetDataCrosswarpResponse } = useGetCrossWarpsQuery({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience?.id,
        filters: defaultFilters,
        only_facets: 1,
    },
    { refetchOnMountOrArgChange: true, skip: !currentClient });

    function useCrosswarpQuery(filters: Filters, query = '', page = 1, collapse = null) {
        if (currentClient) {
            setQueryInputObject({
                clientId: currentClient.id,
                collapse,
                experienceId: selectedExperience?.id,
                filters: Object.keys(filters).length === 0 ? defaultFilters : { ...defaultFilters, ...filters },
                page,
                query,
            });
        }
    }

    // ---- ProductsContext var and functions ----
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<{ id: string }[]>([]);
    const selectAProduct = (id: string) => setSelectedProducts([...selectedProducts, { id }]);
    const deselectAProduct = (id: string) => {
        if (checkAll) {
            setCheckAll(false);

            return setSelectedProducts([]);
        }

        return setSelectedProducts(selectedProducts.filter((product) => product.id !== id));
    };
    const changeCheckAll = (newVal: boolean) => {
        if (!newVal) {
            setSelectedProducts([]);
        }
        setCheckAll(newVal);
    };

    return (
        <ProductsContext.Provider
            value={{
                changeCheckAll,
                checkAll,
                deselectAProduct,
                selectAProduct,
                selectedProducts,
            }}
        >
            {
                !currentClient
                    ? <Center height="100%">{t('select_client', { ns: ERROR_LOCALES })}</Center>
                    : <GridLayout
                        FlexWarpComponent={CrosswarpGridElement}
                        SubHeaderContent={CrossWarpSubHeader}
                        data={crosswarpResponse}
                        dataQuery={useCrosswarpQuery}
                        facetData={facetDataCrosswarpResponse}
                        imagesRatio={
                            config
                                ? `${(100 / config.garment_image_ratio).toString()}%`
                                : '120%'
                        }
                        isLoading={isCrosswarpLoading}
                        title={t('title', { clientName: currentClient?.name })}
                        type="garment"
                    />
            }

        </ProductsContext.Provider>

    );
}

import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { Pose } from '../../types/api-types';
import getGarmentPrimaryString from '../../utils/string-helper';
import { getGarmentDetailUrl } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import ImageWithError from '../ImageWithError';

interface PoseDetailCardProps {
    pose: Pose
}

export default function PoseDetailCard(props: PoseDetailCardProps) {
    const { pose } = props;

    const gdHref = useMemo(() => {
        const garmentId = pose.garment_id;
        if (!garmentId) {
            return undefined;
        }

        return getGarmentDetailUrl(garmentId);
    }, [pose]);

    return (
        <HStack
            alignItems="center"
            h="100%"
            justifyContent="space-between"
            spacing={2}
            w="100%"
        >
            <ImageWithError h="100%" maxH="125px" maxW="50%" src={pose.image_url}/>
            <VStack flex={1} h="100%" minW="50%" spacing={1}>
                {([
                    'garment_gender',
                    'garment_pose',
                    'garment_category',
                    'garment_primary',
                    'garment_status',
                ] as const).map((dataKey) => <HStack justifyContent="flex-start" w="100%">
                    <Text textAlign="right" wordBreak="break-all">
                        {
                            (dataKey === 'garment_primary')
                                ? getGarmentPrimaryString(pose[dataKey])
                                : pose[dataKey]
                        }
                    </Text>
                </HStack>)}
                <HStack justifyContent="flex-start" w="100%">
                    <RoundButton
                        href={gdHref}
                        onClick={() => window.open(gdHref, '_blank')}
                        text="GD"
                    />
                </HStack>

            </VStack>
        </HStack>
    );
}

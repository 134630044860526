import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import UnclosableBanner from '../../../components/UnclosableBanner';

interface CollapseGridElementProps {
    collapse: { title: string, content: ReactNode, count?: number }[]
    defaultIndex?: number[]
    warnings?: string[]
}

export default function CollapseGridElement(props: CollapseGridElementProps) {
    const { collapse, defaultIndex, warnings } = props;

    return (
        <VStack h="100%" overflowY="auto" p={4} w="100%">
            {
                warnings && warnings.map((warning) => <UnclosableBanner key={warning} title={warning} type="error"/>)
            }
            <Accordion allowMultiple defaultIndex={defaultIndex || []} display="flex" flexDirection="column" gap={4} w="100%">
                {
                    collapse.map((collapseElem) => <AccordionItem key={collapseElem.title} w="100%">
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                {collapseElem.title}{collapseElem.count !== undefined ? ` (${collapseElem.count})` : ''}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            {collapseElem.content}
                        </AccordionPanel>
                    </AccordionItem>)
                }
            </Accordion>

        </VStack>
    );
}

import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { Flex, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSuperposeAnimationContext } from '../../components/SuperposeAnimationContext';
import { WARP_EDIT_LOCALES } from '../../utils/constants';
import SingleImageGridElement from '../preprocessing/gridElements/SingleImageElement';

interface ComparisonItemProps {
    type: 'smart' | 'nosmart',
    imgSrc?: string,
    itemHeight: string,
    selectedType?: 'smart' | 'nosmart',
    isError?: boolean,
    label: string,
    onClick?(value: 'smart' | 'nosmart'): void,
    superposeImg?: string
}

const ComparisonItem = (props: ComparisonItemProps) => {
    const { t } = useTranslation(WARP_EDIT_LOCALES);
    const { type, imgSrc, itemHeight, selectedType, isError, onClick, label, superposeImg } = props;

    const SuperposeAnimationContext = useSuperposeAnimationContext();

    const [isSuperposed, setIsSuperposed] = useState<boolean>(false);

    return (
        <GridItem
            display="flex"
            flexDir="row"
            height={itemHeight}
            justifyContent="center"

        >
            <Flex
                _hover={onClick && {
                    outlineColor: selectedType === type ? 'primary.500' : 'gray',
                }}
                cursor={onClick && 'pointer'}
                m={4}
                onClick={onClick ? () => onClick(type) : undefined}
                outline={'2px solid'}
                outlineColor={selectedType === type ? 'primary.500' : 'lightgray'}
                p={4}
                position="relative"
                transition="outline-color 0.2s"
                w="100%"
            >
                {
                    selectedType === type
                    && <CheckCircleIcon color="primary.500" position="absolute" />
                }
                {
                    (!imgSrc && !isError) && <Spinner position="absolute" right={4} />
                }
                {
                    (isError && !imgSrc) && <NotAllowedIcon fontSize={'25px'} position="absolute" right={4} />
                }
                {
                    superposeImg && imgSrc
                    && <Text
                        bottom={4}
                        color="black"
                        position="absolute"
                        right={4}
                        zIndex={1}
                    >
                        {isSuperposed ? t('comparison.no_smart') : t('comparison.smart')}
                    </Text>
                }

                <SingleImageGridElement
                    imageSrc={imgSrc || ''}
                    label={label}
                    onSuperposeChange={setIsSuperposed}
                    superpose={superposeImg}
                    superposeAnimation={!!(superposeImg && imgSrc && (!SuperposeAnimationContext || SuperposeAnimationContext.animate))}
                />
            </Flex>
        </GridItem>
    );
};

interface WarpComparisonProps {
    noSmartWarp?: string,
    smartWarp?: string,
    itemHeight: string,
    onSelect?(value: 'smart' | 'nosmart'): void,
    selectedWarpType?: 'smart' | 'nosmart',
    isPostWarpError?: boolean,
}

export default function WarpComparison(props: WarpComparisonProps) {
    const { t } = useTranslation(WARP_EDIT_LOCALES);
    const { noSmartWarp, smartWarp, itemHeight, onSelect, selectedWarpType, isPostWarpError } = props;

    return (
        <Grid templateColumns={'repeat(2, 1fr)'}>
            <ComparisonItem
                imgSrc={noSmartWarp}
                isError={isPostWarpError}
                itemHeight={itemHeight}
                label={t(onSelect ? 'comparison.no_smart_title' : 'comparison.no_smart_title_no_select')}
                onClick={onSelect}
                selectedType={selectedWarpType}
                type="nosmart"
            />
            <ComparisonItem
                imgSrc={smartWarp}
                isError={isPostWarpError}
                itemHeight={itemHeight}
                label={t(onSelect ? 'comparison.smart_title' : 'comparison.smart_title_no_select')}
                onClick={onSelect}
                selectedType={selectedWarpType}
                superposeImg={noSmartWarp}
                type="smart"
            />
        </Grid>
    );
}

import { Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack, StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const DEFAULT_PROPS = {
    defaultValue: 0.5,
    max: 1,
    min: 0,
    step: 0.01,
    variant: 'gray',
    w: 120,
};

interface HorizontalSliderProps {
    containerProps?: StackProps,
    title?: string,
    sliderProps?: SliderProps
}

export default function HorizontalSlider(props: HorizontalSliderProps) {
    const { containerProps, title, sliderProps } = props;

    return (
        <VStack {...containerProps}>
            {title && <Text>{title}</Text>}
            <Slider {...DEFAULT_PROPS} {...sliderProps}>
                <SliderTrack h={0.5}>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </VStack>
    );
}

import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import InfoWithTooltip from '../../../components/InfoWithTooltip';
import OpacitySlider from '../../../components/sliders/OpacitySlider';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import GridItemImage from '../GridItemImage';

interface OpacityGridElementProps {
    label?: string
    info?: string
    opacitySrc: string
    imageSrc: string | string[]
    additionalText?: string,
    defaultOpacity?: number
}

const DEFAULT_OPACITY = 0.5;

export default function OpacityGridElement(props: OpacityGridElementProps) {
    const { label, info, opacitySrc, imageSrc, additionalText, defaultOpacity } = props;

    const [opacityValue, setOpacityValue] = useState<number>(defaultOpacity || DEFAULT_OPACITY);

    useFeedbackContextHelper({ imageSrc, label, opacityValue, overlay: [opacitySrc] });

    return (
        <VStack h="100%" w="100%">
            <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                {
                    label && <HStack justifyContent="center">
                        <Text fontWeight="bold">{label}</Text>
                        {info && <InfoWithTooltip tooltipLabel={info} />}
                    </HStack>
                }

                <OpacitySlider defaultValue={defaultOpacity || DEFAULT_OPACITY} onChange={setOpacityValue} />
            </HStack>

            <HStack
                height="100%"
                justifyContent="center"
                spacing={0}
                w="100%"
            >
                <GridItemImage
                    additionalText={additionalText}
                    opacitySrc={opacitySrc}
                    opacityValue={opacityValue}
                    src={imageSrc}
                />
            </HStack>
        </VStack>
    );
}

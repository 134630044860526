import { Box, Divider, Flex, HStack, Spinner, Text, useToast, VStack, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forceCheck } from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CheckFinalCard from '../../components/cards/CheckFinalCard';
import ValidationSubHeader from '../../components/headers/ValidationSubHeader';
import ImageWithError from '../../components/ImageWithError';
import FeedbackModal from '../../components/modals/FeedbackModal';
import {
    useGetCheckGarmentDetailQuery,
    useGetCheckGarmentModelsQuery,
    usePutCheckGarmentValidateMutation,
} from '../../services/api/api-check';
import {
    invalidateFeedbackAction,
    useGetCheckGarmentFeedbacksQuery,
} from '../../services/api/api-feedback';
import { getClient } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ErrorMutation, FeedbackParams, Model } from '../../types/api-types';
import { CHECKFINAL_LOCALES, COMMON_LOCALES, CROSSWARP_IMAGESIZE_KEY, CROSSWARP_LOCALES, ERROR_LOCALES, PREFILTERS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useFeedbacks from '../../utils/feedback-hook';
import resizeImage from '../../utils/image';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import WarpDetailGridElement from '../preprocessing/gridElements/WarpDetailGridElement';

const SCREEN_ID = 'checkFinalValidation';

export default function CheckFinalValidation() {
    // ---- Util Hooks ----
    const { t } = useTranslation([COMMON_LOCALES, ERROR_LOCALES, CROSSWARP_LOCALES, CHECKFINAL_LOCALES]);
    const { garmentId } = useParams();
    const toast = useToast();
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();
    const { sendCheckGarmentFeedback } = useFeedbacks();

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));
    const config = useAppSelector((state) => state.session.config);

    // ---- States ----
    const [gridScrollPage, setGridScrollPage] = useState<number>(1);
    const [productsPerRow, setProductsPerRow] = useState<number>(loadFromLocalStorage(CROSSWARP_IMAGESIZE_KEY) || 4);
    const [fullModels, setFullModels] = useState<Model[]>([]);
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const [currentFeedbackModel, setCurrentFeedbackModel] = useState<Model | undefined>();
    const [localPostedFeedback, setLocalPostedFeedback] = useState<boolean>(false);
    const [viewBackChecked, setViewBackChecked] = useState<boolean>(false);

    // ---- APIs ----
    const { data: feedbacks } = useGetCheckGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', sort: true },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );
    const { data: detailData, error: errorDetail, isLoading: isDetailDataLoading } = useGetCheckGarmentDetailQuery(
        { clientId: currentClient?.id || '', id: garmentId || '' },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );
    const { data: modelsData, isLoading: isModelsLoading, error: errorModels } = useGetCheckGarmentModelsQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', page: gridScrollPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );
    const [putValidate] = usePutCheckGarmentValidateMutation();

    // ---- Called when the grid of sample is scrolled ----
    const handleScrollChangePage = () => {
        handlePaginationScroll(isModelsLoading, gridScrollPage, () => setGridScrollPage(gridScrollPage + 1), modelsData, fullModels.length);
    };

    const noValidationAllowed = useMemo(() => {
        if (!detailData) {
            return true;
        }

        if (errorModels || errorDetail) {
            return true;
        }

        return (localPostedFeedback
            || (
                detailData.garment_check
                && ([PREFILTERS.INTERNAL_FEEDBACK, PREFILTERS.FEEDBACK, PREFILTERS.VALIDATED] as string[]).includes(detailData.garment_check)
            ));
    }, [localPostedFeedback, detailData, errorModels, errorDetail]);

    const handleImageSizeChange = (newValue: number) => {
        setProductsPerRow(newValue);
        forceCheck();
    };

    const handleFeedbackSuccess = () => {
        setFeedbackModalOpen(false);

        dispatch(invalidateFeedbackAction);

        if (!localPostedFeedback) {
            setLocalPostedFeedback(true);
        }
    };

    const sendFeedback = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        // ---- Add the name of the model if it existe before the feedback so it's easier to process ----
        const prefixMessage = currentFeedbackModel
            ? `${currentFeedbackModel.model_identity.display || currentFeedbackModel.model_identity.name}: `
            : '';
        sendCheckGarmentFeedback({ ...params, garmentId, message: `${prefixMessage}${params.message}` }, handleFeedbackSuccess);

        resolve();

        return null;
    });

    const handleOpenFeedbackModal = (model?: Model) => {
        setCurrentFeedbackModel(model);
        setFeedbackModalOpen(true);
    };

    const handleOnViewChange = (newVal: number) => {
        setImageIndex(newVal);
        if (!viewBackChecked && newVal === 1) {
            setViewBackChecked(true);
        }
    };

    const handleValidate = () => {
        if (noValidationAllowed) {
            navigate('/check');

            return;
        }

        if (!viewBackChecked) {
            handleOnViewChange(1);

            toast({
                description: t('check_back_view', { ns: CHECKFINAL_LOCALES }),
                isClosable: true,
                status: 'info',
            });

            return;
        }

        if (currentClient && garmentId) {
            putValidate({ clientId: currentClient.id, id: garmentId })
                .unwrap().then((response) => {
                    if (response.success === false) {
                        toast({
                            isClosable: true,
                            status: 'error',
                            title: t('cant_validate_status', { ns: ERROR_LOCALES }),
                        });
                    }
                    // ---- Put success + success in the response ----
                    if (response.success) {
                        toast({
                            isClosable: true,
                            status: 'success',
                            title: t('success', { ns: CHECKFINAL_LOCALES }),
                        });
                        navigate('/check');
                    }
                });
        }
    };

    useEffect(() => {
        if (!modelsData) {
            return undefined;
        }

        const apiCurrentPage = parseInt(modelsData.current_page_number, 10);

        // ---- We concat the data to have the full list if we incremented the page ----
        if (gridScrollPage === apiCurrentPage && gridScrollPage !== 1) {
            setFullModels((prev) => prev.concat(modelsData ? modelsData.items : []));

            return undefined;
        }

        setFullModels(modelsData.items);

        return undefined;
    }, [modelsData]);

    return (
        <Box height='100%'>
            <FeedbackModal
                hidePriority={true}
                modalOpen={feedbackModalOpen}
                onClose={() => { setFeedbackModalOpen(false); }}
                onSend={sendFeedback}
                screenId={SCREEN_ID}
            />
            <VStack boxSize="full" spacing={0}>
                <ValidationSubHeader
                    detailData={detailData}
                    feedback={feedbacks && feedbacks.items.length > 0 ? feedbacks.items[0] : undefined}
                    onFeedbackClick={() => handleOpenFeedbackModal()}
                    onImageSizeChange={handleImageSizeChange}
                    onValidate={handleValidate}
                    onViewBackChange={handleOnViewChange}
                    validateButtonLabel={noValidationAllowed ? t('done') : undefined}
                    viewBackValue={imageIndex}
                />

                <HStack boxSize="full" id={SCREEN_ID} overflow="hidden" pl={4} spacing={0}>
                    {
                        errorDetail
                            ? <VStack flex={1} h="100%" justifyContent="center">
                                <Text>{(errorDetail as ErrorMutation)?.data?.message}</Text>
                            </VStack>
                            : <VStack alignItems="center" flex={1} h="100%" justifyContent="center" minH="100%" spacing={0}>
                                {
                                    isDetailDataLoading
                                        ? <Spinner />
                                        : <> {
                                            (detailData)
                                            && <WarpDetailGridElement
                                                garmentDetail={{
                                                    imageLabel: detailData.garment_name,
                                                    productCategory: detailData.garment_type,
                                                }}
                                                imageSrc={resizeImage(detailData.image_url, { width: 800 })}
                                                label={t('ref_warp', { ns: CROSSWARP_LOCALES })}
                                                modelIdentity={detailData.model_identity}
                                            />
                                        }
                                        </>
                                }
                            </VStack>
                    }

                    <Divider orientation='vertical' />

                    {
                        errorModels
                            ? <VStack flex={4} h="100%" justifyContent="center">
                                <Text>{`${t('error_get_check_detail', { ns: ERROR_LOCALES })}:`}</Text>
                                <Text>{`${(errorModels as ErrorMutation)?.data?.message}`}</Text>
                            </VStack>
                            : <VStack alignItems="center" flex={4} h="100%" justifyContent="center" pl={4} spacing={0}>
                                {
                                    isDetailDataLoading
                                        ? <Spinner />
                                        : <Flex
                                            as="ul"
                                            flexWrap="wrap"
                                            gridGap={4}
                                            height="100%"
                                            onScroll={handleScrollChangePage}
                                            overflowY="auto"
                                            pt={2}
                                            width="100%"
                                        >
                                            {
                                                modelsData && modelsData.items.map((model) => (
                                                    <WrapItem
                                                        key={model.model_id}
                                                        w={`calc(100% / ${productsPerRow} - 16px)`}
                                                    >
                                                        <CheckFinalCard
                                                            imageIndex={imageIndex}
                                                            imageRatio={config?.look_image_ratio ? `${100 / config.look_image_ratio}%` : '100%'}
                                                            model={model}
                                                            onFeedbackClick={() => handleOpenFeedbackModal(model)}
                                                        />
                                                    </WrapItem>
                                                ))
                                            }
                                        </Flex>
                                }
                            </VStack>
                    }

                    <Divider orientation='vertical' />

                    <VStack flex={0.5} h="100%" minH="100%" pl={4} pr={4} spacing={2}>
                        <Text fontWeight="bold">{t('extra')}</Text>
                        <VStack overflowY="auto">
                            {
                                (detailData?.extras) && detailData.extras.map((extra) => (
                                    <ImageWithError key={extra} src={resizeImage(extra, { width: 800 })} />
                                ))
                            }
                        </VStack>
                    </VStack>
                </HStack>
            </VStack>

        </Box >

    );
}

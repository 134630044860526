import { WarningTwoIcon } from '@chakra-ui/icons';
import { Flex, HStack, Text, Tooltip, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import RoundButton from '../../../components/buttons/RoundButton';
import ImageWithError from '../../../components/ImageWithError';
import InfoWithTooltip from '../../../components/InfoWithTooltip';
import ModelIdentityBanner from '../../../components/ModelIdentityBanner';
import { useSuperposeAnimationContext } from '../../../components/SuperposeAnimationContext';
import { Pose } from '../../../types/api-types';
import { COMMON_LOCALES, PREFILTERS, WARP_LOCALES } from '../../../utils/constants';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import resizeImage from '../../../utils/image';
import { getCleanSearch } from '../../../utils/url-helpers';
import GridItemImage from '../GridItemImage';

interface CarouselGridElementProps {
    label?: string
    info?: string
    imagesData: (string | Pose)[]
    superpose?: string | string[]
    onIndexChange?(newIndex: number): void
}

export default function CarouselGridElement(props: CarouselGridElementProps) {
    const { t } = useTranslation(WARP_LOCALES);
    const { search } = useLocation();
    const SuperposeAnimationContext = useSuperposeAnimationContext();

    const { label, info, imagesData, superpose, onIndexChange } = props;

    useFeedbackContextHelper({ imagesData, label, superpose });

    const [indexSelected, setIndexSelected] = useState<number>(0);

    const getImageSrc = (imageObject: string | Pose) => {
        if (typeof imageObject === 'string') {
            return resizeImage(imageObject, { width: 800 });
        }

        return resizeImage(imageObject.image_url, { width: 800 });
    };

    const isPose = (imageObject: string | Pose) => typeof imageObject !== 'string';

    const poseElements = (imageObject: string | Pose) => {
        if (typeof imageObject === 'string' || !imageObject.warp_id) {
            return null;
        }

        return <VStack align="flex-start" justify="center" left="8px" position="absolute" spacing="8px" top="8px" zIndex={1}>
            <RoundButton
                href={`/refwarp/${imageObject.warp_id}/quality?${getCleanSearch(search)}`}
                text="QC"
                tooltipLabel={t('qc', { ns: COMMON_LOCALES })}
            />
        </VStack>;
    };

    const heightInfos = useMemo(() => {
        if (imagesData.length === 0) {
            return null;
        }

        const imageObject = imagesData[indexSelected];

        // ---- Need Pose object ----
        if (typeof imageObject === 'string') {
            return null;
        }

        // ---- Need Reference and Warp info ----
        if (!imageObject.model_identity) {
            return null;
        }

        return <ModelIdentityBanner
            modelHeight={imageObject.model_identity.height}
            modelSizes={[imageObject.model_identity.garment_size, imageObject.model_identity.garment_size2]}
        />;
    }, [indexSelected]);

    const contextImageLoadedSuperpose = useMemo(() => {
        // ---- If there is no superpose context or no imagePreloaded list we allow the animation from the context var ----
        if (!SuperposeAnimationContext || !SuperposeAnimationContext.imagesPreloadedList) {
            return true;
        }

        // ---- We need superpose to exists to have the animation ----
        if (!superpose) {
            return false;
        }

        // ---- Check if the superpose (single img or multiple) is loaded ----
        let includeSuperpose = true;
        if (typeof superpose === 'string') {
            includeSuperpose = SuperposeAnimationContext.imagesPreloadedList.includes(superpose);
        } else {
            for (let i = 0; i < superpose.length; i++) {
                if (!SuperposeAnimationContext.imagesPreloadedList.includes(superpose[i])) {
                    includeSuperpose = false;
                    break;
                }
            }
        }

        return (
            SuperposeAnimationContext.imagesPreloadedList.includes(getImageSrc(imagesData[indexSelected]))
            && includeSuperpose
        );
    }, [SuperposeAnimationContext, indexSelected, imagesData, superpose]);

    const handleImageClick = (index: number) => {
        if (onIndexChange) {
            onIndexChange(index);
        }

        setIndexSelected(index);
    };

    return (
        <VStack h="100%" position="relative" spacing={0} w="100%">
            {
                label && <HStack justifyContent="center">
                    <Text fontWeight="bold">{label}</Text>
                    {info && <InfoWithTooltip tooltipLabel={info} />}
                </HStack>
            }
            <HStack
                flex={1}
                justifyContent="center"
                overflow="hidden"
                position="relative"
                spacing={0}
                w="100%"
            >
                {
                    imagesData.length > 0
                        ? <>
                            {
                                poseElements(imagesData[indexSelected])
                            }
                            <GridItemImage
                                src={getImageSrc(imagesData[indexSelected])}
                                superpose={superpose}
                                superposeAnimation={(
                                    !!(typeof imagesData[indexSelected] !== 'string' && superpose)
                                    && (!SuperposeAnimationContext || SuperposeAnimationContext.animate)
                                    && contextImageLoadedSuperpose
                                )}
                            />

                            <VStack height="100%" overflowY="auto" w="100px">
                                <Wrap spacing={0}>
                                    {
                                        imagesData.map((imageData, index) => (
                                            <WrapItem
                                                justifyContent="center"
                                                key={getImageSrc(imageData)}
                                                marginLeft={0}
                                                marginRight={0}
                                                paddingBottom={index !== imagesData.length - 1 ? 2 : 0}
                                                position="relative"
                                                w="100%"
                                            >
                                                {
                                                    (
                                                        isPose(imageData)
                                                        && (imageData as Pose).warp_status !== undefined
                                                        && (imageData as Pose).warp_status !== PREFILTERS.READY
                                                    )
                                                    && <Flex left={0} position="absolute">
                                                        <Tooltip label={t('warp_not_ready')} placement="top">
                                                            <WarningTwoIcon color="orange" />
                                                        </Tooltip>
                                                    </Flex>
                                                }
                                                <ImageWithError
                                                    height="100px"
                                                    objectFit="contain"
                                                    onClick={() => handleImageClick(index)}
                                                    src={getImageSrc(imageData)}
                                                />
                                            </WrapItem>

                                        ))
                                    }
                                </Wrap>

                            </VStack>
                        </>
                        : <Text>{t('no_comparison_images')}</Text>
                }
            </HStack>
            {heightInfos}
        </VStack>
    );
}

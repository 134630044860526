import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import RoundButton from '../components/buttons/RoundButton';
import FeedbackCardsList from '../components/FeedbackCardsList';
import FeedbackOverlay from '../components/FeedbackOverlay';
import BaseSubHeader from '../components/headers/BaseSubHeader';
import Restricted from '../components/permission/Restricted';
import { useGetExternalQualityQuery } from '../services/api/api-external';
import { useGetWarpFeedbacksQuery } from '../services/api/api-feedback';
import { useGetGarmentDetailQuery } from '../services/api/api-garment';
import { useGetWarpQualityQuery } from '../services/api/api-warp';
import { getClient } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { Feedback, Pose } from '../types/api-types';
import preventCmdCtrlClick from '../utils/click-helpers';
import { COMMON_LOCALES, LAYOUT_LOCALES, WARP_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';
import useFeedbacks from '../utils/feedback-hook';
import resizeImage from '../utils/image';
import useRoles from '../utils/roles-hook';
import handlePaginationScroll from '../utils/scroll-pagination-helpers';
import { getCleanSearch, getGarmentDetailUrl, getModelDetailUrl, getWarpDetailUrl } from '../utils/url-helpers';
import WarpDetailGridElement from './preprocessing/gridElements/WarpDetailGridElement';

export default function Feedbacks() {
    // ---- Utils Hooks ----
    const { t } = useTranslation([COMMON_LOCALES, WARP_LOCALES]);
    const navigate = useCustomNavigate();
    const { search, pathname } = useLocation();
    const { isAdmin, isNoRole } = useRoles();
    const { getVisibleIdsFromFeedbacks } = useFeedbacks();

    // ---- Props ----
    const { warpId, garmentId } = useParams();

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- State ----
    const [fullFeedBacks, setFullFeedBacks] = useState<Feedback[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [visibleFeedbackIds, setVisibleFeedbackIds] = useState<string[]>();

    // ---- MatchPath vars to know which feedback to use ----
    const useWarpFeedback = matchPath('warp/:warpId/feedbacks', pathname);
    const useExternalFeedback = matchPath('external_validation/:warpId/feedbacks', pathname);
    const useCheckFeedback = matchPath('check/:garmentId/feedbacks', pathname);
    // ---- API ----
    const { data: warpDetail, isError: isErrorWarpQuality } = useGetWarpQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        { skip: !currentClient || !warpId || !useWarpFeedback },
    );
    const { data: externalQuality } = useGetExternalQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || !useExternalFeedback },
    );
    const { data: garmentDetail } = useGetGarmentDetailQuery(
        { clientId: currentClient?.id || '', id: garmentId || warpDetail?.garment_id || externalQuality?.garment_id || '' },
        { skip: !currentClient || !(garmentId || warpDetail?.garment_id || externalQuality?.garment_id) },
    );

    const { data: warpFeedBacks, isLoading: isWarpFeedbackLoading } = useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || !useWarpFeedback },
    );

    const isGDDisabled = useMemo(() => !garmentDetail && (warpDetail && !warpDetail.garment_id), [garmentDetail, warpDetail]);
    const gdHref = useMemo(() => {
        const localGarmentId = garmentDetail?.garment_id || warpDetail?.garment_id;
        if (isGDDisabled || !localGarmentId) {
            return undefined;
        }

        return getGarmentDetailUrl(localGarmentId);
    }, [isGDDisabled, garmentDetail, warpDetail]);

    const isWDisabled = useMemo(
        () => !warpDetail || !warpDetail.warp_id, [warpDetail],
    );
    const wHref = useMemo(() => {
        if (isWDisabled || !warpDetail) {
            return undefined;
        }

        return getWarpDetailUrl(warpDetail.warp_id);
    }, [isWDisabled, warpDetail]);

    const isMDisabled = useMemo(
        () => !warpDetail || !warpDetail.model_id, [warpDetail],
    );
    const mHref = useMemo(() => {
        if (isMDisabled || !warpDetail) {
            return undefined;
        }

        return getModelDetailUrl(warpDetail.model_id);
    }, [isWDisabled, warpDetail]);

    const isRWQCDisabled = useMemo(() => !warpDetail, [warpDetail]);
    const rwqcHref = useMemo(() => {
        if (isRWQCDisabled) {
            return undefined;
        }

        return `/refwarp/${warpDetail?.warp_id}/quality?${getCleanSearch(search)}`;
    }, [isRWQCDisabled, warpDetail, search]);

    const isPPQCDisabled = useMemo(() => !garmentDetail, [garmentDetail]);
    const ppqcHref = useMemo(() => {
        if (isPPQCDisabled) {
            return undefined;
        }

        return `/preprocessing/${garmentDetail?.garment_id}/quality?${getCleanSearch(search)}`;
    }, [isPPQCDisabled, garmentDetail, search]);

    const isCheckDisabled = useMemo(() => !garmentId, [garmentId]);
    const checkHref = useMemo(() => {
        if (isCheckDisabled) {
            return undefined;
        }

        return `/check/${garmentId}?${getCleanSearch(search)}`;
    }, [isCheckDisabled, search]);

    const carouselData = useMemo(() => {
        let result: (string | Pose)[] = [];
        if (!warpDetail) {
            return result;
        }

        result = [...(warpDetail.extras || [])];

        if (warpDetail.reference) {
            result.unshift(warpDetail.reference);
        }

        result.unshift(warpDetail.image_url);

        return result;
    }, [warpDetail]);

    const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80) {
            return;
        }
        handlePaginationScroll(isWarpFeedbackLoading, currentPage, () => setCurrentPage(currentPage + 1), warpFeedBacks, fullFeedBacks.length);
    };

    useEffect(() => {
        if (isErrorWarpQuality) {
            navigate('/');
        }
    }, [isErrorWarpQuality]);

    useEffect(() => {
        if (warpFeedBacks) {
            const apiCurrentPage = parseInt(warpFeedBacks.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                setFullFeedBacks((prev) => prev.concat(warpFeedBacks.items));
            } else {
                setCurrentPage(1);
                setFullFeedBacks(warpFeedBacks.items);
            }

            if (!visibleFeedbackIds) {
                setVisibleFeedbackIds(getVisibleIdsFromFeedbacks(warpFeedBacks.items));
            }
        }
    }, [warpFeedBacks]);

    return (
        <Box height='100%' id='crosswarpFeedback'>
            <VStack boxSize="full" spacing={0}>
                <BaseSubHeader>
                    <HStack pl={4} pr={4} w="100%">
                        <VStack alignItems="flex-start" flex={1}>
                            <Text fontWeight="bold">{t('feedback.title')}</Text>
                            {
                                (isAdmin && (warpDetail || garmentDetail))
                                && <HStack flex={1} justifyContent="flex-start" minW={90} spacing={1} w="100%">
                                    <Restricted to="old_routes">
                                        <RoundButton
                                            href={gdHref}
                                            isDisabled={isGDDisabled}
                                            onClick={() => window.open(gdHref, '_blank')}
                                            text="GD"
                                            tooltipLabel={t('gd', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    <Restricted to="preprocessing">
                                        <RoundButton
                                            href={ppqcHref}
                                            isDisabled={isPPQCDisabled}
                                            onClick={() => navigate(
                                                `/preprocessing/${warpDetail?.garment_id || garmentDetail?.garment_id}/quality`,
                                            )}
                                            secondLine="QC"
                                            text="PP"
                                            tooltipLabel={t('ppqc', { ns: COMMON_LOCALES })}
                                        />
                                    </Restricted>
                                    {
                                        warpDetail && <>
                                            <Restricted to="old_routes">
                                                <RoundButton
                                                    href={wHref}
                                                    isDisabled={isWDisabled}
                                                    onClick={() => window.open(wHref, '_blank')}
                                                    text="W"
                                                    tooltipLabel={t('w', { ns: COMMON_LOCALES })}
                                                />
                                                <RoundButton
                                                    href={mHref}
                                                    isDisabled={isMDisabled}
                                                    onClick={() => window.open(mHref, '_blank')}
                                                    text="M"
                                                    tooltipLabel={t('m', { ns: COMMON_LOCALES })}
                                                />
                                                <RoundButton
                                                    href={rwqcHref}
                                                    isDisabled={isRWQCDisabled}
                                                    onClick={() => navigate(`/refwarp/${warpDetail.warp_id}/quality`)}
                                                    secondLine="QC"
                                                    text="RW"
                                                    tooltipLabel={t('rwqc', { ns: COMMON_LOCALES })}
                                                />
                                            </Restricted>
                                        </>
                                    }

                                    {
                                        useCheckFeedback && garmentId
                                        && <RoundButton
                                            href={checkHref}
                                            isDisabled={isCheckDisabled}
                                            onClick={() => navigate(`/check/${garmentId}`)}
                                            text="CF"
                                            tooltipLabel={t('tabs.check_final', { ns: LAYOUT_LOCALES })}
                                        />
                                    }
                                </HStack>
                            }
                            {
                                useCheckFeedback && garmentId && isNoRole
                                && <Button
                                    as="a"
                                    href={checkHref}
                                    isDisabled={isCheckDisabled}
                                    onClick={(e) => preventCmdCtrlClick(e, () => navigate(`/check/${garmentId}`))}
                                    variant="link"
                                >
                                    {t('tabs.check_final', { ns: LAYOUT_LOCALES })}
                                </Button>
                            }
                        </VStack>
                    </HStack>
                </BaseSubHeader>
                <HStack boxSize="full" overflow="hidden" pl={4} spacing={0}>
                    {
                        warpId && warpDetail
                            ? <FeedbackOverlay
                                carouselData={carouselData}
                                feedbacks={fullFeedBacks}
                                imgSrc={warpDetail?.image_url}
                                onScroll={handleOnScroll}
                            />
                            : <>
                                <VStack flex={1} h="100%" minH="100%" spacing={0}>
                                    {
                                        (warpId && warpDetail)
                                        && <WarpDetailGridElement
                                            garmentDetail={{
                                                description: garmentDetail?.product_description,
                                                imageLabel: garmentDetail?.garment_name,
                                                productCategory: garmentDetail?.garment_type,
                                                productName: garmentDetail?.product_name,
                                            }}
                                            imageSrc={resizeImage(warpDetail.image_url, { width: 800 })}
                                            label={t('w')}
                                            warpDetail={warpDetail}
                                        />
                                    }

                                    {
                                        (garmentDetail && (garmentId || useExternalFeedback)) && <WarpDetailGridElement
                                            garmentDetail={{
                                                description: garmentDetail.product_description,
                                                imageLabel: garmentDetail.garment_name,
                                                productCategory: garmentDetail.garment_type,
                                                productName: garmentDetail.product_name,
                                            }}
                                            imageSrc={resizeImage(garmentDetail.image_url, { width: 800 })}
                                            label={t('gd')}
                                        />
                                    }
                                </VStack>
                                <Divider orientation='vertical' />
                                <FeedbackCardsList containerStyle={{ flex: 3, h: '100%', p: 4 }} garmentId={garmentId} warpId={warpId} />
                            </>
                    }
                </HStack>
            </VStack>
        </Box>
    );
}

import { Box, Button, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import ImageWithError from '../../components/ImageWithError';
import ZoomSlider from '../../components/sliders/ZoomSlider';
import { PREPROCESSING_LOCALES } from '../../utils/constants';
import resizeImage from '../../utils/image';

export const CENTER_SPINNER = <Box boxSize="full" position="absolute">
    <Center h="100%" w="100%">
        <Spinner />
    </Center>
</Box>;

interface GridItemImageProps {
    src: string | string[],
    imageSelected?: number | null,
    overlay?: string[],
    superpose?: string | string[],
    superposeAnimation?: boolean,
    opacitySrc?: string,
    opacityValue?: number,
    altZoom?: boolean,
    onSuperposeChange?(superposeValue: boolean): void,
    overrideSuperposeBoolean?: boolean
    additionalText?: string,
    noSrcResize?: boolean,
    dataSrc?: string
}

export default function GridItemImage(props: GridItemImageProps) {
    const { t } = useTranslation(PREPROCESSING_LOCALES);

    const {
        src,
        imageSelected,
        overlay,
        superpose,
        opacitySrc,
        opacityValue,
        altZoom,
        superposeAnimation,
        onSuperposeChange,
        overrideSuperposeBoolean,
        additionalText,
        noSrcResize,
        dataSrc,
    } = props;

    const [transformComponentRef, setTransformComponentRef] = useState<ReactZoomPanPinchRef | null>();

    const [isSuperpose, setIsSuperpose] = useState<boolean>(false);
    const [animateSuperpose, setAnimateSuperpose] = useState<boolean | undefined>(superposeAnimation);

    const [zoomValue, setZoomValue] = useState<number>(1);

    // ---- Update the inputZoomValue from scale and validate the value ----
    const updateZoomValues = (scale: number) => {
        setZoomValue(scale);
    };

    // ---- This value checks if we want an external value to override the local superpose ----
    const isSuperposeCheck = useMemo(() => {
        if (overrideSuperposeBoolean !== undefined) {
            return overrideSuperposeBoolean;
        }

        return isSuperpose;
    }, [overrideSuperposeBoolean, isSuperpose]);

    useEffect(() => {
        let interval = 0;

        // ---- If the animation is active ----
        if (animateSuperpose) {
            interval = window.setInterval(() => {
                setIsSuperpose((prevValue) => !prevValue);
            }, 500);

            return () => {
                clearInterval(interval);
            };
        }

        // ---- Clear the interval if the animation is disabled ----
        clearInterval(interval);

        return undefined;
    }, [animateSuperpose]);

    useEffect(() => {
        setAnimateSuperpose(superposeAnimation);
        if (!superposeAnimation) {
            setIsSuperpose(false);
        }
    }, [superposeAnimation]);

    useEffect(() => {
        if (onSuperposeChange) {
            onSuperposeChange(isSuperposeCheck);
        }
    }, [isSuperposeCheck]);

    const handleSuperposeMouseEnter = () => {
        setIsSuperpose(true);
    };

    const handleSuperposeMouseLeave = () => {
        setIsSuperpose(false);
    };

    const handleContainerMouseEnter = () => {
        if (superposeAnimation) {
            setAnimateSuperpose(false);
            setIsSuperpose(false);
        }
    };

    const handleContainerMouseLeave = () => {
        if (superposeAnimation) {
            setAnimateSuperpose(true);
        }
    };

    // ---- Height is full without Label size and margins ----
    return (
        <VStack h="100%" spacing={0} w="100%">
            <Box
                display="flex"
                height="100%"
                justifyContent="center"
                onMouseEnter={handleContainerMouseEnter}
                onMouseLeave={handleContainerMouseLeave}
                position="relative"
                w={'100%'}
            >
                <TransformWrapper
                    onZoomStop={(ref) => {
                        updateZoomValues(ref.state.scale);
                    }}
                    ref={setTransformComponentRef}
                    wheel={{
                        activationKeys: altZoom ? ['Alt'] : [],
                        step: 0.05,
                    }}
                >
                    <TransformComponent
                        contentStyle={{ height: '100%', justifyContent: 'center', width: '100%' }}
                        wrapperStyle={{ height: '100%', width: '100%' }}
                    >
                        {
                            Array.isArray(src)
                                ? <>
                                    {
                                        src.map((imageSrc, index) => (
                                            (imageSelected === index || imageSelected === null)
                                            && <ImageWithError
                                                display={isSuperposeCheck ? 'none' : 'block'}
                                                height="100%"
                                                key={imageSrc}
                                                objectFit="contain"
                                                position="absolute"
                                                src={noSrcResize ? imageSrc : resizeImage(imageSrc, { width: 800 })}
                                            />
                                        ))
                                    }
                                </>
                                : <ImageWithError
                                    datasrc={dataSrc}
                                    display={isSuperposeCheck ? 'none' : 'block'}
                                    height="100%"
                                    key={noSrcResize ? src : resizeImage(src, { width: 800 })}
                                    maxW={'100%'}
                                    objectFit="contain"
                                    position="absolute"
                                    src={noSrcResize ? src : resizeImage(src, { width: 800 })}
                                />
                        }
                        {
                            overlay?.map((overlaySrc, overlayIndex) => (
                                (imageSelected === overlayIndex || imageSelected === null)
                                && <ImageWithError
                                    height="100%"
                                    key={overlaySrc}
                                    objectFit="contain"
                                    position="absolute"
                                    src={resizeImage(overlaySrc, { width: 800 })}
                                />
                            ))
                        }

                        {
                            superpose && <>
                                {
                                    Array.isArray(superpose)
                                        ? superpose.map((superposeSrc, index) => (
                                            (imageSelected === index || !imageSelected)
                                            && <ImageWithError
                                                display={isSuperposeCheck ? 'block' : 'none'}
                                                height="100%"
                                                key={superposeSrc}
                                                objectFit="contain"
                                                position="absolute"
                                                src={resizeImage(superposeSrc, { width: 800 })}
                                            />
                                        ))
                                        : <ImageWithError
                                            display={isSuperposeCheck ? 'block' : 'none'}
                                            height="100%"
                                            maxW={'100%'}
                                            objectFit="contain"
                                            position="absolute"
                                            src={resizeImage(superpose, { width: 800 })}
                                        />
                                }
                            </>

                        }

                        {
                            opacitySrc && opacityValue !== undefined && <ImageWithError
                                height="100%"
                                key={resizeImage(opacitySrc, { width: 800 })}
                                maxW={'100%'}
                                objectFit="contain"
                                opacity={opacityValue}
                                position="absolute"
                                src={resizeImage(opacitySrc, { width: 800 })}
                            />
                        }
                    </TransformComponent>
                </TransformWrapper>
                <VStack bottom={0} left={2} position="absolute">
                    <ZoomSlider
                        max={800}
                        min={100}
                        transformComponentRef={transformComponentRef || undefined}
                        updateZoomValues={updateZoomValues}
                        zoomValue={zoomValue}
                    />

                    {
                        superpose && <Button

                            onMouseEnter={handleSuperposeMouseEnter}
                            onMouseLeave={handleSuperposeMouseLeave}
                            variant="link"
                        >
                            {t('superpose')}
                        </Button>
                    }
                </VStack>

            </Box>
            {additionalText
                && <HStack justifyContent={'center'} w="100%">
                    <Text color="black" fontSize={14} noOfLines={1}>{additionalText}</Text>
                </HStack>
            }
        </VStack>

    );
}

import { Center, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { forceCheck } from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import GarmentGridCard from '../../components/cards/GarmentGridCard';
import { useAppSelector } from '../../services/store/store';
import { Garment } from '../../types/api-types';
import { GARMENT_STATUS_KEY, WARP_STATUS_KEY } from '../../utils/constants';

interface GarmentElementProps {
    data: unknown[],
    numberProductPerRow: number,
    imagesRatio: string,
    onScroll(): void,
}

// ---- Component used in the Grid Layout ----
const GarmentGridElement = (props: GarmentElementProps) => {
    const reservedList = useAppSelector((state) => state.session.reservations);

    const { pathname } = useLocation();

    // ---- According to the pathname we have a different status key ----
    const statusKey = useMemo(() => {
        switch (pathname) {
            case '/refwarp':
                return WARP_STATUS_KEY;
            default:
                return GARMENT_STATUS_KEY;
        }
    }, [pathname]);

    // ---- FIX to show lazyloaded image after we filter ----
    useEffect(() => {
        forceCheck();
    }, [props.data]);

    return (<Wrap boxSize="full" onScroll={props.onScroll} overflowY="auto" pb={6} pr={10} spacing={2}>
        {
            (props.data as Garment[]).map((product) => (
                <WrapItem key={product.garment_id} w={`calc(100% / ${props.numberProductPerRow} - 8px)`}>
                    <Center boxSize="full">
                        <GarmentGridCard
                            garment={product}
                            imageRatio={props.imagesRatio}
                            isReserved={!!reservedList.find((reservation) => reservation.garment_id === product.garment_id)}
                            statusKey={statusKey}
                        />
                    </Center>
                </WrapItem>

            ))
        }
    </Wrap>
    );
};

export default GarmentGridElement;

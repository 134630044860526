import { Mutex, MutexInterface } from 'async-mutex';

const mutex = new Mutex();

interface CallbackType {(): Promise<unknown>}

let lastInQueue: CallbackType | null = null;
let currentCallback: CallbackType | null = null;

export default function useAsyncQueue() {
    // ---- This method will execute the lastInQueue until the lastInQueue is equals to the currentCallback ----
    const recursiveExecution = (callback: CallbackType, releaseFunction: MutexInterface.Releaser) => {
        // ---- Exectuing the callback ----
        callback().then(() => {
            // ---- If the lastInQueue is the currentCallback or there is no lastInQueue we stop the recursion and release the mutex ----
            if (lastInQueue === currentCallback || !lastInQueue) {
                lastInQueue = null;
                currentCallback = null;
                releaseFunction();

                return;
            }

            // ---- We assign the currenctCallback to the lastInQueue and call the recursive method ----
            currentCallback = lastInQueue;
            recursiveExecution(lastInQueue, releaseFunction);
        });
    };

    const addToQueue = async (callback: CallbackType) => {
        // ---- We set the callback as lastInQueue ----
        lastInQueue = callback;

        // ---- If mutex is unlocked ----
        if (!mutex.isLocked()) {
            // ---- Mutex lock ----
            mutex.acquire().then((release) => {
                // ---- We assign currentCallback ----
                currentCallback = lastInQueue;
                // ---- We execute the last callback in queue ----
                if (lastInQueue) {
                    recursiveExecution(lastInQueue, release);
                }
            });
        }
    };

    return { addToQueue };
}

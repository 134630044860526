import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GridLayout from '../../components/layouts/GridLayout';
import { useGetExternalsQuery } from '../../services/api/api-external';
import { getClient, getConfig, getExperience } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ErrorMutation, Filters, QueryInput } from '../../types/api-types';
import { ERROR_LOCALES, GARMENTS_LOCALES, WARP_LOCALES } from '../../utils/constants';
import CheckGridElement from '../check/CheckGridElement';

export default function External() {
    const { t } = useTranslation([GARMENTS_LOCALES, ERROR_LOCALES, WARP_LOCALES]);

    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));

    const [queryInputObject, setQueryInputObject] = React.useState<QueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience?.id,
    });

    const { data: externalData, isFetching: isExternalLoading, error: errorExtQuality } = useGetExternalsQuery(
        queryInputObject,
        { refetchOnMountOrArgChange: true, skip: !currentClient },
    );

    function useDataQuery(filters: Filters, query = '', page = 1, collapse = null) {
        if (currentClient) {
            setQueryInputObject({
                clientId: currentClient.id,
                collapse,
                experienceId: selectedExperience?.id,
                filters: Object.keys(filters).length === 0 ? {} : { ...filters },
                page,
                query,
            });
        }
    }

    return (
        <Box h="100%">
            {
                errorExtQuality
                    ? <VStack h="100%" justifyContent="center">
                        <Text>{(errorExtQuality as ErrorMutation)?.data?.message}</Text>
                    </VStack>
                    : <>
                        {
                            externalData?.items.length === 0
                                ? <VStack h="100%" justifyContent="center">
                                    <Text fontSize="60px" fontWeight="bold">{t('no_external_garments_title', { ns: WARP_LOCALES })}</Text>
                                    <Text>{t('no_external_garments', { ns: WARP_LOCALES })}</Text>
                                </VStack>
                                : <GridLayout
                                    FlexWarpComponent={CheckGridElement}
                                    data={externalData}
                                    dataQuery={useDataQuery}
                                    imagesRatio={
                                        config
                                            ? `${(100 / config.look_image_ratio).toString()}%`
                                            : '120%'
                                    }
                                    isLoading={isExternalLoading}
                                    noReservationQuery={true}
                                    title={t('title', { clientName: currentClient?.name })}
                                    type="garment"
                                />
                        }
                    </>

            }

        </Box>
    );
}

import { createStandaloneToast } from '@chakra-ui/react';

import theme from '../theme';

export default function rgbToHex(rgb: number[], defaultValue?: string): string {
    const { toast } = createStandaloneToast({ theme });

    let hasError = false;

    if (rgb.length !== 3) {
        toast({
            isClosable: true,
            status: 'error',
            title: 'Array must contain exactly three numbers.',
        });

        hasError = true;
    }

    const hex = rgb.map((value) => {
        if (value < 0 || value > 255) {
            toast({
                isClosable: true,
                status: 'error',
                title: 'Each RGB value must be between 0 and 255.',
            });

            hasError = true;

            return '';
        }
        const hexString = value.toString(16).padStart(2, '0');

        return hexString;
    }).join('');

    return hasError ? (defaultValue || '#FFFFFF') : `#${hex}`;
}

import { Center, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { forceCheck } from 'react-lazyload';

import CheckFinalCard from '../../components/cards/CheckGarmentCard';
import { Garment } from '../../types/api-types';

interface CheckGridElementProps {
    data: unknown[],
    numberProductPerRow: number,
    imagesRatio: string,
    onScroll(): void,
}

// ---- Component used in the Grid Layout ----
const CheckGridElement = (props: CheckGridElementProps) => {
    // ---- FIX to show lazyloaded image after we filter ----
    useEffect(() => {
        forceCheck();
    }, [props.data]);

    return (<Wrap boxSize="full" onScroll={props.onScroll} overflowY="auto" pb={6} pr={10} spacing={2}>
        {
            (props.data as Garment[]).map((product) => (
                <WrapItem key={product.garment_id} w={`calc(100% / ${props.numberProductPerRow} - 8px)`}>
                    <Center boxSize="full">
                        <CheckFinalCard
                            garment={product}
                            imageRatio={props.imagesRatio}
                        />
                    </Center>
                </WrapItem>

            ))
        }
    </Wrap>
    );
};

export default CheckGridElement;

import qs from 'qs';

import { FilterInContextType } from '../components/filters/filtersTypes';

export const getObjectUrl = (urlParams: string) => qs.parse(urlParams);

const removeQuestionMark = (urlParams: string) => {
    let cloneUrlParams = urlParams;
    if (cloneUrlParams.charAt(0) === '?') {
        cloneUrlParams = urlParams.slice(1);
    }

    return cloneUrlParams;
};

export const addFiltersToUrlParams = (urlParams: string, filters: FilterInContextType[]) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    const newFilterObject = {} as {[key:string]: string | string[]};
    filters.forEach((localFilter) => {
        const urlValue = newFilterObject[localFilter.filterKey];
        let newValue: string | string[];
        if (urlValue) {
            if (Array.isArray(urlValue)) {
                newValue = [...urlValue, localFilter.filterValue];
            } else {
                newValue = [urlValue as string, localFilter.filterValue];
            }
        } else {
            newValue = localFilter.filterValue;
        }
        newFilterObject[localFilter.filterKey] = newValue;
    });

    parsedUrl.filter = newFilterObject;

    return qs.stringify(parsedUrl);
};

export const removeFiltersFromUrlParams = (urlParams: string) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    delete parsedUrl.filter;

    return qs.stringify(parsedUrl);
};

export const getFiltersFromUrlParams = (urlParams: string) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    const filterInContextArray = [] as FilterInContextType[];

    if (parsedUrl.filter) {
        const typedUrlFilter = parsedUrl.filter as {[key:string]: string | string[]};
        Object.keys(typedUrlFilter).forEach((localKey) => {
            if (Array.isArray(typedUrlFilter[localKey])) {
                (typedUrlFilter[localKey] as string[]).forEach((filterValue) => {
                    filterInContextArray.push({ filterKey: localKey, filterValue });
                });
            } else {
                filterInContextArray.push({ filterKey: localKey, filterValue: typedUrlFilter[localKey] as string });
            }
        });
    }

    return filterInContextArray;
};

export const getCleanSearch = (search: string) => {
    const parsedUrl = getObjectUrl(search);

    return qs.stringify({ clientId: parsedUrl.clientId || parsedUrl['?clientId'], mode: parsedUrl.mode || parsedUrl['?mode'] });
};

export const getGarmentDetailUrl = (garmentId: string) => `https://app.veesual.ai/admin/garment/${garmentId}/detail`;
export const getWarpDetailUrl = (warpId: string) => `https://app.veesual.ai/admin/warp/${warpId}/detail`;
export const getModelDetailUrl = (modelId: string) => `https://app.veesual.ai/admin/model/${modelId}/detail`;
export const getNewWarpEditUrl = (garmentId: string) => `https://app.veesual.ai/admin/garment/${garmentId}/warp`;

import { Icon, IconProps } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import { KeypointType } from '../types/api-types';
import { calculateRealPos, getKpColor } from '../utils/warp-edit-helpers';

const KEYPOINT_SIZE = 8; // Size in px

interface KeypointProps {
    currentZoomScale: number
    iconProps: IconProps
    initX: number,
    initY: number,
    onDragStart?(): void,
    onDragEnd?(newPos: KeypointType): void,
    onDoubleClick?(): void,
    imageId: string
    type: string
}

export default function Keypoint(props: KeypointProps) {
    const { iconProps, initX, initY, onDragStart, onDragEnd, onDoubleClick, imageId, type, currentZoomScale } = props;

    const [kpDrag, setKpDrag] = useState<boolean>(false);
    const [kpX, setKpX] = useState<number>(initX);
    const [kpY, setKpY] = useState<number>(initY);
    const [initDragX, setInitDragX] = useState<number>(0);
    const [initDragY, setInitDragY] = useState<number>(0);
    const [isMouseUp, setIsMouseUp] = useState<boolean>(false);

    const color = useMemo(() => getKpColor(type), [type]);

    const scaleValue = useMemo(() => 1 / currentZoomScale, [currentZoomScale]);

    const mouseMoveHandler = (mouseMoveEvent: MouseEvent) => {
        const garmentWarpImageElement = document.getElementById(imageId);
        if (!garmentWarpImageElement) {
            return;
        }

        const rect = garmentWarpImageElement.getBoundingClientRect();
        const { x, y } = calculateRealPos(mouseMoveEvent.clientX, mouseMoveEvent.clientY, rect);

        // ---- Coordinate unvalid ----
        if (x < 0 || x > 100 || y < 0 || y > 100) {
            setKpX(initDragX);
            setKpY(initDragY);
            setKpDrag(false);

            return;
        }

        setKpX(x);
        setKpY(y);
    };

    const mouseDownHandler = () => {
        // Disable Pan
        setKpDrag(true);
        setInitDragX(kpX);
        setInitDragY(kpY);
        if (onDragStart) {
            onDragStart();
        }
    };

    const mouseUpHandler = () => {
        setIsMouseUp(true);
        setKpDrag(false);
    };

    const doubleClickHandler = () => {
        if (onDoubleClick) {
            onDoubleClick();
        }
    };

    /**
     * Set back position from props after we called dragEnd (when isMouseUp goes to false) so if there is an error we go back to the correct pos
     */
    useEffect(() => {
        if (!isMouseUp && (kpX !== initX || kpY !== initY)) {
            setKpX(initX);
            setKpY(initY);
        }
    }, [isMouseUp, initX, initY]);

    useEffect(() => {
        if (kpDrag === true) {
            window.addEventListener('mousemove', mouseMoveHandler);
            window.addEventListener('mouseup', mouseUpHandler);
        } else {
            window.removeEventListener('mousemove', mouseMoveHandler);
            window.removeEventListener('mouseup', mouseUpHandler);

            // ---- Call callback if mouseUP here because State is not updated directly in mouseUp ----
            if (onDragEnd && isMouseUp) {
                setIsMouseUp(false);
                onDragEnd({ type, x: kpX, y: kpY });
            }
        }

        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler);
            window.removeEventListener('mouseup', mouseUpHandler);
        };
    }, [kpDrag]);

    return (
        <>
            {
                kpDrag && <div
                    style={{
                        left: `calc(${initDragX}% - ${KEYPOINT_SIZE / 2}px)`,
                        position: 'absolute',
                        top: `calc(${initDragY}% - ${KEYPOINT_SIZE / 2}px)`,
                    }}
                >
                    <div style={{
                        transform: `translate(0px, 0px) scale(${scaleValue})`,
                    }}>
                        <Icon
                            viewBox='0 0 200 200'
                            {...iconProps}
                            opacity={0.5}
                        >
                            <path
                                d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                                fill='currentColor'
                            />
                        </Icon>
                    </div>
                </div>
            }
            <div style={{ left: `calc(${kpX}% - ${KEYPOINT_SIZE / 2}px)`, position: 'absolute', top: `calc(${kpY}% - ${KEYPOINT_SIZE / 2}px)` }}>
                <div style={{ transform: `translate(0px, 0px) scale(${scaleValue})` }}>
                    <Icon
                        viewBox='0 0 200 200'
                        {...iconProps}
                        color={iconProps.color || color}
                        onDoubleClick={doubleClickHandler}
                        onMouseDown={mouseDownHandler}
                        onMouseMove={(mouseMoveEvent) => {
                            if (kpDrag) {
                                if (iconProps.onMouseMove) {
                                    iconProps.onMouseMove(mouseMoveEvent);
                                }
                            }
                        }}
                    >
                        <path
                            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                            fill='currentColor'
                        />
                    </Icon>
                </div>
            </div>
        </>

    );
}

import React from 'react';

import { Permission } from '../../types/api-types';
import usePermission from './usePermission';

type Props = {
    to: Permission;
    fallback?: Element | string;
    children?: React.ReactNode;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({ to, fallback, children }) => {
    // We "connect" to the provider thanks to the PermissionContext
    const allowed = usePermission(to);

    // If the user has that permission, render the children
    if (allowed) {
        return <>{children}</>;
    }

    // Otherwise, render the fallback
    return <>{fallback}</>;
};

export default Restricted;

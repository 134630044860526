import { HStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import { SUB_HEADER_HEIGHT } from '../../utils/constants';

interface BaseSubHeaderProps {
    children?: ReactElement
}

export default function BaseSubHeader(props: BaseSubHeaderProps) {
    return (
        <HStack backgroundColor="gray.200" h={SUB_HEADER_HEIGHT} w="100%">
            {props.children}
        </HStack>
    );
}

import { useMemo } from 'react';

import { getUser } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Permission } from '../../types/api-types';
import {
    ADMIN, ADMIN_PERMISSION, BACKEND, BACKEND_QUALITY_PERMISSION,
    BACKEND_RWINTQC_PERMISSION,
    BACKEND_WARP_PERMISSION, SUPERADMIN, SUPERADMIN_PERMISSION,
} from '../../utils/constants';

function isAllowedTo(newPermission: Permission, roles?: string[], backends?: string[]): boolean {
    let isAllowed = false;

    if (!roles || roles.length === 0) {
        return false;
    }

    roles.forEach((role) => {
        if (!isAllowed) {
            switch (role) {
                case SUPERADMIN:
                    isAllowed = SUPERADMIN_PERMISSION.includes(newPermission);
                    break;
                case ADMIN:
                    isAllowed = ADMIN_PERMISSION.includes(newPermission);
                    break;
                case BACKEND:
                    if (backends) {
                        if (backends.includes('WARP')) {
                            isAllowed = isAllowed || BACKEND_WARP_PERMISSION.includes(newPermission);
                        }
                        if (backends.includes('QUALITY')) {
                            isAllowed = isAllowed || BACKEND_QUALITY_PERMISSION.includes(newPermission);
                        }
                        if (backends.includes('RWINTQC')) {
                            isAllowed = isAllowed || BACKEND_RWINTQC_PERMISSION.includes(newPermission);
                        }
                    }
                    break;
            }
        }
    });

    return isAllowed;
}

const usePermission = (permission: Permission) => {
    const roles = useAppSelector((state) => getUser(state)?.roles);
    const backends = useAppSelector((state) => getUser(state)?.backend);

    // Memoize the result to prevent unnecessary recalculations
    return useMemo(() => isAllowedTo(permission, roles, backends), [permission, roles, backends]);
};

export default usePermission;

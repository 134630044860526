import { Spinner, StackProps, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import {
    useGetCheckGarmentFeedbacksQuery,
    useGetExternalWarpFeedbacksQuery,
    useGetGarmentFeedbacksQuery,
    useGetWarpFeedbacksQuery,
} from '../services/api/api-feedback';
import { getClient } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { Feedback } from '../types/api-types';
import { COMMON_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';
import handlePaginationScroll from '../utils/scroll-pagination-helpers';
import FeedbackCard from './cards/FeedbackCard';

interface FeedbackCardsListProps {
    containerStyle?: StackProps
    warpId?: string,
    garmentId?: string
}

export default function FeedbackCardsList(props: FeedbackCardsListProps) {
    // ---- Utils Hooks ----
    const { t } = useTranslation(COMMON_LOCALES);
    const { pathname } = useLocation();
    const navigate = useCustomNavigate();

    // ---- Props ----
    const { warpId, garmentId, containerStyle } = props;

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- State ----
    const [fullFeedBacks, setFullFeedBacks] = useState<Feedback[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    // ---- MatchPath vars to know which feedback to use ----
    const useWarpFeedback = matchPath('warp/:warpId/feedbacks', pathname) || matchPath('warp/:warpId/edit', pathname);
    const useGarmentFeedback = matchPath('garment/:garmentId/feedbacks', pathname);
    const useExternalFeedback = matchPath('external_validation/:warpId/feedbacks', pathname);
    const useCheckFeedback = matchPath('check/:garmentId/feedbacks', pathname);
    // ---- API ----
    const { data: warpFeedBacks, isLoading: isWarpFeedbackLoading, isError: isErrorWarpFeedbacks } = useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || !useWarpFeedback },
    );
    const { data: garmentFeedBacks, isLoading: isGarmentFeedbackLoading, isError: isErrorGarmentFeedbacks } = useGetGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId || !useGarmentFeedback },
    );
    const { data: externalFeedbacks, isLoading: isExternalFeedbackLoading, isError: isErrorExternalFeedbacks } = useGetExternalWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId || !useExternalFeedback },
    );
    const { data: checkFeedbacks, isLoading: isCheckFeedbackLoading, isError: isErrorCheckFeedbacks } = useGetCheckGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId || !useCheckFeedback },
    );

    const isAnyLoading = useMemo(() => isWarpFeedbackLoading || isGarmentFeedbackLoading || isExternalFeedbackLoading || isCheckFeedbackLoading,
        [isWarpFeedbackLoading, isGarmentFeedbackLoading, isExternalFeedbackLoading, isCheckFeedbackLoading]);

    const feedBacks = useMemo(() => {
        if (useWarpFeedback) {
            return warpFeedBacks;
        }

        if (useGarmentFeedback) {
            return garmentFeedBacks;
        }

        if (useExternalFeedback) {
            return externalFeedbacks;
        }

        if (useCheckFeedback) {
            return checkFeedbacks;
        }

        return undefined;
    }, [warpFeedBacks, garmentFeedBacks, warpId, garmentId, useWarpFeedback, useGarmentFeedback, useExternalFeedback, useCheckFeedback]);

    // Called when we scroll and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80) {
            return;
        }

        handlePaginationScroll(isAnyLoading, currentPage, () => setCurrentPage(currentPage + 1), feedBacks, fullFeedBacks.length);
    };

    // ---- Handle when the first page is not enough to fill the list of feedbacks ----
    useEffect(() => {
        const feedbackContainer = document.getElementById('feedbackContainer');

        if (!feedBacks || !feedbackContainer) {
            return undefined;
        }

        // ---- If the container is not scrollable we fetch the next page ----
        if (fullFeedBacks.length > 0 && feedbackContainer.scrollHeight <= feedbackContainer.clientHeight) {
            // ---- Check if we still have element to fetch ----
            const apiCurrentPage = parseInt(feedBacks.current_page_number, 10);
            const apiNumitemsPerPage = parseInt(feedBacks.num_items_per_page, 10);
            if ((apiCurrentPage * apiNumitemsPerPage) >= feedBacks.total_count) {
                return undefined;
            }

            setCurrentPage(currentPage + 1);
        }

        return undefined;
    }, [fullFeedBacks]);

    useEffect(() => {
        if (feedBacks) {
            const apiCurrentPage = parseInt(feedBacks.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                setFullFeedBacks((prev) => prev.concat(feedBacks.items));
            } else {
                setCurrentPage(1);
                setFullFeedBacks(feedBacks.items);
            }
        }
    }, [feedBacks]);

    useEffect(() => {
        if (isErrorWarpFeedbacks || isErrorGarmentFeedbacks || isErrorExternalFeedbacks || isErrorCheckFeedbacks) {
            navigate('/');
        }
    }, [isErrorWarpFeedbacks, isErrorGarmentFeedbacks, isErrorExternalFeedbacks, isErrorCheckFeedbacks]);

    return (
        <VStack
            {...containerStyle}
            id="feedbackContainer"
            justifyContent={fullFeedBacks.length === 0 ? 'center' : 'flex-start'}
            onScroll={handleScroll}
            overflowY="auto"
            w="100%"
        >
            {
                isAnyLoading && fullFeedBacks.length === 0 && <Spinner />
            }
            {
                fullFeedBacks.length === 0 && !isAnyLoading
                && <Text>{t('feedback.no_feedback')}</Text>
            }
            <Wrap w="100%">
                {
                    fullFeedBacks.map((feedback) => (
                        <WrapItem key={feedback.id} w="100%">
                            <FeedbackCard feedback={feedback} noActionButton={!matchPath('warp/:warpId/edit', pathname)}/>
                        </WrapItem>
                    ))
                }
            </Wrap>

        </VStack>
    );
}

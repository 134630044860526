import {
    Button,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_LOCALES } from '../../utils/constants';

interface InputModalProps {
    title: string,
    description?: string,
    onValidate(textValue?: string): void,
    onClose(): void,
    isOpen: boolean,
    disableCheckboxLabel?: string,
}

export default function InputModal(props: InputModalProps) {
    const { t } = useTranslation(COMMON_LOCALES);
    const { title, description, onValidate, onClose, isOpen, disableCheckboxLabel } = props;

    const [input, setInput] = useState<string>();
    const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value);

        // ---- If we were invalid and the new value is valid we reset the Invalid flag ----
        if (isInputInvalid && e.target.value !== '') {
            setIsInputInvalid(false);
        }

        // ---- If the value is empty we set the invalid flag ----
        if (e.target.value === '') {
            setIsInputInvalid(true);
        }
    };

    const handleSendClick = () => {
        // ---- We validate if input is valid OR if we use the checkbox ----
        if ((!isInputInvalid && input) || isChecked) {
            // ---- We don't send the input if the checkbox is checked ----
            onValidate(!isChecked ? input : undefined);

            return;
        }

        if (!isInputInvalid) {
            setIsInputInvalid(true);
        }
    };

    // ---- Needed to update the default input value ----
    useEffect(() => {
        if (isOpen === true) {
            setInput(undefined);
        }
    }, [isOpen]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.500" />

            <ModalContent m={8} p={4}>
                <ModalHeader textAlign={'left'}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <VStack alignItems={'flex-start'} spacing={6}>
                        {description && <Text textAlign="start" whiteSpace="pre-line">{description}</Text>}
                        {disableCheckboxLabel
                            && <Checkbox
                                isChecked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                            >
                                {disableCheckboxLabel}
                            </Checkbox>}
                        <Textarea
                            flex={3}
                            isDisabled={isChecked}
                            isInvalid={isInputInvalid}
                            justifyContent="center"
                            minHeight="80px"
                            onChange={handleInputChange}
                            placeholder={t('input_placeholder')}
                            value={input}
                        />
                    </VStack>

                </ModalBody>
                <ModalFooter justifyContent={'flex-end'} minW="auto">
                    <HStack>
                        <Button onClick={onClose} variant='ghost'>{t('close')}</Button>
                        <Button onClick={handleSendClick}>{t('send')}</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

import { KeypointType } from '../types/api-types';

export const calculateRealPos = (x: number, y: number, boundingRect: DOMRect) => {
    // x position within the element.
    const realX = (((x - boundingRect.x) * 100) / boundingRect.width);
    // y position within the element.
    const realY = (((y - boundingRect.y) * 100) / boundingRect.height);

    return { x: realX, y: realY };
};

export const convertApiKeypointsArray = (apiArray: number[][], type: string) => {
    const result: KeypointType[] = [];
    apiArray.forEach((apiKp) => {
        // ---- We verify that it's a list with two elements (x and y) ----
        if (apiKp.length === 2) {
            result.push({ type, x: apiKp[0] * 100, y: apiKp[1] * 100 });
        }
    });

    return result;
};

export const getKpColor = (kpType: string) => {
    switch (kpType) {
        case 'torso':
            return 'red';
        case 'leftArm':
            return 'lime';
        case 'rightArm':
            return 'cyan';
        case 'leftLeg':
            return 'yellow';
        case 'rightLeg':
            return 'purple';
        default:
            return 'red';
    }
};

import { ChevronDownIcon, ChevronRightIcon, DownloadIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Button, HStack, Spinner, Switch, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast, VStack } from '@chakra-ui/react';
import {
    Column,
    createColumnHelper,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import CellValue from '../components/CellValue';
import SearchBar from '../components/SearchBar';
import { useGetDashboardStatesQuery } from '../services/api/api-dashboard';
import { DashboardRowData } from '../types/api-types';
import {
    COMMON_LOCALES,
    CROSSWARP_STATUS_KEY,
    ERROR_LOCALES,
    GARMENT_STATUS_KEY,
    LAYOUT_LOCALES,
    PREFILTERS,
    WARP_STATUS_KEY,
} from '../utils/constants';

const DEFAULT_VISIBILITY = {
    [`state.garment.status.${PREFILTERS.TODO}`]: false,
    [`state.garment.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.garment.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.garment.status.${PREFILTERS.READY}`]: false,
    [`state.garment.status.${PREFILTERS.READY}.percentage`]: true,
    'state.crosswarp.target': false,
    [`state.crosswarp.status.${PREFILTERS.MISSINGOVERRIDE}`]: false,
    [`state.crosswarp.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.crosswarp.status.${PREFILTERS.TOHDQC}`]: false,
    [`state.crosswarp.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.crosswarp.status.${PREFILTERS.READY}`]: false,
    [`state.crosswarp.status.${PREFILTERS.READY}.percentage`]: true,
    'state.finished.READY': false,
    'state.finished.READY.percentage': true,
    'state.finished.pose.BACK': false,
    'state.finished.pose.FRONT': false,
    'state.finished.target': false,
    'state.warp.target': false,
    [`state.warp.status.${PREFILTERS.TODO}`]: false,
    [`state.warp.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.warp.status.${PREFILTERS.TOINTQC}`]: false,
    [`state.warp.status.${PREFILTERS.TOEXTQC}`]: false,
    [`state.warp.status.${PREFILTERS.TOHDQC}`]: false,
    [`state.warp.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.warp.status.${PREFILTERS.READY}`]: false,
    [`state.warp.status.${PREFILTERS.READY}.percentage`]: true,
};

const Home = () => {
    const { t } = useTranslation([LAYOUT_LOCALES]);
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const [showArchive, setShowArchive] = useState<boolean>(false);
    const { data: dashboardState, isFetching: isDashboardLoading } = useGetDashboardStatesQuery(showArchive ? 1 : 0);

    const [search, setSearch] = useState<string>();
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>(DEFAULT_VISIBILITY);

    const dashboardData = useMemo(() => {
        if (!dashboardState) {
            return null;
        }

        const result: DashboardRowData[] = [];
        dashboardState.forEach((respState) => {
            if (respState.states.length > 0) {
                result.push({
                    client: respState.client,
                    state: respState.states[0].state,
                    subRows: respState.states.filter((_, index) => index !== 0).map((state) => ({
                        client: respState.client,
                        experience: state.experience,
                        state: state.state,
                    })),
                });
            }
        });

        return result;
    }, [dashboardState]);

    const getSortIcon = (sortName: SortDirection | false) => {
        if (!sortName) {
            return null;
        }

        switch (sortName) {
            case 'asc':
                return <TriangleUpIcon />;
            case 'desc':
                return <TriangleDownIcon />;
            default:
                return null;
        }
    };

    const handleTableHeaderClick = (headerColumn?: Column<DashboardRowData>) => {
        if (!headerColumn || ['client.name', 'experience.name', 'input'].includes(headerColumn.id)) {
            return;
        }

        if (headerColumn.depth === 0) {
            // ---- Need to clone the state to modify it ----
            const result = JSON.parse(JSON.stringify(visibility));

            // ---- For each subcolumn we hide those that are not ready ----
            headerColumn.columns.forEach((column) => {
                result[column.id] = !result[column.id];
            });
            setVisibility(result);

            return;
        }

        headerColumn.toggleSorting();
    };

    const getStyleBorderStyle = (column: Column<DashboardRowData>): CSSProperties => {
        if (column.depth === 0) {
            return !['client.name', 'experience.name'].includes(column.id) ? { borderRight: '1px solid gray' } : {};
        }
        if (column.id.match('READY') || column.id === 'input.garment') {
            return {
                borderRight: '1px solid gray',
            };
        }

        return {};
    };

    // TODO: Fix the column width issue
    const getCommonPinningStyles = (column: Column<DashboardRowData>): CSSProperties => {
        const isPinned = column.getIsPinned();
        const isLastLeftPinnedColumn = (isPinned === 'left' && column.getIsLastColumn('left'));

        return {
            boxShadow: isLastLeftPinnedColumn
                ? '-4px 0 4px -4px gray inset'
                : undefined,
            left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
            opacity: 1,
            position: isPinned ? 'sticky' : 'relative',
            width: column.getSize(),
            zIndex: isPinned ? 1 : 0,
        };
    };

    const columnHelper = createColumnHelper<DashboardRowData>();

    // ---- Path function to build path for cell click ----
    const baseSearchParams = (clientId: string, experienceId?: string) => `?mode=${searchParams.get('mode')
    }&clientId=${clientId}${(experienceId) ? `&experienceId=${experienceId}` : ''}`;
    const getPPPath = (
        clientId: string,
        experienceId?: string,
        filter?: string|string[],
    ) => {
        if (filter && Array.isArray(filter)) {
            return `/preprocessing${baseSearchParams(clientId, experienceId)}${
                filter.map((one) => `&filter[${GARMENT_STATUS_KEY}][]=${one}`).join('')
            }`;
        }

        return `/preprocessing${baseSearchParams(clientId, experienceId)}${filter ? `&filter[${GARMENT_STATUS_KEY}]=${filter}` : ''}`;
    };
    const getRWPath = (
        clientId: string,
        experienceId?: string,
        filter?: string,
    ) => `/refwarp${baseSearchParams(
        clientId, experienceId,
    )}${filter ? `&filter[${WARP_STATUS_KEY}]=${filter}` : ''}${filter === PREFILTERS.TOQUALITY ? '&filter[garment_allowed|noraw]=true' : ''
    }`;
    const getCWPath = (
        clientId: string,
        experienceId?: string,
        filter?: string,
    ) => `/crosswarp${baseSearchParams(
        clientId, experienceId,
    )}${filter ? `&filter[${CROSSWARP_STATUS_KEY}]=${filter}` : ''}${filter === PREFILTERS.TOQUALITY ? '&filter[garment_allowed|noraw]=true' : ''
    }`;

    const defaultColumns = [
        columnHelper.accessor('client.name', {
            cell: (info) => <HStack
                _hover={info.row.getCanExpand() ? { backgroundColor: 'gray.200', cursor: 'pointer' } : undefined}
                onClick={info.row.getToggleExpandedHandler()} p={2} pl={`${info.row.depth === 0 ? 8 : ((info.row.depth + 1) * 16)}px`} pr={2}>
                {info.row.getCanExpand() && <div>{info.row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}</div>}
                <Text>{info.getValue()}</Text>
            </HStack>,
            enableColumnFilter: true,
            header: ({ table }) => <Text
                onClick={table.getToggleAllRowsExpandedHandler()}
            >
                {t('dashboard.client')}
            </Text>,
            id: 'client.name',
        }),
        columnHelper.accessor((row) => row.experience?.name || t('all_experiences', { ns: COMMON_LOCALES }) as string, {
            cell: (info) => <Text pl={2} pr={2}>{info.getValue()}</Text>,
            enableColumnFilter: true,
            header: () => t('dashboard.experience'),
            id: 'experience.name',
        }),
        // ---- INPUT STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor('state.model.total', {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: () => t('model', { ns: COMMON_LOCALES }),
                    id: 'input.model',
                }),
                columnHelper.accessor('state.garment.total', {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: () => t('garment', { ns: COMMON_LOCALES }),
                    id: 'input.garment',
                }),
            ],
            header: () => t('dashboard.input'),
            id: 'input',
        }),
        // ---- PREPROCESSING STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => (row.state.garment.status[PREFILTERS.TODETAIL] || 0)
                + (row.state.garment.status[PREFILTERS.TOCLIPPING] || 0)
                + (row.state.garment.status[PREFILTERS.TOFOREGROUND] || 0)
                + (row.state.garment.status[PREFILTERS.TOCUT] || 0), {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, [
                            PREFILTERS.TODETAIL, PREFILTERS.TOCLIPPING, PREFILTERS.TOFOREGROUND, PREFILTERS.TOCUT,
                        ])}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_do', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.TODO]}`,
                }),
                columnHelper.accessor((row) => row.state.garment.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.TOQUALITY]}`,
                }),
                columnHelper.accessor((row) => row.state.garment.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.HASFEEDBACK]}`,
                }),
                columnHelper.accessor((row) => (row.state.garment.status[PREFILTERS.READY] || 0)
                    + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                    + (row.state.garment.status[PREFILTERS.TOWARP] || 0), {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.garment.total;

                        return <CellValue
                            containerStyle={{
                                backgroundColor: targetValue === cellValue
                                    && targetValue !== 0
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.pp_ready', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => ((
                    (row.state.garment.status[PREFILTERS.READY] || 0)
                    + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                    + (row.state.garment.status[PREFILTERS.TOWARP] || 0)) * 100) / (row.state.garment.total || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={{
                                backgroundColor: cellValue === 100
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.pp_ready', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.pp_status'),
            id: 'pp_status',
        }),
        // ---- REFWARP STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.warp.target, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={info.getValue()}
                    />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.warp.target',
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TODO] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TODO)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_do', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TODO}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOINTQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOINTQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.int_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOINTQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOEXTQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOEXTQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.ext_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOEXTQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOHDQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOHDQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.hd_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.READY] || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.warp.target;

                        return <CellValue
                            containerStyle={{
                                backgroundColor: targetValue === cellValue
                                    && targetValue !== 0
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.rw_ready', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => ((row.state.warp.status[PREFILTERS.READY] || 0) * 100) / (row.state.warp.target || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={{
                                backgroundColor: cellValue === 100
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.rw_ready', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.rw_status'),
            id: 'rw_status',
        }),
        // ---- CROSSWARP STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.crosswarp.target, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={info.getValue()}
                    />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.crosswarp.target',
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.MISSINGOVERRIDE] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.MISSINGOVERRIDE)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_check', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.MISSINGOVERRIDE}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.TOHDQC] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOHDQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.hd_qc', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.READY] || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.crosswarp.target;

                        return <CellValue
                            containerStyle={{
                                backgroundColor: targetValue === cellValue
                                    && targetValue !== 0
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.cw_ready', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => ((row.state.crosswarp.status[PREFILTERS.READY] || 0) * 100) / (row.state.crosswarp.target || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={{
                                backgroundColor: cellValue === 100
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.cw_ready', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.cw_status'),
            id: 'cw_status',
        }),
        // ---- FINISHED STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.finished.target, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.finished.target',
                }),
                columnHelper.accessor((row) => row.state.finished.pose.FRONT || 0, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('FRONT', { ns: COMMON_LOCALES }),
                    id: 'state.finished.pose.FRONT',
                }),
                columnHelper.accessor((row) => row.state.finished.pose.BACK || 0, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('BACK', { ns: COMMON_LOCALES }),
                    id: 'state.finished.pose.BACK',
                }),
                columnHelper.accessor((row) => row.state.finished.ready || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.finished.target;

                        return <CellValue
                            containerStyle={{
                                backgroundColor: targetValue === cellValue
                                    && targetValue !== 0
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            value={cellValue}
                        />;
                    },
                    header: t('both_finished', { ns: COMMON_LOCALES }),
                    id: 'state.finished.READY',
                }),
                columnHelper.accessor((row) => ((row.state.finished.ready || 0) * 100) / (row.state.finished.target || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={{
                                backgroundColor: cellValue === 100
                                    ? 'lightgreen'
                                    : undefined,
                            }}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('both_finished', { ns: COMMON_LOCALES }),
                    id: 'state.finished.READY.percentage',
                }),
            ],
            header: t('dashboard.finished_status'),
            id: 'finished_status',
        }),
    ];
    const table = useReactTable({
        columns: defaultColumns,
        data: dashboardData || [],
        defaultColumn: {
            minSize: 0,
        },
        enableColumnFilters: false,
        filterFromLeafRows: true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getSubRows: (row) => row.subRows,
        onColumnVisibilityChange: setVisibility,
        onExpandedChange: setExpanded,
        state: {
            columnPinning: { left: ['client.name', 'experience.name'] },
            columnVisibility: visibility,
            expanded,
            globalFilter: search,
        },
    });

    // ---- Download an HTML file that can be imported in Excel or Google Sheet ----
    const handleExport = () => {
        // ---- Toggle Columns and Rows if not all are visible to open them ----
        if (!table.getIsAllColumnsVisible()) {
            table.toggleAllColumnsVisible();
        }
        if (!table.getIsAllRowsExpanded()) {
            table.toggleAllRowsExpanded();
        }

        // ---- We need and interval as it takes time to open all the columns and rows ----
        let intervalTimeout = 0;
        const interval = window.setInterval(() => {
            // ---- If we have not yet everything opened or if we hit the timeout threshold ---
            if ((!table.getIsAllRowsExpanded() || !table.getIsAllColumnsVisible()) && intervalTimeout < 20) {
                intervalTimeout++;

                return;
            }

            clearInterval(interval);

            if (intervalTimeout >= 10) {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t('export_dashboard', { ns: ERROR_LOCALES }),
                });

                return;
            }

            // ---- Get Table HTMLElement ----
            const tableElem = document.getElementById('state-table') as HTMLElement | null;
            if (!tableElem) {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t('export_dashboard', { ns: ERROR_LOCALES }),
                });

                return;
            }

            // ---- Extract HTML content ----
            const tableHtml = tableElem.innerHTML;

            // ---- Create blob used to make a downloadable content ----
            const blob = new Blob([tableHtml], { type: 'text/html' });

            // ---- Create data URL from blob ----
            const url = window.URL.createObjectURL(blob);

            // ---- Need to create this "a" tag to trigger the download ----
            const link = document.createElement('a');
            link.href = url;
            // ---- Var for date in file name ----
            const currentDate = new Date();
            const day = currentDate.getUTCDate().toString().padStart(2, '0');
            const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getUTCFullYear();
            link.download = `IGP_report_${day}-${month}-${year}.html`;
            link.click();

            // ---- Clean ----
            window.URL.revokeObjectURL(url);

            // ---- Reset table ----
            table.setColumnVisibility(DEFAULT_VISIBILITY);
            table.toggleAllRowsExpanded();
        }, 200);
    };

    return (
        <VStack alignItems="flex-start" h="100%" spacing={0}>
            <HStack backgroundColor={'gray.200'} borderBottom="1px solid gray" p={4} w="100%">
                <HStack flex={1} spacing={4}>
                    <HStack>
                        <SearchBar
                            debounce={500}
                            onChange={setSearch}
                            placeholder={`${t('filter', { ns: COMMON_LOCALES })} ${t('client')} & ${t('experience')}`}
                        />
                    </HStack>

                    <HStack>
                        <Text>{t('archived_client', { ns: COMMON_LOCALES })}</Text>
                        <Switch onChange={(e) => setShowArchive(!!e.target.checked)} size="lg" />
                    </HStack>
                </HStack>
                <Button onClick={handleExport} rightIcon={<DownloadIcon />}>{t('export', { ns: COMMON_LOCALES })}</Button>
            </HStack>

            {
                isDashboardLoading
                    ? <HStack alignItems={'center'} h="100%" justifyContent={'center'} w="100%"><Spinner /></HStack>
                    : <TableContainer h="100%" id="state-table" overflowX="scroll" overflowY="unset">
                        <Table size="sm" variant="unstyled">
                            <Thead bgColor="gray.200" position="sticky" top="0px" zIndex={2}>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Th
                                                _hover={
                                                    ['experience.name', 'input'].includes(header.column.id) || header.isPlaceholder
                                                        ? undefined
                                                        : { backgroundColor: 'gray.300', cursor: 'pointer' }}
                                                bgColor='gray.200'
                                                colSpan={header.colSpan}
                                                color={header.depth !== 1 && header.column.id.match('READY') ? 'black' : 'unset'}
                                                id={header.id}
                                                key={header.id}
                                                onClick={() => handleTableHeaderClick(header.column)}
                                                style={{
                                                    ...getCommonPinningStyles(header.column),
                                                    ...getStyleBorderStyle(header.column),
                                                }}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}
                                                {header.depth !== 1 && getSortIcon(header.column.getIsSorted())}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <Tr
                                        _hover={{ backgroundColor: 'gray.100' }}
                                        bgColor="white"
                                        borderBottom={`1px solid ${(row.depth === 0 && !row.getIsExpanded())
                                            || (row.getParentRow()?.subRows.length === row.index + 1)
                                            ? 'gray'
                                            : 'lightgray'
                                        }`}
                                        key={row.id}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <Td
                                                bgColor={['client.name', 'experience.name'].includes(cell.column.id) ? 'inherit' : undefined}
                                                fontWeight={cell.column.id === 'client.name' ? 'bold' : 'normal'}
                                                key={cell.id}
                                                p={0}
                                                style={{
                                                    ...getCommonPinningStyles(cell.column),
                                                    ...getStyleBorderStyle(cell.column),
                                                }}
                                                textAlign={!['client.name', 'experience.name'].includes(cell.column.id) ? 'end' : 'start'}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
            }

        </VStack>
    );
};

export default Home;

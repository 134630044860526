import {
    Button,
    HStack,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CarouselGridElement from '../pages/preprocessing/gridElements/CarouselGridElement';
import OpacityGridElement from '../pages/preprocessing/gridElements/OpacityGridElement';
import SingleImageGridElement from '../pages/preprocessing/gridElements/SingleImageElement';
import { usePutGarmentTransparencyMutation } from '../services/api/api-garment';
import { invalidateWarpQualityAction } from '../services/api/api-warp';
import { getClient } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { Pose, WarpQuality } from '../types/api-types';
import rgbToHex from '../utils/color-helpers';
import { COMMON_LOCALES, WARP_LOCALES } from '../utils/constants';
import InfoWithTooltip from './InfoWithTooltip';
import ValidateModal from './modals/ValidateModal';
import TransparencySlider from './sliders/TransparencySlider';

const MIN = -100;
const MAX = 100;
const DEFAULT_COLOR = '#FFFFFF';
const DEFAULT_VALUE = 0;

interface TransparencyEditionProps {
    warpDetail: WarpQuality
    carouselData?: (string | Pose)[]
}

export default function TransparencyEdition(props: TransparencyEditionProps) {
    // ---- Hooks ----
    const { t } = useTranslation([WARP_LOCALES, COMMON_LOCALES]);
    const toast = useToast();
    const dispatch = useDispatch();

    // ---- Props ----
    const { warpDetail, carouselData } = props;

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- API ----
    const [putTransparency] = usePutGarmentTransparencyMutation();

    // ---- State ----
    const [sliderValue, setSliderValue] = useState<number | string>(
        warpDetail.debugs.transparency.trsp_value
            ? warpDetail.debugs.transparency.trsp_value * -200 + 100
            : DEFAULT_VALUE,
    );
    const [colorValue, setColorValue] = useState<string | undefined>(
        warpDetail.debugs.transparency.trsp_bgcolor
            ? rgbToHex(warpDetail.debugs.transparency.trsp_bgcolor, DEFAULT_COLOR)
            : DEFAULT_COLOR,
    );
    const [sliderError, setSliderError] = useState<boolean>(false);
    const [colorError, setColorError] = useState<boolean>(false);
    const [warpImageUrl, setWarpImageUrl] = useState<string>(warpDetail.debugs.transparent.garment_image_url);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentAction, setCurrentAction] = useState<'save' | 'reset' | undefined>();

    // ---- Functions ----
    const handleSliderOnChange = (newValue: number | string) => {
        setSliderValue(newValue);
        if (newValue < MIN || newValue > MAX || newValue === '') {
            setSliderError(true);

            return;
        }

        setSliderError(false);
    };

    const getTrspValueFromSliderValue = (value: number | string) => (100 - (value as number)) / 200;

    const showToast = (status: 'error' | 'success', message?: string) => {
        toast({
            isClosable: true,
            status,
            title: message,
        });
    };

    const handlePreview = (params?: { reset?: boolean }) => {
        // ---- If we reset we reset the local values + get the original image url ----
        if (params?.reset) {
            setColorValue(DEFAULT_COLOR);
            setSliderValue(DEFAULT_VALUE);
            setColorError(false);
            setSliderError(false);

            const url = new URL(warpImageUrl);
            url.searchParams.delete('trsp_value');
            url.searchParams.delete('trsp_bgcolor');
            setWarpImageUrl(url.toString());

            return;
        }

        // ---- Var used to set a new URL or not ----
        let isAnyError = false;

        // ---- We need a color value ----
        if (!colorValue) {
            setColorError(true);
            isAnyError = true;
        }

        // ---- We need a correct slider value ----
        if (sliderValue === undefined || sliderValue < MIN || sliderValue > MAX) {
            setSliderError(true);
            isAnyError = true;
        }

        if (isAnyError) {
            return;
        }

        // ---- Remove error states ----
        setColorError(false);
        setSliderError(false);

        // ---- Convert the slider value to real value ----
        const trspValue = getTrspValueFromSliderValue(sliderValue);

        // ---- Build and update the image url ----
        const url = new URL(warpImageUrl);
        url.searchParams.set('trsp_value', trspValue.toString());
        url.searchParams.set('trsp_bgcolor', colorValue as string);
        setWarpImageUrl(url.toString());
    };

    const handleSave = () => {
        if (!currentClient || !warpDetail) {
            return;
        }

        handlePreview();
        putTransparency({
            clientId: currentClient.id,
            id: warpDetail.garment_id,
            trsp_bgcolor: colorValue,
            trsp_value: getTrspValueFromSliderValue(sliderValue),
        }).unwrap().then((resp) => {
            // ---- Close Modal ----
            setModalOpen(false);

            if (resp.success) {
                // ---- Need to invalidate because if we change step we need the new value in the warp quality object ----
                dispatch(invalidateWarpQualityAction);

                showToast('success', t('transparency.success_save'));

                return;
            }

            showToast('error', t('transparency.error'));
        }).catch(() => {
            // ---- Close Modal ----
            setModalOpen(false);
            showToast('error', t('transparency.error'));
        });
    };

    const handleResetToDefault = () => {
        if (!currentClient || !warpDetail) {
            return;
        }

        putTransparency({
            clientId: currentClient.id,
            id: warpDetail.garment_id,
            trsp_bgcolor: null,
            trsp_value: null,
        }).unwrap().then((resp) => {
            // ---- Close Modal ----
            setModalOpen(false);
            if (resp.success) {
                // ---- Need to invalidate because if we change step we need the new value in the warp quality object ----
                dispatch(invalidateWarpQualityAction);

                showToast('success', t('transparency.success_reset'));

                // ---- Reset local values + get preview ----
                handlePreview({ reset: true });

                return;
            }

            showToast('error', t('transparency.error'));
        }).catch(() => {
            // ---- Close Modal ----
            setModalOpen(false);
            showToast('error', t('transparency.error'));
        });
    };

    const handleColorOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setColorValue(e.target.value);
        setColorError(false);
    };

    const handleOnModalClose = () => {
        setModalOpen(false);
        setCurrentAction(undefined);
    };

    const handleValidate = useMemo(() => {
        if (!currentAction) {
            return undefined;
        }

        switch (currentAction) {
            case 'reset':
                return handleResetToDefault;
            case 'save':
                return handleSave;
            default:
                return undefined;
        }
    }, [currentAction]);

    const validateLabel = useMemo(() => {
        if (!currentAction) {
            return undefined;
        }

        switch (currentAction) {
            case 'reset':
                return t('reset_default', { ns: COMMON_LOCALES });
            case 'save':
                return t('save', { ns: COMMON_LOCALES });
            default:
                return undefined;
        }
    }, [currentAction]);

    // ---- Use Effects ----
    useEffect(() => {
        if (!warpDetail.debugs.transparency.trsp_value && !warpDetail.debugs.transparency.trsp_bgcolor) {
            return;
        }
        const url = new URL(warpDetail.debugs.transparent.garment_image_url);
        if (warpDetail.debugs.transparency.trsp_value) {
            url.searchParams.set('trsp_value', warpDetail.debugs.transparency.trsp_value.toString());
        }

        if (warpDetail.debugs.transparency.trsp_bgcolor) {
            url.searchParams.set('trsp_bgcolor', rgbToHex(warpDetail.debugs.transparency.trsp_bgcolor, DEFAULT_COLOR));
        }
        setWarpImageUrl(url.toString());
    }, [warpDetail]);

    useEffect(() => {
        if (currentAction) {
            setModalOpen(true);
        }
    }, [currentAction]);

    return (
        <HStack alignItems="center" flex={1} h="100%" justifyContent="center" w="100%" >
            <ValidateModal
                description={t('transparency.validation_description')}
                modalOpen={modalOpen}
                onClose={handleOnModalClose}
                onValidate={handleValidate}
                validateLabel={validateLabel}
            />
            <VStack flex={1} h="100%">
                {
                    carouselData
                        ? <CarouselGridElement
                            imagesData={[warpDetail.image_url, ...carouselData]}
                            label={t('comparison_images.title', { ns: COMMON_LOCALES })}
                            superpose={[warpDetail.debugs.transparent.model_image_url, warpImageUrl]}
                        />
                        : <SingleImageGridElement
                            imageSrc={[warpDetail.debugs.transparent.model_image_url, warpDetail.debugs.transparent.garment_image_url]}
                            label={t('original_warp')}
                            superpose={[warpDetail.debugs.transparent.model_image_url, warpImageUrl]}
                        />
                }
            </VStack>
            <VStack flex={1} h="100%">
                <OpacityGridElement
                    defaultOpacity={1}
                    imageSrc={warpDetail.debugs.transparent.model_image_url}
                    opacitySrc={warpImageUrl}
                />
            </VStack>

            <VStack flex={1} h="100%" justifyContent="center" p={4} spacing={4}>
                <Text fontWeight="bold">{t('transparency.settings')}</Text>
                <VStack alignItems="flex-start" pr={8} w="100%">
                    <HStack>
                        <Text>{t('transparency.mask_boost')} % </Text>
                        <InfoWithTooltip tooltipLabel={t('transparency.mask_boost_tooltip')} />
                        <div>:</div>
                    </HStack>

                    <HStack border={sliderError ? '2px solid' : undefined} borderColor="red.500" borderRadius={8} p={2} w="100%">
                        <NumberInput maxW='100px' mr={2} onChange={handleSliderOnChange} step={1} value={sliderValue}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <TransparencySlider
                            focusThumbOnChange={false}
                            max={MAX}
                            min={MIN}
                            onChange={handleSliderOnChange}
                            step={1}
                            value={sliderValue as number}
                            w="100%"
                        />
                    </HStack>
                </VStack>
                <VStack alignItems="flex-start" w="100%">
                    <Text>{t('transparency.bgcolor')}: {colorValue}</Text>
                    <Input cursor="pointer" isInvalid={colorError} onChange={handleColorOnChange} type="color" value={colorValue} />
                </VStack>

                <HStack w="100%">
                    <Button onClick={() => handlePreview()} variant="outline" w="100%">{t('preview', { ns: COMMON_LOCALES })}</Button>
                    <Button onClick={() => setCurrentAction('save')} w="100%">{t('save', { ns: COMMON_LOCALES })}</Button>
                    <Button onClick={() => setCurrentAction('reset')} variant="red" w="100%">{t('reset_default', { ns: COMMON_LOCALES })}</Button>
                </HStack>
            </VStack>
        </HStack>
    );
}

import { useEffect, useMemo, useState } from 'react';

export default function useHorizontalScroll(scrollElement: HTMLElement | null) {
    const [refScrollLeft, setRefScrollLeft] = useState<number>(0);
    const [refClientWidth, setRefClientWidth] = useState<number>(0);
    const [refScrollWidth, setRefScrollWidth] = useState<number>(0);

    const isScrollableLeft = useMemo(() => refScrollLeft !== 0, [refScrollLeft]);
    const isScrollableRight = useMemo(() => refScrollLeft + refClientWidth < refScrollWidth, [refScrollLeft, refClientWidth, refScrollWidth]);

    // ---- Store the different content width values in state so it update the scrollable check ----
    const updateContainerValues = (content: HTMLElement) => {
        setRefScrollLeft(Math.ceil(content.scrollLeft));
        setRefScrollWidth(content.scrollWidth);
        setRefClientWidth(content.clientWidth);
    };

    const scrollRight = () => {
        scrollElement?.scrollTo({
            behavior: 'smooth',
            left:
                refScrollLeft > refScrollWidth - refClientWidth
                    ? refScrollWidth
                    : refScrollLeft + refClientWidth,
        });
    };

    const scrollLeft = () => {
        scrollElement?.scrollTo({
            behavior: 'smooth',
            left:
                refScrollLeft < scrollElement.clientWidth
                    ? 0
                    : refScrollLeft - scrollElement.clientWidth,
        });
    };

    const handleScroll = (e: Event) => {
        updateContainerValues(e.currentTarget as HTMLElement);
    };

    const manualUpdateContainerValues = () => {
        if (!scrollElement) {
            return;
        }

        updateContainerValues(scrollElement);
    };

    useEffect(() => {
        if (!scrollElement) {
            return undefined;
        }

        updateContainerValues(scrollElement);

        // ---- Handle Resize of the scroll element ----
        function handleResize() {
            if (scrollElement) {
                updateContainerValues(scrollElement);
            }
        }

        // ---- Plug resizeObserver on scroll Element ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(scrollElement);

        // ---- Add on scroll event ----
        scrollElement.addEventListener('scroll', handleScroll);

        // ---- Clean up ----
        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
            resizeObserver.disconnect();
        };
    }, [scrollElement]);

    return {
        isScrollableLeft,
        isScrollableRight,
        manualUpdateContainerValues,
        scrollLeft,
        scrollRight,
    };
}

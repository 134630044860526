import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { FaArrowsAlt, FaEraser, FaPencilAlt } from 'react-icons/fa';

import { FeedbackImage, FeedbackImageObject } from '../types/api-types';
import { COMMON_LOCALES } from '../utils/constants';
import useHorizontalScroll from '../utils/hoziontal-scroll-hook';
import FeedbackKonvaStage from './FeedbackKonvaStage';

const defaultColor = '#EB144C';

interface CustomCanvasDrawProps {
    imageSrc?: string | FeedbackImage[],
    isError?: boolean
}

/**
 * Custom Component where we use an imageSrc in a canvas to draw on it
 * @param props contains imageSrc string
 */
const CustomCanvasDraw = React.forwardRef<{ getAllImageObjects:() => Promise<(FeedbackImageObject | null)[]> }, CustomCanvasDrawProps>((
    props, ref,
) => {
    const { t } = useTranslation([COMMON_LOCALES]);

    const [currentColor, setCurrentColor] = useState<string>(defaultColor);
    const [tool, setTool] = useState('pen');
    const [hasDrawnOne, setHadDrawnOne] = useState<boolean>(false);

    const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

    const konvaStageRefs = useRef<({ getImageObject: () => Promise<FeedbackImageObject | null> } | null)[]>([]);

    const { isScrollableLeft, scrollRight, scrollLeft, isScrollableRight, manualUpdateContainerValues } = useHorizontalScroll(containerElement);

    const { imageSrc, isError } = props;

    React.useImperativeHandle(
        ref,
        () => ({
            async getAllImageObjects() {
                // ---- Push all promises to get the ImageObject inside an array ----
                const promiseArray: Promise<FeedbackImageObject | null>[] = [];
                konvaStageRefs.current.forEach((localRef) => {
                    // ---- When we remove an active canva it remains as null in the ref list ----
                    if (!localRef) {
                        return;
                    }

                    promiseArray.push(
                        new Promise((resolve) => {
                            localRef?.getImageObject().then((imageObject) => {
                                resolve(imageObject);
                            });
                        }),
                    );
                });

                return Promise.all(promiseArray);
            },
        }),
    );

    useEffect(() => {
        manualUpdateContainerValues();
    }, [imageSrc]);

    return (
        <HStack
            spacing={0}
            style={{
                height: 'calc(100% - 32px)',
                margin: 16,
                width: 'calc(100% - 32px)',
            }}
        >

            <VStack h="100%" justify="flex-start" pr={2} w="150px">
                <HStack>
                    <IconButton
                        aria-label='pen-button'
                        icon={<Icon as={FaPencilAlt} />}
                        isActive={tool === 'pen'}
                        onClick={() => setTool('pen')}
                        variant="tab-like"
                    />
                    <IconButton
                        aria-label='eraser-button'
                        icon={<Icon as={FaEraser} />}
                        isActive={tool === 'eraser'}
                        onClick={() => setTool('eraser')}
                        variant="tab-like"
                    />
                    <IconButton
                        aria-label='move-button'
                        icon={<Icon as={FaArrowsAlt} />}
                        isActive={tool === 'move'}
                        onClick={() => setTool('move')}
                        variant="tab-like"
                    />
                </HStack>
                {tool === 'pen' && <Text fontSize={10} textAlign="left">{t('feedback.canvas_draw.pen_help')}</Text>}

                <TwitterPicker
                    color={currentColor}
                    colors={['#EB144C', '#00D084', '#0693E3', '#521AD5']}
                    onChange={(colorResult) => setCurrentColor(colorResult.hex)}
                    styles={{ default: { card: { border: 'none', borderRadius: 0, boxShadow: 'none', width: 'auto' } } }}
                    triangle="hide"
                />
                <VStack>
                    <Text>{t('scroll', { ns: COMMON_LOCALES })}</Text>
                    <HStack>

                        <IconButton
                            aria-label='scroll-left'
                            icon={<ArrowLeftIcon />}
                            isDisabled={!isScrollableLeft}
                            onClick={scrollLeft}
                            zIndex={2}
                        />
                        <IconButton
                            aria-label='scroll-left'
                            icon={<ArrowRightIcon />}
                            isDisabled={!isScrollableRight}
                            onClick={scrollRight}
                        />
                    </HStack>
                </VStack>

            </VStack>
            <HStack
                h="100%"
                maxW="100%"
                overflowX="auto"
                position="relative"
                ref={(newRef) => setContainerElement(newRef)}
                w="100%"
            >

                {
                    imageSrc && <>
                        {
                            typeof imageSrc !== 'string'
                                ? <>
                                    {
                                        imageSrc.length === 0
                                            ? <Text w="100%">{t('feedback.select_image', { ns: COMMON_LOCALES })}</Text>
                                            : imageSrc.map((image, i) => <VStack
                                                flex={1}
                                                h="calc(100% - 16px)"
                                                key={image.src.toString()}
                                                minW="30%"
                                            >
                                                <Text>{image.label}</Text>
                                                <FeedbackKonvaStage
                                                    currentColor={currentColor}
                                                    imageSrc={image}
                                                    isError={isError}
                                                    noOverlay={hasDrawnOne}
                                                    onDraw={() => { setHadDrawnOne(true); }}
                                                    ref={(newRef) => { konvaStageRefs.current[i] = newRef; }}
                                                    tool={tool}
                                                />
                                            </VStack>)
                                    }
                                </>
                                : <FeedbackKonvaStage
                                    currentColor={currentColor}
                                    imageSrc={imageSrc}
                                    isError={isError}
                                    ref={(newRef) => { konvaStageRefs.current[0] = newRef; }}
                                    tool={tool}
                                />
                        }
                    </>
                }

            </HStack>
        </HStack>
    );
});

export default CustomCanvasDraw;

import { Center, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { forceCheck } from 'react-lazyload';

import CrosswarpGridCard from '../../components/cards/CrosswarpGridCard';
import { useAppSelector } from '../../services/store/store';
import { Crosswarp } from '../../types/api-types';
import { CROSSWARP_STATUS_KEY } from '../../utils/constants';

interface CrosswarpGridElementProps {
    data: Crosswarp[],
    numberProductPerRow: number,
    imagesRatio: string,
    onScroll(): void,
}

// ---- Component used in the Grid Layout ----
const CrosswarpGridElement = (props: CrosswarpGridElementProps) => {
    const reservedList = useAppSelector((state) => state.session.reservations);

    // ---- FIX to show lazyloaded image after we filter ----
    useEffect(() => {
        forceCheck();
    }, [props.data]);

    return (<Wrap boxSize="full" onScroll={props.onScroll} overflowY="auto" pb={6} pr={10} spacing={2}>
        {
            (props.data).map((product, index) => (
                <WrapItem
                    key={product.crosswarp_id || index}
                    w={`calc(100% / ${props.numberProductPerRow} - 8px)`}
                >
                    <Center boxSize="full">
                        <CrosswarpGridCard
                            crosswarp={product}
                            imageRatio={props.imagesRatio}
                            isReserved={!!reservedList.find((reservation) => (
                                reservation.garment_id === product.garment_id
                                && reservation.model_id === product.model_id
                            ))}
                            statusKey={CROSSWARP_STATUS_KEY}
                        />
                    </Center>
                </WrapItem>

            ))
        }
    </Wrap>
    );
};

export default CrosswarpGridElement;

import {
    Box,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import RefWarpSubHeader from '../../components/headers/RefWarpSubHeader';
import GarmentsGrid from '../garments/GarmentsGrid';

export default function RefWarp() {
    const [searchParams] = useSearchParams();

    return (
        <Box height='100%'>
            {
                searchParams.get('mode') === 'garment' && <GarmentsGrid SubHeaderContent={RefWarpSubHeader}/>
            }

            {
                searchParams.get('mode') === 'model' && <>
                    <Text>Nothing yet</Text>
                </>
            }

        </Box>
    );
}

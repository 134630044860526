import { createContext, useContext } from 'react';

export interface ExistingCrossWarp {'warp_id': string}
export interface NewCrossWarp {'garment_id': string, 'model_id': string}

export interface CrossWarpsContextType {
    deselectACrossWarp: (crosswarp: (ExistingCrossWarp | NewCrossWarp)) => void;
    selectACrossWarp: (crosswarp: (ExistingCrossWarp | NewCrossWarp)) => void;
    selectedCrossWarps: (ExistingCrossWarp | NewCrossWarp)[];
    changeCheckAll: (newValue: boolean) => void;
}

export function isExistingCrossWarp(crosswarp: ExistingCrossWarp | NewCrossWarp): crosswarp is ExistingCrossWarp {
    return (crosswarp as ExistingCrossWarp).warp_id !== undefined;
}

const CrossWarpsContext = createContext<CrossWarpsContextType | null>(null);

export const useCrossWarpsContext = () => useContext(CrossWarpsContext);

export const isCrossWarpsEqual = (first: (ExistingCrossWarp | NewCrossWarp), second: (ExistingCrossWarp | NewCrossWarp)) => {
    if (isExistingCrossWarp(first) && isExistingCrossWarp(second)) {
        return first.warp_id === second.warp_id;
    }

    if (!isExistingCrossWarp(first) && !isExistingCrossWarp(second)) {
        return (first.garment_id === second.garment_id && first.model_id === second.model_id);
    }

    return false;
};

export default CrossWarpsContext;

import { createContext, useContext } from 'react';

export interface SuperposeAnimationContextType {
    animate: boolean,
    setAnimate?(value: boolean): void,
    imagesPreloadedList?: string[]
}

const SuperposeAnimationContext = createContext<SuperposeAnimationContextType | null>(null);

export const useSuperposeAnimationContext = () => useContext(SuperposeAnimationContext);

export default SuperposeAnimationContext;

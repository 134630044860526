import { ArrowLeftIcon, ArrowRightIcon, NotAllowedIcon, ViewIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, HStack, IconButton, Image, Modal, ModalContent, ModalOverlay, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GridItemImage from '../../pages/preprocessing/GridItemImage';
import { invalidateFeedbackAction, usePutFeedbackStatusMutation } from '../../services/api/api-feedback';
import { invalidateWarpQualityAction } from '../../services/api/api-warp';
import { getUser } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Feedback } from '../../types/api-types';
import { COMMON_LOCALES } from '../../utils/constants';
import useFeedbacks from '../../utils/feedback-hook';
import useHorizontalScroll from '../../utils/hoziontal-scroll-hook';
import useRoles from '../../utils/roles-hook';
import InfoWithTooltip from '../InfoWithTooltip';

interface FeedbackCardProps {
    feedback: Feedback,
    noActionButton?: boolean
}

export default function FeedbackCard(props: FeedbackCardProps) {
    const toast = useToast();
    const { t } = useTranslation(COMMON_LOCALES);
    const { isNoRole, isAdmin } = useRoles();
    const { getFirstNewImageFeedback } = useFeedbacks();
    const dispatch = useDispatch();

    const { feedback, noActionButton } = props;

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [localFeedback, setLocalFeedback] = useState<Feedback>(feedback);
    const [isImageHover, setIsImageHover] = useState<boolean>(false);
    const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

    const { isScrollableLeft, scrollRight, scrollLeft, isScrollableRight } = useHorizontalScroll(containerElement);

    const user = useAppSelector((state) => getUser(state));

    const [putFeedbackStatus] = usePutFeedbackStatusMutation();

    const statusColor = useMemo(() => {
        switch (localFeedback.status) {
            case 'PENDING':
                return 'orange';
            case 'CANCELED':
                return 'red';
            case 'DONE':
                return 'green';
            default:
                return 'black';
        }
    }, [localFeedback]);

    const firstImageWithAnnotation = useMemo(() => {
        if (!feedback.images) {
            return null;
        }

        return getFirstNewImageFeedback(feedback.images);
    }, [feedback]);

    const handlePostStatus = (newStatus: string) => {
        putFeedbackStatus({ feedbackId: feedback.id, status: newStatus }).unwrap().then((resp) => {
            if (resp.success && resp.feedback_status) {
                setLocalFeedback({ ...localFeedback, status: resp.feedback_status });

                // ---- Invalidate feedbacks when we change the status ----
                dispatch(invalidateFeedbackAction);

                // ---- Invalidate warpquality so we have the new status in the QC ----
                dispatch(invalidateWarpQualityAction);

                return;
            }

            if (resp.errors) {
                Object.keys(resp.errors).forEach((errorKey) => {
                    toast({
                        description: resp.errors && resp.errors[errorKey].toString(),
                        isClosable: true,
                        status: 'error',
                        title: errorKey,
                    });
                });
            }
        });
    };

    const handleImageClick = () => {
        setModalVisible(true);
    };

    const handleDoneClick = () => {
        handlePostStatus('DONE');
    };

    const handleCancelClick = () => {
        handlePostStatus('CANCELED');
    };

    const handleActivateClick = () => {
        handlePostStatus('PENDING');
    };

    const feedbackDate = new Date(feedback.created_at);

    return (
        <VStack
            backgroundColor="white"
            h="150px"
            justifyContent="flex-start"
            p={2}
            spacing={1}
            w="100%"
        >
            <Modal
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                variant="imagePreview"
            >
                <ModalOverlay bg="blackAlpha.500" />

                <ModalContent h="calc(100% - 4rem)" justifyContent='flex-start' m={8} p={4} position="relative">
                    <Text fontSize="md" fontWeight="bold">{t('description')}:</Text>
                    <Text mb={4}>{feedback.content}</Text>
                    <HStack
                        h="100%"
                        maxW="100%"
                        overflowX="auto"
                        ref={(newRef) => setContainerElement(newRef)}
                        w="100%"
                    >
                        {
                            isScrollableLeft
                            && <IconButton
                                aria-label='scroll-left'
                                icon={<ArrowLeftIcon />}
                                left={2}
                                onClick={scrollLeft}
                                position="absolute"
                                zIndex={2}
                            />
                        }

                        {
                            isScrollableRight && <IconButton
                                aria-label='scroll-left'
                                icon={<ArrowRightIcon />}
                                onClick={scrollRight}
                                position="absolute"
                                right={2}
                                zIndex={2}
                            />
                        }

                        {
                            feedback.images
                        && feedback.images.map((imgSrc, index) => (typeof imgSrc === 'string'
                            ? <VStack h="100%" key={imgSrc} minW="33%" w="100%">
                                <Text>{t('screenshot')} {index + 1}</Text>
                                <GridItemImage noSrcResize={true} src={imgSrc} />
                                <Divider />
                            </VStack>
                            : <VStack h="100%" key={index} minW="33%" w="100%">
                                <Text>{t('screenshot')} {index + 1}</Text>
                                <GridItemImage
                                    imageSelected={null}
                                    noSrcResize={true}
                                    overlay={imgSrc.annotation ? [imgSrc.annotation] : undefined}
                                    src={imgSrc.image}
                                />
                                <Divider />
                            </VStack>))
                        }
                    </HStack>
                </ModalContent>
            </Modal>
            <HStack flexWrap="wrap" fontSize={12} h="15%" justifyContent="space-between" spacing={0} w="100%">
                <Text w="33%">{feedbackDate.toDateString()}</Text>
                {feedback.updated_by
                    && <HStack w="33%">
                        <Text fontWeight="bold">{t('feedback.updated_by')}: </Text>
                        <Text color="black">{feedback.updated_by}</Text>
                    </HStack>
                }
                <HStack w="33%">
                    <Text fontWeight="bold">{t('feedback.created_by')}: </Text>
                    <Text color="black">{feedback.created_by}</Text>
                </HStack>
            </HStack>
            <HStack alignItems="flex-start" h="70%" justifyContent="center" w="100%">
                <HStack alignItems="flex-start" flex={4} h="100%" pt={2}>
                    <Text fontWeight="bold">{t('prefilters.feedback')}: </Text>
                    <Text h="100%" overflowY="auto">{feedback.content}</Text>
                </HStack>

                <VStack flex={1} h="100%" justifyContent="center" position="relative">
                    {
                        feedback.images && feedback.images.length > 0
                            ? <>
                                {
                                    firstImageWithAnnotation
                                        ? <VStack h="100%" position="relative" w="100%">
                                            <Image
                                                cursor="pointer"
                                                h="100%"
                                                objectFit="contain"
                                                position="absolute"
                                                src={firstImageWithAnnotation.image}
                                            />
                                            {
                                                firstImageWithAnnotation.annotation && <Image
                                                    cursor="pointer"
                                                    h="100%"
                                                    objectFit="contain"
                                                    position="absolute"
                                                    src={firstImageWithAnnotation.annotation}
                                                />
                                            }
                                        </VStack>
                                        : <>
                                            {
                                                typeof feedback.images[0] === 'string'
                                                && <Image cursor="pointer" h="100%" objectFit="contain" src={feedback.images[0]} />
                                            }
                                        </>

                                }
                                <Box
                                    _hover={{ bg: '#00000050' }}
                                    alignItems="center"
                                    display="flex"
                                    h="100%"
                                    justifyContent="center"
                                    onClick={handleImageClick}
                                    onPointerEnter={() => setIsImageHover(true)}
                                    onPointerLeave={() => setIsImageHover(false)}
                                    opacity={1}
                                    position="absolute"
                                    top={0}
                                    transition="background 0.1s ease-in-out"
                                    w="100%"
                                >
                                    {
                                        isImageHover
                                            ? <ViewIcon color="black" h="25px" w="25px" />
                                            : <Text color="black" fontSize={40}>
                                                {feedback.images.length > 1 ? `${feedback.images.length}+` : ''}
                                            </Text>
                                    }

                                </Box>
                            </>

                            : <NotAllowedIcon />
                    }

                </VStack>
            </HStack>
            <HStack alignItems="flex-end" h="15%" justifyContent="flex-end" spacing={4} w="100%">
                {
                    ((!isNoRole) || isAdmin)
                    && <>
                        {
                            localFeedback.status === 'PENDING'
                                ? <>
                                    {
                                        (!noActionButton || isAdmin)
                                            ? <Button onClick={handleDoneClick} variant="link">{t('feedback.mark_as_done')}</Button>
                                            : <InfoWithTooltip tooltipLabel={t('feedback_validation_info')} />
                                    }
                                    {(feedback.created_by_id === user?.id || isAdmin)
                                        && <Button onClick={handleCancelClick} variant="link">{t('cancel')}</Button>}
                                </>
                                : <>
                                    {(feedback.created_by_id === user?.id || isAdmin)
                                        && <Button onClick={handleActivateClick} variant="link">{t('feedback.reactivate')}</Button>}
                                </>
                        }
                    </>
                }
                <Text color={statusColor} fontWeight="bold">{localFeedback.status}</Text>
            </HStack>
        </VStack>
    );
}

import { Box, Button, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { Model } from '../../types/api-types';
import { CHECKFINAL_LOCALES, CROSSWARP_LOCALES } from '../../utils/constants';
import resizeImage from '../../utils/image';
import ImageWithError from '../ImageWithError';
import ModelIdentityBanner from '../ModelIdentityBanner';

interface CheckFinalCardProps {
    model: Model
    imageRatio: string
    imageIndex?: number
    onFeedbackClick(): void
}

export default function CheckFinalCard(props: CheckFinalCardProps) {
    const { t } = useTranslation([CROSSWARP_LOCALES, CHECKFINAL_LOCALES]);

    const { model, imageRatio, imageIndex, onFeedbackClick } = props;

    // ---- This is used to change the font size according to the card width ----
    const ref = useRef<HTMLDivElement>(null);
    const [cardWidth, setCardWidth] = useState<number>(0);

    const fontSize = useMemo(() => {
        if (cardWidth < 230) { return 10; }

        return 'sm';
    }, [cardWidth]);

    useEffect(() => {
        function handleResize() {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setCardWidth(rect.width);
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [ref.current?.getBoundingClientRect()]);

    return (
        <VStack
            backgroundColor="gray.100"
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            ref={ref}
            w="100%"
        >
            <Box
                borderRadius="lg"
                h={0}
                overflow="hidden"
                paddingBottom={imageRatio}
                position="relative"
                w="100%"
            >
                <LazyLoad height="100%" offset={100} once overflow>
                    <ImageWithError
                        fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                        height="100%"
                        objectFit={'contain'}
                        position="absolute"
                        src={resizeImage(model.image_urls[imageIndex || 0], { width: 800 })}
                        width="100%"
                    />
                </LazyLoad>
            </Box>
            <VStack justifyContent="space-between" spacing={2} w="100%">
                <Text fontWeight="bold">{model.model_identity.display || model.model_identity.name}</Text>
                <ModelIdentityBanner
                    modelHeight={model.model_identity.height}
                    modelSizes={[model.model_identity.garment_size, model.model_identity.garment_size2]}
                />
                <HStack flex={1} justifyContent="space-evenly" minW={90} spacing={4} w="100%">
                    <Button flex={1} fontSize={fontSize} onClick={onFeedbackClick} pb={1} pt={1} variant="outline">
                        {t('report', { ns: CHECKFINAL_LOCALES })}
                    </Button>
                </HStack>
            </VStack>
        </VStack>
    );
}

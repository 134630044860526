import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import RoundButton from '../../../components/buttons/RoundButton';
import InfoWithTooltip from '../../../components/InfoWithTooltip';
import ModelIdentityBanner from '../../../components/ModelIdentityBanner';
import { ModelIdentity, WarpQuality } from '../../../types/api-types';
import { WARP_LOCALES } from '../../../utils/constants';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import { getCleanSearch } from '../../../utils/url-helpers';
import GridItemImage from '../GridItemImage';

interface WarpDetailGridElementProps {
    label?: string
    info?: string
    imageSrc: string | string[]
    garmentDetail?: { productName?: string, productCategory?: string, description?: string, imageLabel?: string }
    warpDetail?: WarpQuality,
    noExtra?: boolean
    bottomElement?: React.ReactNode
    modelIdentity?: ModelIdentity
}

export default function WarpDetailGridElement(props: WarpDetailGridElementProps) {
    const { t } = useTranslation(WARP_LOCALES);
    const { search } = useLocation();

    const { label, info, imageSrc, garmentDetail, warpDetail, noExtra, bottomElement, modelIdentity } = props;

    useFeedbackContextHelper({ imageSrc, label });

    return (
        <VStack h="100%" overflowY="auto" pb="8px" w="100%">
            {
                label && <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                    <HStack justifyContent="center">
                        <Text fontWeight="bold">{label}</Text>
                        {info && <InfoWithTooltip tooltipLabel={info} />}
                    </HStack>
                </HStack>
            }

            <VStack
                justifyContent="center"
                minHeight="50%"
                spacing={0}
                w="100%"
            >
                <GridItemImage altZoom={!!(warpDetail && !noExtra)} src={imageSrc} />
            </VStack>
            {modelIdentity && <ModelIdentityBanner
                modelHeight={modelIdentity.height}
                modelSizes={[modelIdentity.garment_size, modelIdentity.garment_size2]}
            />}
            {
                garmentDetail && <VStack p={2} wordBreak="break-word">
                    {
                        garmentDetail.imageLabel && <Text>{garmentDetail.imageLabel}</Text>
                    }

                    {
                        garmentDetail.productName && <>
                            <Text fontWeight="bold">{t('product_name')}</Text>
                            <Text>{garmentDetail.productName}</Text>
                        </>
                    }

                    {
                        garmentDetail.productCategory && <>
                            <Text fontWeight="bold">{t('product_category')}</Text>
                            <Text>{garmentDetail.productCategory}</Text>
                        </>
                    }

                    {
                        garmentDetail.description && <>
                            <Text fontWeight="bold">{t('product_description')}</Text>
                            <Text>{garmentDetail.description}</Text>
                        </>
                    }
                </VStack>
            }

            {
                (warpDetail && !noExtra) && <>
                    {
                        warpDetail.variants
                        && <>
                            <VStack p={2}>
                                <Text fontWeight="bold">{t('variants')}</Text>
                            </VStack>
                            {
                                warpDetail.variants.map((variant) => (
                                    <VStack
                                        justifyContent="center"
                                        key={variant.warp_id}
                                        minHeight="50%"
                                        position="relative"
                                        spacing={0}
                                        w="100%"
                                    >
                                        <VStack position="absolute" right="8px" top="8px" zIndex={1}>
                                            <RoundButton
                                                href={`/refwarp/${variant.warp_id}/quality?${getCleanSearch(search)}`}
                                                secondLine="QC"
                                                text="RW"
                                            />
                                        </VStack>

                                        <GridItemImage altZoom={!!warpDetail} src={variant.image_url} />
                                    </VStack>

                                ))
                            }
                        </>
                    }
                    {
                        warpDetail.poses
                        && <>
                            <VStack p={2}>
                                <Text fontWeight="bold">{t('poses')}</Text>
                            </VStack>
                            {
                                warpDetail.poses.map((pose) => (
                                    <VStack
                                        justifyContent="center"
                                        key={pose.warp_id}
                                        minHeight="50%"
                                        position="relative"
                                        spacing={0}
                                        w="100%"
                                    >
                                        <VStack position="absolute" right="8px" top="8px" zIndex={1}>
                                            <RoundButton
                                                href={`/refwarp/${pose.warp_id}/quality?${getCleanSearch(search)}`}
                                                secondLine="QC"
                                                text="RW"
                                            />
                                        </VStack>
                                        <GridItemImage altZoom={!!warpDetail} src={pose.image_url} />
                                    </VStack>

                                ))
                            }
                        </>
                    }
                </>
            }
            {
                bottomElement
            }
        </VStack>
    );
}

import { Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack } from '@chakra-ui/react';
import React from 'react';

export default function BaseSlider(props: SliderProps) {
    return (
        <Slider
            {...props}
            m={0}
            maxW={'120px'}
            minW={'80px'}
            variant="gray"
        >
            <SliderTrack h={0.5}>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
        </Slider>
    );
}

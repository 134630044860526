import { WarningTwoIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertTitle, ComponentWithAs, IconProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import preventCmdCtrlClick from '../../utils/click-helpers';

interface BaseBannerProps {
    alertIcon?: ComponentWithAs<'svg', IconProps>,
    colorScheme?: string,
    children?: ReactNode,
    status?: 'info' | 'warning' | 'success' | 'error',
    title?: string,
    onClick?(): void
    href?: string
}

export default function BaseBanner(props: BaseBannerProps) {
    const { alertIcon, colorScheme, children, status, title, onClick, href } = props;

    return (
        <Alert
            as={href ? 'a' : undefined}
            colorScheme={colorScheme}
            gridGap={2}
            height='24px'
            href={href}
            onClick={onClick ? (e) => preventCmdCtrlClick(e, () => onClick()) : undefined}
            p={1}
            pl={4}
            status={status}
            target="_blank"
            w="100%"
        >
            <AlertIcon as={alertIcon || WarningTwoIcon} />
            <AlertTitle
                flex={5}
                noOfLines={1}
                textAlign="left"
            >
                {title}
            </AlertTitle>
            {
                children
            }
        </Alert>
    );
}

import { CheckCircleIcon, CloseIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { ListIcon, ListItem } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { Feedback } from '../types/api-types';
import { FEEDBACK_STATUS } from '../utils/constants';

interface FeedbackListItemProps {
    feedback: Feedback
}

export default function FeedbackListItem(props: FeedbackListItemProps) {
    const { feedback } = props;

    const iconData = useMemo(() => {
        switch (feedback.status) {
            case FEEDBACK_STATUS.DONE:
                return { color: 'green', icon: CheckCircleIcon };
            case FEEDBACK_STATUS.CANCELED:
                return { color: 'red', icon: CloseIcon };
            case FEEDBACK_STATUS.PENDING:
                return { color: 'orange', icon: WarningTwoIcon };
            default:
                return undefined;
        }
    }, [feedback]);

    return (
        <ListItem overflowY="auto" textAlign="left" w="100%">
            <ListIcon as={iconData?.icon} color={iconData?.color} />
            {feedback.content}
        </ListItem>
    );
}

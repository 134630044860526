import { useMemo } from 'react';

import { getUser } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { ADMIN, SUPERADMIN } from './constants';

export default function useRoles() {
    const user = useAppSelector((state) => getUser(state));

    const isAdmin = useMemo(() => {
        if (!user) {
            return false;
        }

        if (user.roles?.includes(SUPERADMIN) || user.roles?.includes(ADMIN)) {
            return true;
        }

        return false;
    }, [user]);

    const isNoRole = useMemo(() => {
        if (!user) {
            return false;
        }

        if (user.roles?.length === 0 && user.backend?.length === 0) {
            return true;
        }

        return false;
    }, [user]);

    return { isAdmin, isNoRole };
}

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { HStack, IconButton, StackProps, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import FeedbackCard from '../components/cards/FeedbackCard';
import { Feedback } from '../types/api-types';
import useFeedbacks from '../utils/feedback-hook';

interface FeedbackSuperposeProps {
    feedbacks?: Feedback[],
    visibleFeedbackIds?: string[],
    onFeedbackClick?(feedbackId: string): void,
    onScroll?(event: React.UIEvent<HTMLDivElement>): void
    onMouseEnterCard?(feedbackId: string): void,
    onMouseLeaveCard?(): void,
    noActionButton?: boolean
    containerStyle?: StackProps
}

export default function FeedbackSuperpose(props: FeedbackSuperposeProps) {
    const { pathname } = useLocation();
    const { hasOneFeedbackSuperposeImage } = useFeedbacks();

    const { feedbacks, visibleFeedbackIds, onFeedbackClick, onScroll, onMouseEnterCard, onMouseLeaveCard, noActionButton, containerStyle } = props;

    const [overrideFeedbackIdActive, setOverrideFeedbackIdActive] = useState<string | undefined>();

    const handleCheckChange = (feedbackId: string) => {
        if (onFeedbackClick) {
            onFeedbackClick(feedbackId);
        }
    };

    // Called when we scroll and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (onScroll) {
            onScroll(event);
        }
    };

    const handleOnPointerEnter = (feedback: Feedback) => {
        // ---- Set the local state to override the visibleFeedback normal behaviour ----
        setOverrideFeedbackIdActive(feedback.id);

        // ---- Callback if it exists ----
        if (onMouseEnterCard) {
            onMouseEnterCard(feedback.id);
        }
    };

    const handleOnPointerLeave = () => {
        // ---- Remove local state ----
        setOverrideFeedbackIdActive(undefined);

        if (onMouseLeaveCard) {
            onMouseLeaveCard();
        }
    };

    return (
        <HStack h="100%" pt={4} w="100%" {...containerStyle}>
            <VStack flex={1} height="100%" onScroll={handleScroll} overflowY="auto" w="100%">
                <Wrap onPointerLeave={handleOnPointerLeave} p={4} pt="2px" w="100%">
                    {
                        feedbacks && feedbacks.map((feedback) => <WrapItem
                            borderRadius={8}
                            key={feedback.id}
                            onPointerEnter={() => handleOnPointerEnter(feedback)}
                            outline={
                                overrideFeedbackIdActive === feedback.id
                                || (!overrideFeedbackIdActive && visibleFeedbackIds?.includes(feedback.id))
                                    ? '2px solid'
                                    : undefined
                            }
                            outlineColor="primary.500"
                            overflow="hidden"
                            position="relative"
                            w="100%"
                        >
                            <FeedbackCard feedback={feedback} noActionButton={!matchPath('warp/:warpId/edit', pathname) || noActionButton} />
                            {
                                feedback.images && hasOneFeedbackSuperposeImage(feedback.images)
                                && <IconButton
                                    aria-label='toggle-visible-feedback'
                                    bottom={0}
                                    icon={visibleFeedbackIds?.includes(feedback.id) ? <ViewIcon /> : <ViewOffIcon />}
                                    left={0}
                                    onClick={() => handleCheckChange(feedback.id)}
                                    position="absolute"
                                    variant="ghost"
                                />
                            }

                        </WrapItem>)
                    }
                </Wrap>
            </VStack>
        </HStack>
    );
}

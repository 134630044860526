// ---- Return the naming matching the garment_primary value ----
const getGarmentPrimaryString = (primaryNumber?: number) => {
    if (!primaryNumber) {
        return '';
    }

    switch (primaryNumber) {
        case 1:
            return 'PRIMARY';
        case 2:
            return 'SECONDARY';
        case 3:
            return 'TERTIARY';
        default:
            return '';
    }
};

export default getGarmentPrimaryString;

import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import Select from 'react-select';

const customStyles = {
    control: (provided: Record<string, unknown>) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        border: 0,
        boxShadow: 'none',
        flexGrow: 1,
        minHeight: 0,
        width: 150,
    }),
    dropdownIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        opacity: 0,
    }),
    input: (provided: Record<string, unknown>) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    singleValue: (provided: Record<string, unknown>) => ({
        ...provided,
        fontWeight: 'bold',
        margin: 0,
    }),
};

interface CustomDropdownProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOptionLabel?: (option: any) => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOptionValue?: (option: any) => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (option: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isOptionDisabled?: (option: any) => boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any[]
    placeholder?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any
    title?: string
    disabled?: boolean
}
/**
 * A custom dropdown with a title and search feature
 * @param param0 CustomDropdownProps
 */
const CustomDropdown: FC<CustomDropdownProps> = ({
    getOptionLabel, getOptionValue, isOptionDisabled, onChange, options, placeholder, value, title, disabled }) => (
    <div>
        <Text fontSize={12} paddingLeft={'8px'} zIndex={1}>
            {title}
        </Text>
        <Select
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isDisabled={disabled}
            isOptionDisabled={isOptionDisabled}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            value={value}
        />
    </div>
);

export default CustomDropdown;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReserveReservation } from '../../types/api-types';
import { COMMON_LOCALES, WARP_EDIT_LOCALES } from '../../utils/constants';
import BaseBanner from './BaseBanner';

interface ReservedBannerProps {
    reservations: ReserveReservation[]
}

export default function ReservedBanner(props: ReservedBannerProps) {
    const { t } = useTranslation([WARP_EDIT_LOCALES, COMMON_LOCALES]);

    const { reservations } = props;

    const title = `${t('reserved_by') + reservations.map(
        (reservation, index) => `${reservation.firstname} (${reservation.seconds} s)${index === reservations.length - 1 ? '' : ', '}`,
    )}. ${t('edit_disabled')}`;

    return (
        <BaseBanner
            status="warning"
            title={title}
        />
    );
}

import { NotAllowedIcon } from '@chakra-ui/icons';
import { Center, Image, ImageProps, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addErrorImage, getErrorImages } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';

interface ImageWithErrorProps extends ImageProps {
    datasrc?: string
}

const ImageWithError = React.forwardRef<HTMLImageElement, ImageWithErrorProps>((props, ref) => {
    const dispatch = useDispatch();

    const errorImages = useAppSelector((state) => getErrorImages(state));

    const GRID_CENTER_SPINNER = <Center data-image-url={props.datasrc || props.src} h="100%" w="100%">
        <Spinner />
    </Center>;

    const GRID_CENTER_CROSS = <Center data-image-url={props.datasrc || props.src} h="100%" onClick={props.onClick} w="100%">
        <NotAllowedIcon fontSize={'25px'} />
    </Center>;

    const [isLoading, setIsLoading] = useState<boolean>((props.src ? !errorImages.includes(props.src) : true));
    const [isError, setIsError] = useState<boolean>((props.src && errorImages.includes(props.src)) || false);

    const handleOnError = () => {
        setIsLoading(false);
        setIsError(true);

        if (props.src) {
            dispatch(addErrorImage(props.src));
        }
    };

    const handleOnLoad = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        if (props.src && errorImages.includes(props.src)) {
            setIsError(true);
            setIsLoading(false);

            return undefined;
        }

        setIsError(false);

        return undefined;
    }, [errorImages, props.src]);

    return (
        <>
            {
                (props.src && props.src !== '') && <>
                    {
                        isLoading && !props.fallback && GRID_CENTER_SPINNER
                    }
                    {
                        isError && GRID_CENTER_CROSS
                    }

                    {
                        !isError && <Image
                            display={isLoading || isError ? 'none' : 'block'}
                            {...props}
                            onError={handleOnError}
                            onLoad={handleOnLoad}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            pointerEvents="auto !important"
                            ref={ref}
                            src={props.src}
                        />
                    }
                </>
            }
        </>

    );
});

export default ImageWithError;
